import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { loadLocations, loadLocationsDone, selectCurrentLocation } from '../actions/location.actions';
import { loadRooms } from '../actions/room.actions';
import { RequestManagerService } from '../services/requestManager/request-manager.service';
import { LocationService } from '../services/location/location.service';



@Injectable()
export class LocationEffects {



  constructor(private actions$: Actions, private rm: RequestManagerService) {}

  @Effect()
  locationLoad = this.actions$.pipe(
    ofType(loadLocations),
    mergeMap(() => {
      return from(this.rm.get('/locations', null, true)).pipe(map(
        (data) => {
        return loadLocationsDone({locations: data.locations});
      }))
    }
    )
  )

  @Effect()
  selectLocation = this.actions$.pipe(
    ofType(selectCurrentLocation),
    map( (location) => {

      localStorage.setItem('pp-location-select', JSON.stringify(location));
      return loadRooms();
    })
  )


}
