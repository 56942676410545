import { createAction, props } from '@ngrx/store';


export interface ILocation {
  name:number,
  id:string

}
export const loadLocations = createAction(
  '[Location] Load Locations'
);

export const loadLocationsDone = createAction(
  '[Location] Load Locations Done',
  props<{ locations: ILocation[] }>()
);

export const selectCurrentLocation = createAction(
  '[Location] selectLocation',
  props<ILocation>()
);

export const openLocationSelectModal = createAction(
  '[Location] openLocationModal',
)





