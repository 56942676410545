import { Component, OnInit } from '@angular/core';
import { ISettingsValue } from '../../admin/settings/settingsValue';
import { RequestManagerService } from '../../../services/requestManager/request-manager.service';
import { take, tap } from 'rxjs/operators';
import { UtilsService } from '../../../services/utils/utils.service';

@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {

    featureSettingFeaturePayingByCash: ISettingsValue =
        {
            settingsKey: 'feature_payingByCash',
            settingsValue: false,
            settingsId: 2,
            id: 22,
        };


    constructor(private rm: RequestManagerService,
    ) {

        this.rm.getAuth('/settings/settingsValue', this.rm.getJWT())
            .pipe(
                take(1),
                tap((res) => {
                    let featureSettings = UtilsService.getSettingsOfCategoryByName('featureSettings', res);
                    this.featureSettingFeaturePayingByCash = UtilsService.getSettingByKeynameFromCategory('feature_payingByCash', featureSettings);
                    this.featureSettingFeaturePayingByCash.settingsValue = this.featureSettingFeaturePayingByCash.settingsValue === 'true';
                }),
            )
            .subscribe();
    }

    ngOnInit(): void {
    }

}
