import { createAction, props } from '@ngrx/store';

export const authUser = createAction(
  '[Auth] auth user',
  props<{username:string, data:any, isAdmin:boolean, isOffice:boolean, jwt:string}>()
);

export const loadUser = createAction(
  '[Auth] load user',
  props<{userid:any}>()
)

export const loadUserSuccess = createAction(
  '[Auth] load user done',
  props<{user:any}>()
);




