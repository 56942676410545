import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RequestManagerService } from '../requestManager/request-manager.service';

@Injectable({
    providedIn: 'root',
})
export class LocationService {

    locationChange$ = new BehaviorSubject('');

    locationColorMap: { name: string, primaryColor: string } [] = [];

    defaultColor = 'black';

    constructor(private rm: RequestManagerService) {
        this.rm.get('/locations?array_values=true').then((locations) => {
            if (locations.locations) {
                this.locationColorMap = locations.locations;
            }
        });
    }

    getColorByName(locationName: string): string {
        if (this.locationColorMap.length > 0) {
            const correctLocation = this.locationColorMap.find((location) => {
                return location.name == locationName;
            });

            if (correctLocation != undefined) {
                return correctLocation.primaryColor;
            }
        }
        return this.defaultColor;
    }
}
