import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as _moment from 'moment';
import { BehaviorSubject } from 'rxjs';

const moment = _moment;

@Injectable({
    providedIn: 'root',
})
export class FilterRoomsService {

    onlyFreeRoomsFilterActive$ = new BehaviorSubject(false);
    filterDay = new FormControl('', [Validators.required]);
    filterTimeFrom = new FormControl('0:00', [Validators.required,
        Validators.pattern(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)]);
    filterTimeTo = new FormControl('23:59', [Validators.required,
        Validators.pattern(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)]);

    filterActivated$ = new BehaviorSubject(false);

    minValuePrice$ = new BehaviorSubject<number>(1);
    maxValuePrice$ = new BehaviorSubject<number>(100);

    constructor() {
        moment.locale('de-at');
        this.filterDay.patchValue(moment());
    }

    resetParameters() {
        this.filterActivated$.next(false);
        this.minValuePrice$.next(1);
        this.maxValuePrice$.next(100);
        this.onlyFreeRoomsFilterActive$.next(false);
        this.filterDay.patchValue(moment());
        this.filterTimeFrom.patchValue('0:00');
        this.filterTimeTo.patchValue('23:59');
    }
}
