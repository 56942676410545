import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DataProtectionComponent } from '../data-protection/data-protection.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ISettingsValue } from '../admin/settings/settingsValue';
import { UtilsService } from '../../services/utils/utils.service';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    version = undefined;

    companySettingMailto: ISettingsValue =
        {
            settingsKey: 'footer_mailto',
            settingsValue: true,
            settingsId: 1,
            id: 25,
        };

    companyImprint: ISettingsValue =
        {
            settingsKey: 'footer_imprint',
            settingsValue: true,
            settingsId: 1,
            id: 26,
        };

    companyName: ISettingsValue =
        {
            settingsKey: 'companyName',
            settingsValue: true,
            settingsId: 1,
            id: 36,
        };


    constructor(private matDialog: MatDialog,
                public translate: TranslateService,
                private requestManager: RequestManagerService,
    ) {
        this.version = environment.translateVersion;
        this.requestManager.get('/settings/settingsValue', true)
            .then(
                (res) => {
                    let featureSettings = UtilsService.getSettingsOfCategoryByName('companySettings', res);
                    this.companySettingMailto = UtilsService.getSettingByKeynameFromCategory('footer_mailto', featureSettings);
                    this.companyImprint = UtilsService.getSettingByKeynameFromCategory('footer_imprint', featureSettings);
                    this.companyName = UtilsService.getSettingByKeynameFromCategory('companyName', featureSettings);
                },
            );

    }



    ngOnInit(): void {
    }

    openDataProtection() {
        this.matDialog.open(DataProtectionComponent);
    }


}
