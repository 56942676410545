import { Injectable } from '@angular/core';
import { RequestManagerService } from '../requestManager/request-manager.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { UtilsService } from '../utils/utils.service';
import { Colors } from './colors';

@Injectable({
    providedIn: 'root',
})
export class ColorsService {

    private colorsSubject = new Subject<Colors>();

    readonly colors$ = this.colorsSubject.asObservable();

    constructor(private requestManager: RequestManagerService) {
        this.loadColors();
    }

    private loadColors() {
        this.requestManager.get('/settings/settingsValue', true)
            .then(
                (res) => {
                    let colorSettings = UtilsService.getSettingsOfCategoryByName('colorSettings', res);
                    const primaryColorSetting = UtilsService.getSettingByKeynameFromCategory('primaryColor', colorSettings);
                    const primaryColorSelectedSetting = UtilsService.getSettingByKeynameFromCategory('primaryColorSelected', colorSettings);
                    const primaryColorHoverSetting = UtilsService.getSettingByKeynameFromCategory('primaryColorHover', colorSettings);
                    const blackInactiveSetting = UtilsService.getSettingByKeynameFromCategory('blackInactive', colorSettings);
                    const blackDisabledSetting = UtilsService.getSettingByKeynameFromCategory('blackDisabled', colorSettings);
                    const successSetting = UtilsService.getSettingByKeynameFromCategory('success', colorSettings);
                    const warnSetting = UtilsService.getSettingByKeynameFromCategory('warn', colorSettings);
                    const fontSetting = UtilsService.getSettingByKeynameFromCategory('font', colorSettings);
                    const colors: Colors = {
                        primary: primaryColorSetting.settingsValue,
                        primaryColorSelected: primaryColorSelectedSetting.settingsValue,
                        primaryColorHover: primaryColorHoverSetting.settingsValue,
                        blackInactive: blackInactiveSetting.settingsValue,
                        blackDisabled: blackDisabledSetting.settingsValue,
                        success: successSetting.settingsValue,
                        warn: warnSetting.settingsValue,
                        font: fontSetting.settingsValue,
                    };
                    this.colorsSubject.next(colors);
                },
            );

    }
}
