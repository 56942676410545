import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private _url:string;
  private _stripeSecret:string;

  constructor(private http: HttpClient) { 
    
    this._url = environment.apiUrl;
    this._stripeSecret = "pk_test_51HZxImDgrywgRk697acuDj4iVEaIeUdsIctiIk4lnhTaOc394dTAaelmkpZjt0leCAgydVI0hHOuD76NfrHCHqWA00nAFL2Lrg";
  }
  
  public get baseurl() : string {
    return this._url;
  }

  public get stripeSecret():string {
    return this._stripeSecret;
  }
  
}
