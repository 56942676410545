import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RequestManagerService} from '../../../services/requestManager/request-manager.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.css']
})
export class CustomerDashboardComponent implements OnInit {
  refreshValue = 0;

  id: number;
  private sub: any;
  public subComponentsAreLoading = true;

  private componentsLoading = {
    'bookings': true,
    'data': true,
    'actions': true,
    'accounting': true
  };

  loadingSpinner$ = new BehaviorSubject(false);

  constructor(private route: ActivatedRoute,
              private _requestManager: RequestManagerService,
              public modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (params['id'] != undefined) {
        this.id = +params['id'];
      } else {
        this.id = this._requestManager.getIdOfUserThatIsLoggedIn();
      }
    });
  }

  loadingDone(name){
    this.componentsLoading[name]=false;
    this.updateLoadingStatus()
  }

  updateLoadingStatus() {
   
    if (
      !this.componentsLoading.data &&
      !this.componentsLoading.actions &&
      !this.componentsLoading.accounting) {
      this.subComponentsAreLoading = false;
    } else {
      this.subComponentsAreLoading = true;
    }
  }

  getCustomerId() {
    return this.id;
  }

  showErrorPage(httpCode, errorMessage) {

  }

  onRefresh($event: any) {
    // console.log($event);
    this.modalService.dismissAll();
    this.refreshValue++;
  }
}
