import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";


import * as _ from "lodash";
import { clone, cloneDeep } from 'lodash';
import { defer, forkJoin, from, Observable, of, timer } from 'rxjs';
import {
    catchError,
    concatMap,
    debounceTime,
    map,
    mergeMap,
    share, startWith, switchMap,
    take, tap, toArray,
} from 'rxjs/operators';
import * as XLSX from 'xlsx';
import {
  loadCustomers,
  loadCustomersSuccess,
} from "../../actions/customer.actions";
import { searchRooms, searchRoomsDone } from "../../actions/room.actions";
import { State } from "../../reducers";
import { RequestManagerService } from "../../services/requestManager/request-manager.service";
import { UtilsService } from "../../services/utils/utils.service";
import { BatchBookingQuery } from "./batch-booking.query";
import { BatchBookingStore, ImportBookingRow } from './batch-booking.store';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: "app-batch-booking",
  templateUrl: "./batch-booking.component.html",
  styleUrls: ["./batch-booking.component.scss"],
})
export class BatchBookingComponent implements OnInit {
    bookingPreview$;
    searchCustomer = new FormControl('', []);
    searchRoom = new FormControl('', []);
    rooms$;
    customers$;
    result$;
    loading = false;
    roomCache = {};
    roomObservable: any = {};
    noBookingsInPreview = false;
    completedSuccessfullBookings = 0;
    bookingsToBeMade = 0;
    bookingIsInProcess = false;
    bookingError = false;
    failedBookings: any = [];

    constructor(
        private store: Store,
        private batchStore: BatchBookingStore,
        private batchBookingQuery: BatchBookingQuery,
        private requestManager: RequestManagerService,
        private notifier: NotifierService,
    ) {
        this.searchCustomer.valueChanges
            .pipe(debounceTime(500))
            .subscribe((value) => {
                this.searchForCustomer(value);
      });

    this.searchRoom.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.searchForRoom(value);
    });
    this.bookingPreview$ = this.batchBookingQuery.select(
      (state) => state.importingBookings
    );
    this.customers$ = this.store
      .select((state: State) => state.data.customers)
      .pipe(
        map((customers) => {
          return _.values(customers);
        })
      );
    this.rooms$ = this.store.select((state: State) => state.searchResult.rooms);
  }

  searchForRoom(name) {
    if (name != "") {
      this.store.dispatch(searchRooms({ name }));
    } else {
      this.store.dispatch(searchRoomsDone({ rooms: [] }));
    }
  }

  searchForCustomer(name) {
    if (name != "") {
      this.store.dispatch(
        loadCustomers({ searchTerm: name, offset: 0, limit: 20 })
      );
    } else {
      this.store.dispatch(loadCustomersSuccess({ customers: {} }));
    }
  }

  getRoom(roomId) {
    if (this.roomCache[roomId]) {
      return of(this.roomCache[roomId]);
    } else if (this.roomObservable[roomId]) {
      return this.roomObservable[roomId];
    } else {
      this.roomObservable[roomId] = from(
        this.requestManager.get("/room/" + roomId)
      ).pipe(
        map((response) => {
          if(!response.room) return;
          let room = Object.values<any>(response.room)[0];
          this.roomCache[room.id] = room;
          return room;
        }),
        share()
      );
      return this.roomObservable[roomId];
    }
  }

  previewBooking() {
      this.noBookingsInPreview = false;
      this.loading = true;
      this.bookingPreview$ = this.batchBookingQuery
          .select((state) => state.importingBookings)
          .pipe(
              take(1),
              mergeMap((importing) => {


                  let requests = [];
                  if (importing === undefined) {
                      this.loading = false;
                      throw new Error('Excel Datei konnte nicht verarbeitet werden');
                  }

                  if (Array.isArray(importing) && importing.length === 0) {
                      this.noBookingsInPreview = true;
                      this.loading = false;
                      throw new Error('Keine Buchungen hochgeladen!');
                  }
                  for (let booking of importing) {
                      if (booking === undefined) {
                          continue;
                      }
                      requests.push(
                          this.getRoom(booking.roomId).pipe(
                              mergeMap((room: any) => {
                                  if (room) {
                                      let endHour = UtilsService.addEntityLength2TimeString(
                                          booking.timeFrom,
                      room.entityLength
                    );
                    let date = booking.date as Date;
                    let body = {
                      roomId: room.id,
                      bookingTimeFrom: UtilsService.getISOStringOfTimeString(
                        date.getFullYear(),
                        date.getMonth() + 1,
                        date.getDate(),
                        booking.timeFrom
                      ),
                      bookingTimeTo: UtilsService.getISOStringOfTimeString(
                        date.getFullYear(),
                        date.getMonth() + 1,
                        date.getDate(),
                        endHour
                      ),
                      userId: booking.userId,
                    };
                    return of(body);
                  } 
                  let date = booking.date as Date;
                  return of({
                    error: "RaumID falsch.",
                    missing: booking.roomId,
                    roomId: booking.roomId,
                    userId: booking.userId,
                    bookingTimeFrom: UtilsService.getISOStringOfTimeString(
                      date.getFullYear(),
                      date.getMonth() + 1,
                      date.getDate(),
                      booking.timeFrom
                    ),
                  });
                })
              )
            );
          }
          return forkJoin(requests).pipe(
            map((result: any[]) => {
              return [
                result.filter((value) => value.missing == undefined),
                result.filter((value) => value.missing != undefined),
              ];
            }),
            mergeMap(([body, missing]) => {
                return this.requestManager.post('/bookings/check', body).pipe(
                    map((response) => {
                        this.loading = false;
                        return {
                            possible: response.filter((value) => value.success),
                            error: missing.concat(
                                response.filter((value) => !value.success),
                            ),
                        };
                    }),
                    catchError((error) => {
                        this.loading = false;
                        this.notifier.show({
                            type: 'error',
                            message: 'Fehler beim Check der Buchungen!',
                        });
                        return of([]);
                    }),
                );
            })
          );
        })
      );
  }

  ngOnInit(): void {}
  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = async (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: "binary" });
      const firstSheetName = Object.values<string>(workBook.SheetNames)[0];

      const sheet = workBook.Sheets[firstSheetName];
      let resultArray = [];
      jsonData = XLSX.utils.sheet_to_json(sheet, {
        dateNF: "dd/mm/yyyy hh:mm:ss",
        raw: true,
      });
      forkJoin(
        jsonData.map((row) => {
          return this.convertXLSXLine(row);
        })
      ).subscribe((results) => {
        let resultArray = results.reduce(
          (previous: any[], current) => previous.concat(current),
          []
        );

        let importingData: ImportBookingRow[] =
          resultArray as ImportBookingRow[];
        this.batchStore.update({ importingBookings: importingData });
      });
    };
    reader.readAsBinaryString(file);
  }

  convertTime(timeField: any) {
    let baseTime = timeField * 24 + 0.00000000000000001;
    let minutes = (baseTime * 60) % 60;

    minutes = Math.floor(minutes + 0.001);
    if (minutes == 60) {
      baseTime++;
      minutes = 0;
    }
    return Math.floor(baseTime) + ":" + minutes;
  }

  convertXLSXLine(row) {
    row.date = new Date((row.date - 25569) * 86400 * 1000);
    row.timeFrom = this.convertTime(row.time);
    row.untilDate = new Date((row.untilDate - 25569) * 86400 * 1000);

    let dates = [row];
    if(row.repeatTimeUntil) {
      row.untilTime = this.convertTime(row.repeatTimeUntil);
    }
    if (row.interval) {
      let pushIndex = 1;
      clone(dates).forEach((row, index) => {
        let rowCopy = row;
      
        do {
          rowCopy = cloneDeep(rowCopy);
          dates.splice(index+ pushIndex++,0, rowCopy);
          (rowCopy.date as Date).setDate(rowCopy.date.getDate() + row.interval);
        } while (rowCopy.date < row.untilDate);
      });
    }

      if (row.einzelbuchung.includes('nein') && row.repeatTimeUntil) {


          return this.getRoom(row.roomId).pipe(
              map((room: any) => {
                  if (room) {
                      let entityLength = room.entityLength;
                      let pushIndex = 1;
                      let compareTimeTo = UtilsService.addEntityLength2TimeString(row.untilTime, -entityLength);
                      clone(dates).forEach((row, index) => {
                          let timeFromOrigin = row.timeFrom;
        
              do {
                let rowCopy = cloneDeep(row);
                dates.splice(index+pushIndex++,0,rowCopy);
                timeFromOrigin = UtilsService.addEntityLength2TimeString(
                  timeFromOrigin,
                  entityLength
                );
                rowCopy.timeFrom = timeFromOrigin;
              } while (UtilsService.compareTimeStringsDetailed(compareTimeTo,timeFromOrigin)===1);
            });
          
          } else {
            dates.forEach(date => {
              date.error = "Raum ID nicht richtig."
            })
                  }
                  return dates;

              })
          );
      }

      return of(dates);
  }

    doBookings(bookings) {
        this.bookingPreview$ = of({});
        this.completedSuccessfullBookings = 0;
        this.bookingsToBeMade = 0;
        this.bookingIsInProcess = true;
        this.bookingError = false;
        this.failedBookings = []; // Array für fehlgeschlagene Buchungen

        const intervalMs = 1500;
        const requests = bookings.map(booking =>
            defer(() =>
                this.requestManager.post(
                    `/bookings/create?bookingForUser=${booking['userId']}&force=true&batch=true`,
                    [_.pick(booking, ['roomId', 'bookingTimeFrom', 'bookingTimeTo', 'userId'])],
                    this.requestManager.getJWT(),
                ) as Observable<any>,  // Explizite Typangabe hier
            ).pipe(
                tap((booking) => {
                    this.completedSuccessfullBookings++;
                    if (booking[0] && booking[0].success === false) {
                        // Füge Buchung zu failedBookings hinzu, wenn success = false
                        this.failedBookings.push({
                            ...booking[0],
                            error: 'Booking Failed',
                            success: false,
                        });
                    }
                }),
                catchError(error => {
                    this.completedSuccessfullBookings++;

                    const errorBooking = { ...booking, error: error.message + (error.error ?? ''), userId: booking['userId'] };
                    this.failedBookings.push(
                        errorBooking,
                    );
                    return of({ ...booking, error: error.message + (error.error ?? ''), userId: booking['userId'] });
                }),
            ),
        );


        this.bookingsToBeMade = requests.length;

        this.result$ = from(requests).pipe(
            concatMap(request =>
                timer(intervalMs).pipe(
                    mergeMap(() => request as Observable<any>),  // request ist bereits ein Observable durch defer()
                ),
            ),
            toArray(),  // Sammelt alle Ergebnisse in einem Array
            tap(() => {
                this.bookingIsInProcess = false;
            }),
            catchError(error => {
                this.bookingError = true;
                this.bookingIsInProcess = false;
                return of([]);  // Gibt ein leeres Array zurück, um Konsistenz im Fehlerfall zu gewährleisten
            }),
        );

    }

}
