import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { RequestManagerService } from '../../../../services/requestManager/request-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { from, Subject, Observable, of, forkJoin, Subscription, BehaviorSubject } from 'rxjs';
import {
    take,
    mergeMap,
    map,
    startWith,
    tap, switchMap, catchError,
} from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { State } from '../../../../reducers';
import { loadRooms } from '../../../../actions/room.actions';
import { cloneDeep, isArray } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '../../../../services/api/api.service';
import { BookingService } from '../../../../services/booking/booking.service';

import * as _moment from 'moment';


const moment =  _moment;

@Component({
    selector: 'app-customer-dashboard-bookings',
    templateUrl: './customer-dashboard-bookings.component.html',
    styleUrls: ['./customer-dashboard-bookings.component.scss'], })
export class CustomerDashboardBookingsComponent implements OnInit {
    @Output() refresh: EventEmitter<any> = new EventEmitter();
    @Output() loadingEvent: EventEmitter<boolean> = new EventEmitter();

    fromDate;
    toDate;
    otherDate;

    /**
     * calculatedSum
     * Only of selected Values
     */
    calculatedSum: number = 0;
    sum: number = 0;

    @Input()
    customerId;

    @Input()
    bookingId;


    filterSubject$ = new Subject<boolean>();

    payingLoading$ = new BehaviorSubject<boolean>(false);

    selectedBookings: any = {};
    selectedByDay: any = {};
    allowedActions: any = {};
    loading = new BehaviorSubject(false);
    loadBookings = new BehaviorSubject(false);

    bookingsById: { [key: string]: any } = {};

    bookings$: Observable<Array<any>>;
    bookingsObjects$: Observable<any>;
    bookings = [];
    rooms = {};
    language = 'DE';
    language$: Subscription;

    filterTodayAvailable = true;
    filterTodayAndUnpaidAvailable = true;
    filterUnpaidAvailable = false;
    filterDateAvailable = true;

    anyAdditionalFilterActive = false;
    additionalFilterArray = [];

    actionList = [];

    userIsAdmin;
    userIsOffice;

    customer$;
    daysMarked: any = {};

    user$;
    userId;
    id$;
    paramId = false;
    fromDateUpdated = false;


    isBooked = false;

    constructor(
        private store: Store,
        private _requestManager: RequestManagerService,
        private _translateService: TranslateService,
        private _notifyService: NotifierService,
        private router: Router,
        private route: ActivatedRoute,
        private api: ApiService,
        public bookingService: BookingService,
        public translate: TranslateService,
    ) {
        moment.locale('de-at');
        this.fromDate = moment().add(-14, 'days');
        this.toDate = moment().add(14, 'days');
        this.addFilterDate(true, false);

        this.language$ = this.store.select((state: State) =>
            state.ui.language).subscribe((langcode) => {
            this.language = langcode;
        });

        if (this.router.getCurrentNavigation()) {
            if (this.router.getCurrentNavigation().extras.state != undefined){
                if (this.router.getCurrentNavigation().extras.state['isBooked'] != undefined) {
                    this.isBooked = this.router.getCurrentNavigation().extras.state['isBooked'];
                    window.location.reload();
                }
            }
        }
    }

    markDay(day, value, bookingGroup) {
        if (value) {
            this.daysMarked[day] = true;
            if (bookingGroup) {
                Object.values<any>(bookingGroup[1]).forEach((booking) => {
                    if (!this.selectedByDay[day]) {
                        this.selectedByDay[day] = [];
                    }
                    this.selectedByDay[day].push(booking.id);
                    const isFound = this.actionList.some(element => {
                        return element.id === booking.id;
                        });
                        if (!isFound) {
                            this.addOrRemoveBookingToActionList(true, booking.id, booking);
                        }

                })
            }
        } else {
            this.daysMarked[day] = false;
            if (this.selectedByDay[day]) {
                this.selectedByDay[day].forEach((id) => {
                    this.addOrRemoveBookingToActionList(false, id);
                });
                this.selectedByDay[day] = [];
            }
        }
    }
    uncheckDayIfBooking(id) {
        Object.keys(this.selectedByDay).forEach((day) => {
            const result = this.selectedByDay[day].filter((value) => id == value);
            if (result.length > 0) {
                this.selectedByDay[day] = [];
                this.daysMarked[day] = false;
            }
        });
    }
    ngOnInit() {


        if (this.customerId) {
            this.userId = this.customerId;
            this.id$ = of(this.customerId);
        } else {
            this.id$ = this.route.params.pipe(
                map((params) => {
                    if (params['id'] != undefined) {
                        this.userId = +params['id'];
                        this.paramId = true;
                    } else {
                        this.userId = this._requestManager.getIdOfUserThatIsLoggedIn();
                    }
                    return this.userId;
                })
            );
        }

        this.customer$ = this.id$.pipe(
            mergeMap((id) => {
                return from(
                    this._requestManager.get(
                        '/user/' + id,
                        this._requestManager.getJWT(),
                        true
                    )
                ).pipe(
                    map((res) => {
                        return Object.values(res.user)[0];
                    })
                );
            })
        );

        this.store.dispatch(loadRooms());

        this.language = this._translateService.currentLang.toUpperCase();
        this.userIsAdmin = Number.parseInt(this._requestManager.getUserIsAdmin());
        this.userIsOffice = Number.parseInt(this._requestManager.getUserIsOffice());
        // console.log(this.userIsAdmin);

        this.user$ = this.store.pipe(
            select((state: State) => state.auth.user.data.user_id)
        );
        this.id$
            .pipe(
                tap((userId) => {
                    this.userId = userId;
                    //console.log("bookingId:" + this.bookingId);
                    if(this.bookingId == null)
                    {
                        this.loadAllBookings(true, userId);
                    }
                })
            )
            .subscribe();
        this.filterSubject$.next(true);

    }


    scrollToElement() {
        setTimeout(() => {
            let element = document.getElementsByClassName('booking-id-equal');
            if(element[0]){
                element[0].scrollIntoView();
            }
        },1000);
    }


    getRoomName(booking, lang = 'DE') {
        let name;

        for (const desc of Object.values<any>(booking.room)[0].roomDescription) {
            name = desc.name;
            if (desc.language == lang) {
                break;
            }
        }
        return name;
    }


    isStornoType(booking) {
        return !(booking.storno == 1 || booking.stornoWait ==1);
    }

    loadAllBookings(forceReload = true, userId) {

        this.loadBookings.next(true);
        const userWhereClause: any[] = [
            {
                field: 'userId',
                model: 'bookings',
                value: userId,
                operator: '=',
            },
        ];

        this.bookingsObjects$ = this.refresh.pipe(
            startWith(true),
            switchMap(() => {
                return this.filterSubject$.asObservable().pipe(
                    startWith(''),
                    switchMap((value) => {
                        // console.log(value);
                        let whereClause;

                        whereClause = cloneDeep(userWhereClause).concat(
                            this.additionalFilterArray,
                        );
                        whereClause.forEach((clause, index) => {
                            if (index + 1 < whereClause.length) {
                                if (clause['followingOperator'] == undefined) {
                                    clause['followingOperator'] = 'and';
                                }
                            }
                        });

                        if(whereClause[whereClause.length -1].followingOperator){
                            delete whereClause[whereClause.length -1].followingOperator;
                        }


                        return from(
                            this._requestManager.get(
                                '/bookings/room/roomDescription?array_values=true&where_condition=' +
                                JSON.stringify(whereClause) +
                                '&order_by=table_bookings_dao.bookingTimeFrom&order_by_order=desc',
                                this._requestManager.getJWT(),
                                forceReload
                            )
                        ).pipe(
                            map((data) => {
                                if (
                                    data &&
                                    data.bookings &&
                                    Object.values(data.bookings).length > 0
                                ) {
                                    return data.bookings;
                                } else {
                                    return [];
                                }
                            }),
                            map(this.bookingService.bookingTimeToDatePipe),
                            tap((bookingsArray: any[]) => {
                                bookingsArray.forEach((booking) => {
                                    this.bookingsById[booking.id] = booking;
                                });
                            })
                        );
                    })
                );
            })
        );

        this.bookings$ = this.bookingsObjects$.pipe(
            map((bookings: any) => {
                let bookingsArray = [];
                if (
                    bookings &&
                    Object.values(bookings).length > 0 &&
                    !isArray(bookings)
                ) {
                    this.bookings = bookings;
                    bookingsArray = Object.values(this.bookings);
                } else if (isArray(bookings)) {
                    bookingsArray = bookings;
                }
                if (bookingsArray.length > 0) {
                    this.sum = 0;
                    Object.values<any>(bookings).forEach((booking) => {
                        if (booking.storno == 1 && booking.paid != 1) {
                            this.sum += Number.parseFloat(booking.price);
                        } else if (booking.storno == 1 || booking.paid == 1) {
                            return;
                        } else {
                            this.sum += Number.parseFloat(booking.price);
                        }
                    });


                    this.payingLoading$.next(false);
                    return Object.values(bookings);
                } else {
                    this.payingLoading$.next(false);
                    return [];
                }
            }),
            map((bookings: any) => {
                return this.groupBookingsByDate(bookings);
            }),
            tap((bookingGroupByDate: any) => {
                this.getEarliestBookingUnpaid(bookingGroupByDate);
                this.bookings = bookingGroupByDate;
            }),
        );

    }


    groupBookingsByDate(bookings: Array<any>) {

        const bookingGroupByDate = this.groupBy(bookings, 'bookingTimeFrom');

        this.loadBookings.next(false);
        // console.log(bookingGroupByDate);
        return Object.entries(bookingGroupByDate);
    }


    groupBy(array, key) {
        // Return the reduced array
        return array.reduce((result, currentItem) => {
            const date = moment(currentItem[key]).format('L');
            // If an array already present for key, push it to the array, otherwise create an array and push the object.
            (result[moment(currentItem[key]).format('L')] = result[moment(currentItem[key]).format('L')] || []).push(currentItem);
            // return the current iteration `result` value, this will be the next iteration's `result` value and accumulate
            return result;
        }, {}); // Empty object is the initial value for result object
    };


    getEarliestBookingUnpaid(bookingGroupByDate: any) {
        let newDate = false;

        //iterate through bookingGroups
        for (let bookings of bookingGroupByDate) {
            for (let booking of bookings[1]) {
                //if a booking is not paid
                if (booking.paid != '1') {
                    //check if bookings date is before filter date
                    if (moment(booking.bookingTimeFrom).isBefore(this.fromDate)) {
                        //if true, update filter from date
                        this.fromDate = moment(booking.bookingTimeFrom);
                        newDate = true;
                    }
                }
            }
        }
        //if filter date has been updated, trigger new filters
        if (newDate) {
            this.addFilterDate(true);
        } else if (!this.fromDateUpdated) {
            this.fromDateUpdated = true;
            this.addFilterDate(true);
        }
    }


    getDateFormat(date: Date) {

        let month = (date.getMonth() + 1).toString();
        if (Number(month) < 10) {
            month = '0' + month;
        }
        let day = date.getDate().toString();
        if (date.getDate() < 10) {
            day = '0' + date.getDate();
        }
        return date.getFullYear() + '-' + month + '-' + day;
    }

    addFilterToday() {
        this.filterTodayAndUnpaidAvailable = false;
        this.filterDateAvailable = false;
        this.filterTodayAvailable = false;
        this.additionalFilterArray.push({
            field: 'bookingTimeFrom',
            model: 'bookings',
            value: '\'' + this.getDateFormat(new Date())  + '%\'',
            operator: 'LIKE',
        });

        this.anyAdditionalFilterActive = true;
        this.fromDate= moment();
        this.toDate= moment();
        this.updateBookingTable(true);
    }

    addFilterUnpaid() {

        this.filterUnpaidAvailable = !this.filterUnpaidAvailable;

        if(!this.filterUnpaidAvailable){
            this.additionalFilterArray.forEach((filter) =>{
                if(filter.field == 'paid'){
                    this.additionalFilterArray.splice(this.additionalFilterArray.indexOf(filter),1);
                }
            })
        }
        else{
            this.additionalFilterArray.push({
                field: 'paid',
                model: 'bookings',
                value: '0',
                operator: '=',
            });

            this.anyAdditionalFilterActive = true;
        }

        this.updateBookingTable(true);
    }

    addFilterTodayAndUnpaid() {
        this.filterDateAvailable = false;
        this.filterUnpaidAvailable = false;
        this.filterTodayAvailable = false;
        this.filterTodayAndUnpaidAvailable = false;
        this.additionalFilterArray.push({
            field: 'bookingTimeFrom',
            model: 'bookings',
            value: '\'' + this.getDateFormat(new Date()) + '%\'',
            operator: 'like',
        });
        this.additionalFilterArray.push({
            field: 'paid',
            model: 'bookings',
            value: '0',
            operator: '=',
        });

        this.anyAdditionalFilterActive = true;
        this.updateBookingTable();
    }


    validateInput($event: any,date) {
        switch(date){
            case 'to':
                this.toDate=$event;
                break;
            case 'from':{
                this.fromDate=$event;
                break;
            }
        }
    }

    addFilterDate(forceUpdate = true, filterForFromDate = true) {

        this.filterTodayAndUnpaidAvailable = false;
        this.filterTodayAvailable = false;

        this.additionalFilterArray = [];

        let month, day, tomonth, today;
        if (Number.parseInt(this.fromDate.month()) + 1 < 10) {

            month = '0' + Number.parseInt(this.fromDate.month() + 1).toString();
        } else {
            month = Number.parseInt(this.fromDate.month()+1).toString();
        }
        if (Number.parseInt(this.fromDate.date())< 10) {
            day = '0' + Number.parseInt(this.fromDate.date()).toString();
        } else {
            day = Number.parseInt(this.fromDate.date()).toString();
        }

        if ((Number.parseInt(this.toDate.month())+1) < 10) {
            tomonth = '0' + Number.parseInt(this.toDate.month() + 1).toString();
        } else {
            tomonth = Number.parseInt(this.toDate.month() + 1).toString();
        }
        if (Number.parseInt(this.toDate.date()) < 10) {
            today = '0' + Number.parseInt(this.toDate.date()).toString();
        } else {
            today = Number.parseInt(this.toDate.date()).toString();
        }

        if (filterForFromDate) {
            this.additionalFilterArray.push({
                field: 'bookingTimeFrom',
                model: 'bookings',
                value: '\'' + this.fromDate.year() + '-' + month + '-' + day + 'T00:00:00\'',
                operator: '>=',
            });
        }

        this.additionalFilterArray.push({
            field: 'bookingTimeTo',
            model: 'bookings',
            value:
                '\'' + this.toDate.year() + '-' + tomonth + '-' + today + 'T23:59:59\'',
            operator: '<=',
        });

        this.anyAdditionalFilterActive = true;
        if(forceUpdate){
            this.updateBookingTable();
        }
    }

    updateBookingTable(forceReload = false) {
        this.filterSubject$.next(true);

        /**
         * On a change of anything in the Bookings table, for example the select option of bookings per page or the search bar
         * we call this function, which will reload the booking list.
         * @type {boolean}
         */

        this.loadAllBookings(forceReload, this.userId);
    }

    cancelSelectedBookings(force = false) {
        this.payingLoading$.next(true);
        this.loading.next(true);
        let countDone = 0;
        let countUndone = 0;
        const cancelBookingsList = [];
        if (force) {
            this.actionList.forEach((value) => {
                if (this.allowedActions[value.id].stornoAdmin) {
                    cancelBookingsList.push({ id: value.id, force: force });
                    countDone++;
                } else {
                    countUndone++;
                }
            });
        } else {
            this.actionList.forEach((value) => {
                if (this.allowedActions[value.id].storno) {
                    cancelBookingsList.push({ id: value.id, force: force });
                    countDone++;
                } else {
                    countUndone++;
                }
            });
        }
        if (countDone > 0) {
            let messageBookingsCancelled = this.translate.instant('BOOKING_TO_BE_CANCELLED').replace('{COUNT_BOOKINGS}', countDone.toString());

            this._notifyService.show({
                message: messageBookingsCancelled,
                type: 'info',
            });
        }

        if (countUndone > 0) {
            let messageBookingsCancelledError = this.translate.instant('BOOKING_CANCEL_ERROR').replace('{COUNT_BOOKINGS}', countUndone.toString());
            this._notifyService.show({
                message: messageBookingsCancelledError,
                type: 'error',
            });
        }

        this.bookingService.cancelBookings(cancelBookingsList)
            .pipe(
                take(1),
                catchError((error) => {

                    this._notifyService.show({
                        message:
                            this._translateService.instant('booking.storno.error') +
                            ':' +
                            error.error,
                        type: 'error',
                    });


                    return of(null);
                }),
            ).subscribe((result: null | { canceled: number }) => {
            if (result) {
                this._notifyService.show({
                    message:
                        this._translateService.instant('booking.storno.success').replace('{COUNT_BOOKINGS}', result.canceled),
                    type: 'success',
                });

                for (const bookingId of cancelBookingsList) {
                    this.addOrRemoveBookingToActionList(false, bookingId.id);
                }

            }
            this.updateBookingTable(true);
            this.loading.next(false);
        });

    }

    unpaySelectedBookings() {
        const result = confirm('Wollen Sie die markierten Buchungen wirklich als unbezahlt (ohne Registrierkasse) setzen?');
        if (!result) {
            return;
        }

        const bookings = [];
        const bookingIds = [];

        this.actionList.forEach((booking) => {
            if (this.allowedActions[booking.booking.id].unpay) {
                bookingIds.push(booking.booking.id);
                bookings.push(booking.booking);
            }
        });


        if (bookingIds.length > 0) {
            this.payingLoading$.next(true);


            this.bookingService.unpayBookings(bookingIds, this.userId)
                .pipe(
                    take(1),
                    map((success) => {
                        bookings.forEach((booking) => {
                                this.addOrRemoveBookingToActionList(false, booking.id, booking);
                            },
                        );
                        return success;
                    }),
                )
                .subscribe((success) => {

                        this._notifyService.notify(
                            'success',
                            'Buchung erfolgreich auf nicht bezahlt gesetzt',
                        );
                        this.refresh.emit('PAYMENT_REFRESH');
                        this.loading.next(false);
                        this.updateBookingTable(true);
                        this.actionList = [];
                    },
                    (error) => {
                        this.loading.next(false);
                        this.updateBookingTable(true);
                    });
        }
    }
    paySelectedBookings() {

        const bookings = [];
        this.actionList.forEach((value) => {
            if (this.allowedActions[value.id].pay) {
                bookings.push(this.bookingService.getBooking(value.id));
            }
        });
        if (bookings.length > 0) {
            forkJoin(bookings)
                .pipe(
                    take(1),
                )
                .subscribe((bookings: any) => {
                    this.payingLoading$.next(true);
                    const modalRef = this.bookingService.openPayBookingModal(bookings, this.userId);

                    modalRef.closed.subscribe((result) => {
                        if (result === true) {
                            console.log(result);
                            this.loading.next(true);
                            bookings.forEach((booking) => {
                                    this.addOrRemoveBookingToActionList(false, booking.id, booking);
                                },
                            );
                            this.updateBookingTable(true);
                            this.loading.next(false);
                        }
                        this.payingLoading$.next(false);
                    });
                });

        }
    }


    addOrRemoveBookingToActionList(value, id, booking?) {
        console.log(id);
        console.log(value);
        console.log(this.selectedBookings);
        console.log(this.actionList);
        console.log(this.allowedActions);
        if (value == false) {
            this.uncheckDayIfBooking(id);
            this.selectedBookings[id] = false;
            delete this.allowedActions[id];
            this.actionList.forEach((arrayValue, key) => {
                if (arrayValue.id == id) {
                    this.actionList.splice(key, 1);
                }
            });
        } else {
            this.bookingService
                .getStornoState(booking)
                .pipe(take(1))
                .subscribe((state) => {
                    this.allowedActions[booking.id] = {
                        storno: state == 'storno' || state == 'stornoWait',
                        pay: booking.paid == null || booking.paid == false,
                        unpay: booking.paid == true,
                        stornoAdmin: this.userIsAdmin == 1,
                    };
                });
            this.selectedBookings[id] = true;
            this.actionList.push({
                id: id,
                booking: booking,
            });
        }

        if (this.actionList.length == 0) {
            this.loading.next(false);
        }
        // console.log(this.actionList);
        this.updateCalculations();
    }

    updateCalculations() {
        if (this.actionList.length == 0) {
            this.calculatedSum = null;
        } else {
            let sum = 0.0;
            this.actionList.forEach((value, key) => {
                if (
                    (this.bookingsById[value.id].storno == 1 && this.bookingsById[value.id].paid == 1)
                    || this.bookingsById[value.id].paid == 1
                ) {
                    return;
                }

                const price = Number.parseFloat(this.bookingsById[value.id].price);
                sum += price;
            });
            if (sum == 0) {
                return null;
            }
            this.calculatedSum = sum;
        }
    }


    hasCalculations() {
        return this.calculatedSum !== null;
    }

    paySelectedBookingsByBalance() {
        this.loading.next(true);
        let bookingIds: string[] = [];
        let bookings: any[] = [];

        this.actionList.forEach((booking) => {
                if (booking.booking.paid != 1 || (booking.booking.storno == 0 && booking.booking.paid == 0)) {
                    bookingIds.push(booking.booking.id);
                    bookings.push(booking.booking);
                }
            },
        );

        if (bookingIds.length > 0) {
            this.payingLoading$.next(true);


            this.bookingService.payBookingsByCredit(bookingIds, this.userId)
                .pipe(
                    take(1),
                    map((success) => {
                        bookings.forEach((booking) => {
                                this.addOrRemoveBookingToActionList(false, booking.id, booking);
                            },
                        );
                        return success;
                    }),
                )
                .subscribe((success) => {

                        let messagePayed: string = this.translate.instant('PAYED_BOOKINGS_WITH_BALANCE');
                        messagePayed = messagePayed.replace('{COUNT_BOOKINGS}', bookingIds.length.toString());
                        this._notifyService.show({
                            message:
                            messagePayed,
                            type: 'success',
                        });

                        this.loading.next(false);
                        this.updateBookingTable(true);
                        this.actionList = [];
                    },
                (error) => {
                    if (error?.status == 402) {
                        this._notifyService.show({
                            message:
                                this._translateService.instant('booking.payByBalance.failed'),
                            type: 'warning',
                        });
                    }
                    this.loading.next(false);
                    this.updateBookingTable(true);
                });
        }

    }

    paySelectedBookingsByLegacy() {
        const result = confirm(
            'Sind sie sicher, dass sie die Buchungen abseits der Registierkasse als bezahlt markieren wollen?'
        );
        if (!result) { return; }

        let requests= [];
        let counterPaid=0;

        const bookings = [];
        this.actionList.forEach((booking) => {
            requests.push(this.api.payBookingWithoutCashRegister(booking).pipe(
                tap((response) => {
                    counterPaid++;
                }),
            ));
            bookings.push(booking);
        });

        if(requests.length > 0){
            this.payingLoading$.next(true);
            forkJoin(requests).subscribe((success) => {
                    bookings.forEach((booking) => {
                        this.addOrRemoveBookingToActionList(false, booking.id, booking);
                    });
                    this.loading.next(false);
                    this.refresh.emit('PAYMENT_REFRESH');
                    this._notifyService.show({
                        message:
                            'Es wurden ' + counterPaid + ' Buchungen erfolgreich ohne Kasse bezahlt',
                        type: 'success',
                    });
                },
                (error) => {
                    this._notifyService.notify('warning', 'Es gab einen Fehler. Siehe Log');
                    if (this.userIsAdmin) {
                        this._notifyService.notify('error', error.message);
                    }
                    this.loading.next(false);
                    this.payingLoading$.next(false);
                });
        }
    }

    displayWeekday(dateString){
        let days;
        switch(this.language){
            case 'DE':
                days =  ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
                break;
            case 'EN':
                days =  ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                break;
        }
        let date = new Date(dateString);
        return days[date.getDay()];
    }



    getBookingState(booking: any) {

        if (booking.storno == 1 && booking.paid == 1) {
            return 'Storniert';
        }
        if (booking.paid == 1) {
            return 'Bezahlt';
        }

        return 'Zahlung ausstehend';
    }

    getStornoState(booking){
        if(booking.storno == 1 && booking.stornoWait==1){
            return 'Storniert, außerhalb der Frist';
        }
        else if(booking.storno == 1){
            return 'Storniert';
        }
        return 'Keine Stornierung';
    }

    getHumanizedDate(bookingGroupArray: any) {
        let compareDate= moment(bookingGroupArray[0].bookingTimeFrom);
        let todayDate= moment();
        let diffDays= compareDate.diff(todayDate,'days');
        if(diffDays === 0){
            let diffHours= compareDate.diff(todayDate,'hours');
            if(diffHours < 12){
                let humanizedHours= moment.duration(diffHours, "hours").humanize(true);
                return humanizedHours;
            }
            if(moment(compareDate).format('L') > moment(todayDate).format('L')){
                return this.translate.instant('TOMORROW');
            }else if(moment(compareDate).format('L') < moment(todayDate).format('L')){
                return this.translate.instant('YESTERDAY');
            }
            return this.translate.instant('TODAY');
        }
        else if(diffDays === -1){
            if(todayDate.subtract('1','days').format('L') == moment(compareDate).format('L'))
            {
                return this.translate.instant('YESTERDAY');
            }
        }
        else if (diffDays === 1){
            if(todayDate.add('1','days').format('L') == moment(compareDate).format('L'))
            {
                return this.translate.instant('TOMORROW');
            }
        }
        let humanizedDate = moment.duration(diffDays, 'days').humanize(true, { d: 7, w: 4, M: 12 });
        return humanizedDate;
    }

    checkAllowedActionList(action: string) : boolean
    {
        let allowedActionCounter=0;

        if(this.actionList.length == 0){
            return true;
        }

        this.actionList.forEach((value) => {
            if (this.allowedActions[value.id][action] === false) {

                // console.log(this.allowedActions[value.id][action]);
                allowedActionCounter++;
            }
        });
        // console.log(allowedActionCounter);
        return allowedActionCounter > 0;
    }

    translateBookingState(state: string): string {

        let message: string = state;

        if (message === 'Storniert') {
            message = this.translate.instant('CANCELED');
        } else if (message === 'Storniert, außerhalb der Frist') {
            message = this.translate.instant('CANCELED_OUTSIDE');
        } else if (message === 'Bezahlt') {
            message = this.translate.instant('PAID');
        } else if (message === 'Zahlung ausstehend') {
            message = this.translate.instant('UNPAID');
        }

        return message;
    }

    getLocation(booking: any) {

        return this.store.pipe(select((state: State) => state.location.all),
            map((locations) => {
                let correctLocation = Object.values<any>(locations).find((location) => location.id == booking.room[0].locationId);
                if (correctLocation != undefined) {
                    if (correctLocation.name != undefined) {
                        return correctLocation.name.replace('Musik Quartier ', 'MQ');
                    }
                }

                return '';

            }),
        );

    }

    navigateToUserProfile() {
        this.router.navigateByUrl('admin/customer/' + this.userId);
    }


    markAllUnpaidBookings() {
        this.actionList = [];
        for (let bookingGroup of this.bookings) {
            for (let booking of bookingGroup[1]) {

                if (booking.paid !== '1' || (booking.stornoWait === '1' && booking.paid !== '1')) {
                    this.addOrRemoveBookingToActionList(true, booking.id, booking);
                }
            }
        }
    }
}