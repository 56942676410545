import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {RequestManagerService} from '../../../services/requestManager/request-manager.service';
import {searchBookings, searchBookingsDone} from '../../../actions/booking.actions';
import {State} from '../../../reducers';
import {Router} from '@angular/router';

@Component({
  selector: 'app-search-bookings-by-id',
  templateUrl: './search-bookings-by-id.component.html',
  styleUrls: ['./search-bookings-by-id.component.css']
})
export class SearchBookingsByIdComponent implements OnInit, OnDestroy {

  constructor(private requestManager: RequestManagerService,
              private store: Store,
              private router: Router) { }

  bookings$;
  searchTerm: string;
  loaded = false;

  ngOnInit(): void {
    this.searchTerm = '';
    this.bookings$ = this.store.select((state: State) => state.searchResult.bookings);

  }

  ngOnDestroy() {
    this.store.dispatch(searchBookingsDone({ bookings: [] }));
  }

  searchBookings() {
    this.store.dispatch(searchBookings({ name: this.searchTerm }));
    this.loaded = true;
  }

  navigateToUser(booking) {
    this.router.navigate(['admin/customer/' + booking.userId], { fragment: booking.id });
  }

  checkNumber(event: any){
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode > 65 && charCode < 90 )) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
