import { Injectable } from "@angular/core";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { cloneDeep } from "lodash";
import {
  map,
  mergeMap,
  filter,
  shareReplay,
  switchMap,
  withLatestFrom,
  tap,
} from "rxjs/operators";
import {
  loadAllRooms,
  loadAllRoomsDone,
  loadCancellationPeriods,
  loadCancellationPeriodSuccess,
  loadRooms,
  loadRoomsDone,
  roomsUpdateDone,
  searchRooms,
  searchRoomsDone,
} from "../actions/room.actions";
import { changeLanguage } from "../actions/ui.actions";
import { selectLocation, State } from "../reducers";
import { ApiService } from "../services/api/api.service";
import { RequestManagerService } from "../services/requestManager/request-manager.service";
import { LocationService } from '../services/location/location.service';

@Injectable()
export class RoomEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store$: Store,
    private requestManager: RequestManagerService,
    private locationService: LocationService
  ) {}

  @Effect()
  search$ = this.actions$.pipe(
    ofType(searchRooms),
    mergeMap((action) => {
      action.name;

      return this.api.searchRooms(action.name).pipe(
        map((rooms: []) => {
          return searchRoomsDone({ rooms });
        })
      );
    })
  );

  @Effect()
  loadCancellationPeriods = this.actions$.pipe(
    ofType(loadCancellationPeriods),
    mergeMap(() => {
      return this.api.getCancellationPeriods().pipe(
        map((response:any) => {
          for(let key in response.room) {
            if(response.room[key].cancellationPeriod==null) {
              response.room[key].cancellationPeriod = 24;
            }
            response.room[key].cancellationPeriod = Number.parseInt(response.room[key].cancellationPeriod);
          }
          return loadCancellationPeriodSuccess({cancellationPeriod:response.room});
        }));
    })
  );
  

  @Effect()
  loadAllRooms$ = this.actions$.pipe(
      ofType(loadAllRooms, changeLanguage),
      withLatestFrom(this.store$.select((state: State) => state)),
      mergeMap(([action, state]) => {

          return this.api.getRooms(null).pipe(
              shareReplay(1),
              map((roomSource: any) => {

                  let rooms = cloneDeep(roomSource);
                  Object.keys(rooms).forEach((key) => {
                      let room = rooms[key];
                      if (room.roomDescription) {
                          Object.keys(room.roomDescription).forEach((langKey) => {
                              let langDesc = room.roomDescription[langKey];
                              if (langDesc.language == state.ui.language) {
                                  room.name = langDesc.name;
                  room.description = langDesc.description;
                }
              });
            }
          });
          return loadAllRoomsDone({ rooms: rooms });
        })
      );
    })
  );

  @Effect()
  load$ = this.actions$.pipe(
      ofType(loadRooms, changeLanguage),

      withLatestFrom(this.store$.select((state) => <State> state)),

      switchMap(([action, state]) => {

          if (state.location.current != null) {
              this.locationService.locationChange$.next(state.location.current.name);
          }

          let locationId = state.location.current
              ? state.location.current.id
              : null;
          return this.api.getRooms(locationId).pipe(
              shareReplay(1),
        map((roomSource) => {
          let rooms = cloneDeep(roomSource);
            if (rooms == undefined || rooms == null) {

                return loadRoomsDone({ rooms: [] });
            }
          Object.keys(rooms).forEach((key) => {
            let room = rooms[key];
            if (room.roomDescription) {
              Object.keys(room.roomDescription).forEach((langKey) => {
                let langDesc = room.roomDescription[langKey];
                if (langDesc.language == state.ui.language) {
                  room.name = langDesc.name;
                  room.description = langDesc.description;
                }
              });
            }
          });


          return loadRoomsDone({ rooms: rooms });
        })
      );
    })
  );

  @Effect({ dispatch: false })
  $roomUpdated = this.actions$.pipe(
    ofType(roomsUpdateDone),
    tap(() => {
      this.requestManager.resetMemory();
    })
  );

  @Effect({ dispatch: false })
    $loadRoomsDone = this.actions$.pipe(
        ofType(loadRoomsDone),
        tap(() => {
            this.locationService.locationChange$.next('');
        })
    )

}
