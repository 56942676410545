import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})


export class RouterService {

    titleSubject$ = new BehaviorSubject('');
    displayFilter$ = new BehaviorSubject(false);

    constructor(private router: Router) {
        router.events.subscribe((event) => {

            if (event instanceof NavigationEnd) {
                this.updateTitleByUrlEvent(event);
            }
        });
    }

    updateTitleByUrlEvent(navigationEndEvent : NavigationEnd) {
        if(navigationEndEvent.url == '/'){
            this.titleSubject$.next('BOOK');
            this.displayFilter$.next(true);
        }
        else if(navigationEndEvent.url == '/customer-dashboard/bookings'){
            this.titleSubject$.next('MY BOOKINGS');
            this.displayFilter$.next(false);
        }
        else if(navigationEndEvent.url == '/customer-dashboard/balance'){
            this.titleSubject$.next('BALANCE');
            this.displayFilter$.next(false);
        }
        else if(navigationEndEvent.url == '/customer-invoices'){
            this.titleSubject$.next('INVOICES');
            this.displayFilter$.next(false);
        }
        else if(navigationEndEvent.url.startsWith('/customer-dashboard-data')){
            this.titleSubject$.next('MY_PROFILE');
            this.displayFilter$.next(false);
        }
        else if(navigationEndEvent.url=='/checkout'){
            this.titleSubject$.next('SHOPPING_CART');
            this.displayFilter$.next(false);
        }
        else{
            this.titleSubject$.next('');
            this.displayFilter$.next(false);
        }
    }
}
