import { Injectable } from '@angular/core';
import { RequestManagerService } from '../requestManager/request-manager.service';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

@Injectable({
    providedIn: 'root',
})
export class IndividualTextService {

    constructor(private requestManager: RequestManagerService,
                private notification: NotifierService,
                private translate: TranslateService) {
    }


    getIndividualTextByLanguage(langcode: string, individualTextField): Observable<any> {
        let where_condition = [
            {
                field: 'language',
                operator: '=',
                model: 'individualText',
                value: langcode,
                followingOperator: 'AND',
            },
            {
                field: 'definition',
                operator: '=',
                model: 'individualText',
                value: individualTextField,
            },
        ];
        return this.requestManager.getAuth('/individualText?array_values=true&where_condition=' + JSON.stringify(where_condition))
            .pipe(
                map((individualTexts) => {
                    let individualText = individualTexts.individualText ?? null;
                    if (Array.isArray(individualText) && individualText.length > 0) {
                        individualText = individualText.pop();
                        console.log(individualText);
                        return individualText['value'] ??
                            this.translate.instant('SUBSCRIBE');
                    }
                    return this.translate.instant('SUBSCRIBE');
                }),
                catchError((error) => {
                    console.log(error);
                    return of(this.translate.instant('SUBSCRIBE'));
                }),
            );
    }
}
