import { createAction, props } from '@ngrx/store';

export const changeLanguage = createAction(
  '[Ui] changeLanguage',
  props<{language:string}>()
);




