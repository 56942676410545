import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { UtilsService } from '../../../services/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { CartManagerService } from '../../../services/cartManager/cart-manager.service';
import { OrderRoomTypeSpecialComponent } from '../../order-room-type-special/order-room-type-special.component';
import { NotifierService } from 'angular-notifier';
import { HostListener } from '@angular/core';
import { RoomManagerService } from '../../../services/roomManager/room-manager.service';
import { ModalSmartphoneRequiredComponent } from '../../modal-smartphone-required/modal-smartphone-required.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
    selector: 'app-bookings',
    templateUrl: './bookings.component.html',
    styleUrls: ['./bookings.component.scss'],
})
export class BookingsComponent implements OnInit, OnChanges {
    @Input() roomList: any[];

   mobile=false;

    innerWidth;
    innerHeight;
  loading = false;

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.innerWidth = window.innerWidth;
        this.innerHeight = window.innerHeight;

        if (this.innerHeight > this.innerWidth) {
            this.mobile = true;
        } else {
            if((innerWidth/16) <= 95){
                this.mobile= true;
            }
            else{
                this.mobile = false;
            }
        }
    }

  constructor(
      private cartManager: CartManagerService,
      public translate: TranslateService,
      private modalService: NgbModal,
      private notifier: NotifierService,
      public roomManager: RoomManagerService,
      private matDialog: MatDialog,
  ) {
      this.onResize();
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
      // process roomList when got data about it
      if (changes.roomList != undefined) {
          if (changes.roomList.currentValue != undefined) {
              // clone object without reference, deep means the object has child-objects
              this.roomManager.processedRoomList = _.cloneDeep(this.roomList);
              this.roomManager.processRoomList(true);
          }
      }
  }


  public colorCell(room, cell): string {
    const classes = "table-padding table-time ";
    if (!cell.available) {
      
      return classes + "closed"
    } else if (cell.booked) {
      //return classes + "booked";
      return classes + "closed";
    } else if (this.isCellOnCart(room, cell)) {
      return classes + " selected";
    }
    return classes + "free";
  }

  private isCellOnCart(room: any, cell: any): boolean {
    let isOnCart = false;

    // create ISO-Time-Strings
    const bookingTimeToTimeString = UtilsService.addEntityLength2TimeString(
      cell.time,
      room.entityLength
    );
    const cellBooking = {
      roomId: room.id,
      bookingTimeFrom: UtilsService.getISOStringOfTimeString(
          this.roomManager.selectedDate.year,
          this.roomManager.selectedDate.month,
          this.roomManager.selectedDate.day,
          cell.time,
      ), // translate Date to ISO-Time-String
      bookingTimeTo: UtilsService.getISOStringOfTimeString(
          this.roomManager.selectedDate.year,
          this.roomManager.selectedDate.month,
          this.roomManager.selectedDate.day,
          bookingTimeToTimeString,
      ), // translate Date to ISO-Time-String
    };

    // check if created cellBooking is on cart
    this.cartManager.getCart().forEach((booking) => {
      if (
        booking.roomId == cellBooking.roomId &&
        booking.bookingTimeFrom == cellBooking.bookingTimeFrom
      ) {
        isOnCart = true;
        return;
      }
    });
    return isOnCart;
  }

  /**
   * Adds/Deletes a booking from the cartManagerService
   * @param room
   * @param cell
   */
  addToCart(room: any, cell: any): void {
    const bookingTimeToTimeString = UtilsService.addEntityLength2TimeString(
      cell.time,
      room.entityLength
    );
    const cartObject: any = {
      roomId: room.id,
      bookingTimeFrom: UtilsService.getISOStringOfTimeString(
          this.roomManager.selectedDate.year,
          this.roomManager.selectedDate.month,
          this.roomManager.selectedDate.day,
          cell.time,
      ), // translate Date to ISO-Time-String
      bookingTimeTo: UtilsService.getISOStringOfTimeString(
          this.roomManager.selectedDate.year,
          this.roomManager.selectedDate.month,
          this.roomManager.selectedDate.day,
          bookingTimeToTimeString,
      ), // translate Date to ISO-Time-String
    };


      // check if booking is available
    if (cell.available && !cell.booked) {
      let formId = room.formId;
      if (formId) {
        let modalRef = this.modalService.open(OrderRoomTypeSpecialComponent);
        modalRef.componentInstance.room = room;
        modalRef.componentInstance.formId = formId;
        modalRef.componentInstance.booking = UtilsService.getISOStringOfTimeString(
            this.roomManager.selectedDate.year,
            this.roomManager.selectedDate.month,
            this.roomManager.selectedDate.day,
            cell.time,
        );
        modalRef.result.then((result) => {
          if (result !== false) {
            cartObject.specialInfo = result;
          }
          if (!this.cartManager.deleteByObjectFromCart(cartObject)) {

              this.cartManager.addToCart(cartObject);
            this.notifier.show({
              type: "success",
              message: this.translate.instant("cart.addProduct"),
            });
          }
        });
      } else {

        // create ISO-Time-Strings

        // handle input in cartManagerService
        if (!this.cartManager.deleteByObjectFromCart(cartObject)) {
          this.cartManager.addToCart(cartObject);
          this.notifier.show({
            type: "success",
            message: this.translate.instant("cart.addProduct"),
          });
        }
      }
    }
  }

    counter(spanTotal: number) {
        return new Array(spanTotal);
    }


    dateChanged(value) {

        let today = new Date();

        let selectedValue = new Date();

        selectedValue.setDate(value._i.date);
        selectedValue.setMonth(value._i.month);
        selectedValue.setFullYear(value._i.year);

        let todayNextYear = new Date();

        todayNextYear.setFullYear(today.getFullYear() + 1);
        if (today <= selectedValue && selectedValue < todayNextYear) {
            const newDate: NgbDate = new NgbDate(value._i.year, value._i.month + 1, value._i.date);
            this.roomManager.selectedDate = newDate;
            console.log(this.roomManager.selectedDate);
            this.roomManager.loadingSubject$.next(true);
        }
    }


    getLengthRoomTitleCellEnd(roomCells: any) {
        if (roomCells != undefined && roomCells.length > 0) {
            return roomCells[0].position - 1;
        }
        return 1;
    }

    openSmartphoneRequired() {
        this.matDialog.open(ModalSmartphoneRequiredComponent);
    }
}
