import { Injectable } from '@angular/core';

export interface ISession {
  jwt : string,
  user: any,
  url : string
}

@Injectable({
  providedIn: 'root'
})

export class SessionManagerService {

  constructor() { }

  public getItem( key:string) : string 
  {
    return localStorage.getItem(key);
  };

  public setItem( key:string, data:any ) : string 
  {
    return localStorage.getItem(key);
  };

  public getSessionObject() : any {
    return {
      jwt:null    }
  }
}
