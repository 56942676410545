import { Component, OnInit } from '@angular/core';
import { isArray } from 'lodash';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { RequestManagerService } from '../../../services/requestManager/request-manager.service';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-invoices-cash',
    templateUrl: './invoices-cash.component.html',
    styleUrls: ['./invoices-cash.component.scss'],
})
export class InvoicesCashComponent implements OnInit {

    invoices$;
    loading$ = new BehaviorSubject<boolean>(false);
    limit: number = 25;
    offset: number = 0;
    paginationOptions: number[] = [10, 25, 50, 100];
    reload$ = new BehaviorSubject<boolean>(true);
    nextPageDisabled: boolean = false;


    constructor(private _rm: RequestManagerService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.loading$.next(true);

        this.route.params.subscribe(params => {

            if (params['id'] != undefined) {

                this.invoices$ =
                    combineLatest([this.reload$]).pipe(
                        switchMap(([reloadValue]) => {
                            if (reloadValue) {
                                return this._rm.getAuth('/receipts/all/user/'
                                    + params['id']
                                    + '?limit=' + this.limit
                                    + '&offset=' + this.offset).pipe(
                                    map((receipts: any) => {
                                        if (receipts.length == 0) {
                                            this.nextPageDisabled = true;
                                            this.loading$.next(false);
                                            return [];
                                        }
                                        this.nextPageDisabled = false;
                                        this.loading$.next(false);
                                        return receipts;
                                    }),
                                );
                            }
                            this.nextPageDisabled = true;
                            this.loading$.next(false);
                            return of([]);
                        }),
                    );
            } else {
                this.invoices$ =
                    combineLatest([this.reload$]).pipe(
                        switchMap((value) => {
                            if (value) {
                                return this._rm.getAuth('/receipts?limit=' + this.limit
                                    + '&offset=' + this.offset
                                    + '&array_values=true'
                                    + '&order_by=table_receipts_dao.entryCreated'
                                    + '&order_by_order=DESC').pipe(
                                    map((res: any) => {
                                        if (res.length == 0) {
                                            this.nextPageDisabled = true;
                                            this.loading$.next(false);
                                            return [];
                                        }
                                        this.nextPageDisabled = false;
                                        this.loading$.next(false);
                                        return Object.values(res.receipts);
                                    }),
                                );
                            }
                            this.nextPageDisabled = true;
                            this.loading$.next(false);
                            return of([]);
                        }),
                    );
            }
        });
    }
  getBaseURL(){
    return environment.apiUrl;
  }

    getDownload(id) {
        this._rm.getAuth('/r2o/receipts/' + id + '/pdf').subscribe((response: any) => {
            if (response.uri) {
                window.open(response.uri);
            }

        });
    }

    limitChange() {
        this.loading$.next(true);
        this.reload$.next(true);
    }

    previousPageButtonIsDisabled() {
        return (this.offset - this.limit) < 0;
    }


    getNextPage() {
        this.offset += this.limit;
        this.loading$.next(true);
        this.reload$.next(true);
    }

    getPreviousPage() {
        this.offset -= this.limit;
        if (this.offset < 0) {
            this.offset = 0;
        }
        this.loading$.next(true);
        this.reload$.next(true);
    }


}
