import { createAction, props } from '@ngrx/store';

export const loadRoomtypes = createAction(
  '[Roomtype] Load Roomtypes'
);

export const loadRoomtypesDone = createAction(
  '[Roomtype] Load Roomtypes Done',
  props<{roomTypes:[]}>()
);



