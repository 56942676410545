import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {UtilsService} from '../../services/utils/utils.service';
import {RequestManagerService} from '../../services/requestManager/request-manager.service';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.css']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {
  constructor(
    private ngxService: NgxUiLoaderService,
  ) {
  }

  ngOnInit() {
    this.ngxService.start();
  }

  ngOnDestroy(){
    this.ngxService.stop();
    //this.ngxService.stopAll();
  }

}
