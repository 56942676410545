import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from "@ngx-translate/core";
import { NotifierService } from 'angular-notifier';
import { BehaviorSubject, throwError } from 'rxjs';
import {
    catchError,
    filter,
    map,
    take,
    tap,
} from 'rxjs/operators';


import { ApiService } from '../../../services/api/api.service';
import { BookingService } from '../../../services/booking/booking.service';
import { AdminCheckoutService } from './checkout.service';


@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit {
    enabled$ = new BehaviorSubject(true);
    locations$;
  bookings$ = new BehaviorSubject<any[]>([]);
  paymentMethods$;
  payerUser$;
  cashRegisterUserId = null;
    locationDetails = null;


  totalSum$ = new BehaviorSubject<number>(0);

  paymentLocationId;
  showNumberOfBookings = 5;
  expandBookings = false;

  @Input()
  public customerIdInput;
    @Input()
    public bookingsInput;

    formGroup = this.fb.group({
        locationId: new FormControl('', Validators.required),
        paymentMethodId: new FormControl('', Validators.required),
        print: new FormControl(true, Validators.required),
        numberPrints: new FormControl('1', Validators.required),
    });


    constructor(
        private fb: FormBuilder,
        private store: Store,
        private checkoutService: AdminCheckoutService,
        private notifier: NotifierService,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
        private zone: NgZone,
        private api: ApiService,
        private ngModal: NgbModal,
        private modalRef: NgbActiveModal,
        private bookingService: BookingService,
    ) {


        this.formGroup.valueChanges.subscribe();
        this.formGroup
            .get('locationId')
            .valueChanges.pipe(
            tap((locationId) => {
                this.changedLocation(locationId);
            }),
        )
            .subscribe();

        const locationId = localStorage.getItem('selectedCashregisterLocation');
        this.paymentLocationId = locationId;

        this.checkoutService.getLocationDetails(locationId)
            .pipe(
                take(1),
                tap((locationDetails) => {


                    this.locationDetails = locationDetails['locations'][locationId] ?? null;

                    this.formGroup.patchValue(
                        { numberPrints: this.locationDetails.numberDefaultPrints },
                        { emitEvent: true },
                    );
                }),
            )
            .subscribe();


        this.payerUser$ = this.checkoutService.getPayerUser(locationId).pipe(
            map((response: any) => {
                if (response.length == 0) {
                    throw('usernotfound');
                } else {
                    return Object.values(response.receiptEmployees)[0];
                }
            }),
            tap((userResponse: any) => {
        this.cashRegisterUserId = userResponse?.cashRegisterUserId;
      }),
      catchError((error: any) => {
        this.modalRef.dismiss('');
        this.notifier.show({
          message: "Die Einstellungen für Ready2Order sind leider nicht vollständig.",
          type: "error",
        });
        return throwError(error);
      })
    );

    this.payerUser$.subscribe();
  }

  ngOnInit(): void {

    this.bookings$
      .pipe(
        filter((bookings) => bookings.length > 0),
        map(this.bookingService.bookingTimeToDatePipe)
      )
      .subscribe((bookings: any) => {
        let total = bookings.reduce((total, booking) => {
          return total + Number.parseFloat(booking.price);
        }, 0);
        this.totalSum$.next(total);

      });
    this.formGroup.patchValue(
      { locationId: localStorage.getItem("selectedCashregisterLocation") },
      { emitEvent: true }
    );

      if (this.bookingsInput) {
      this.bookings$.next(this.bookingsInput);
    }
  }

  firstBookingsOrAll(bookingsArray: any[]) {
    if(this.expandBookings) {
      return bookingsArray;
    }
    else {return bookingsArray.slice(0,this.showNumberOfBookings); }

  }

  clickExpandBookings(){
    this.expandBookings = true;
  }

  isMore(bookingsArray){
    return bookingsArray.length > this.showNumberOfBookings
  }

  getBookingRoomName(booking: any) {
    if (booking.room && booking.room[0] && booking.room[0].roomDescription) {
      return booking.room[0].roomDescription[0].name;
    } else {

        return booking.roomId;
    }
  }
  changedLocation(id) {
    localStorage.setItem("selectedCashregisterLocation", id);
    this.paymentMethods$ = this.checkoutService
      .getPaymentMethodsForLocation(id)
      .pipe(
        tap((methods) => {
          setTimeout(
            () =>
              this.formGroup.patchValue({
                paymentMethodId: methods[0].paymentId,
              }),
            50
          );
        })
      );
  }

  payBookings(bookings) {
      this.enabled$.next(false);

      const print = this.formGroup.get('numberPrints').value == '2';

      let body = Object.assign(
          { payerUserId: this.customerIdInput }, {
              locationId: this.formGroup.get('locationId').value,
              paymentMethodId: this.formGroup.get('paymentMethodId').value,
              print: print,
          },
      );

      this.checkoutService.payBookings(bookings, body).subscribe(
          () => {
              this.notifier.show({
                  message: this.translate.instant('checkout.success'),
                  type: 'success',
              });
              this.modalRef.close(true);
          },
        (error) => {
          window.confirm('FEHLER \n\n' + this.translate.instant('checkout.error') + ': ' + JSON.stringify(error));
            this.modalRef.close(true);
        }
      );
  }



  clickPaymentMethodButton(paymentMethodId: any) {
    this.formGroup.patchValue({ paymentMethodId: paymentMethodId });
    this.bookings$.pipe(take(1)).subscribe((bookings) => {
      this.payBookings(bookings);
    });
  }
}
