import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';



@Injectable()
export class RoomtypeEffects {



  constructor(private actions$: Actions) {}

}
