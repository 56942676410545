import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestManagerService } from '../../../../../services/requestManager/request-manager.service';

@Component({
    selector: 'app-edit-settings-modal',
    templateUrl: './edit-settings-modal.component.html',
    styleUrls: ['./edit-settings-modal.component.scss'],
})
export class EditSettingsModalComponent implements OnInit {

    form: FormGroup;
    userIsAdmin;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private dialogRef: MatDialogRef<EditSettingsModalComponent>,
                private fb: FormBuilder,
                private requestManager: RequestManagerService) {
        this.userIsAdmin = this.requestManager.getUserIsAdmin();

        this.form = this.fb.group({
            'termOfCredit': new FormControl('', [Validators.pattern(/^\d+$/)]),
            'newsletter': new FormControl(false),
            'discount': new FormControl('', [Validators.required, Validators.pattern(/^(\d{1,10})([.]\d{1,5})*?$/)]),
            'taxFree': new FormControl(false),
        });
        this.form.setValue({
            termOfCredit: this.data.termOfCredit,
            newsletter: this.data.newsletter,
            discount: this.data.discount == null ? '0' : this.data.discount,
            taxFree: this.data.taxFree == '1',
        });
    }

    ngOnInit(): void {
    }

    clickClose() {
        this.dialogRef.close('cancel');
    }

    clickSave() {
        this.data.termOfCredit = this.form.get('termOfCredit').value == '' ? null : this.form.get('termOfCredit').value;
        this.data.discount = this.form.get('discount').value;
        // this.data.newsletter= this.form.get('newsletter').value;
        this.data.taxFree = this.form.get('taxFree').value ? '1' : '0';

        this.dialogRef.close(this.data);
    }

}
