import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { RoomManagerService } from '../../../services/roomManager/room-manager.service';

@Component({
    selector: 'app-rooms',
    templateUrl: './rooms.component.html',
    styleUrls: ['./rooms.component.scss'],
})
export class RoomsComponent implements OnInit, OnChanges {

    @Input() roomList: any;

    constructor(
        public roomManager: RoomManagerService,
    ) {

    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        // process roomList when got data about it
        if (changes.roomList.currentValue != undefined) {
            // clone object without reference, deep means the object has child-objects
            this.roomManager.processedRoomList = _.cloneDeep(this.roomList);
            this.roomManager.processRoomList(true);
        }
        // console.log(changes);
    }

    getFilteredDate() {
        return this.roomManager.getFilteredDate();
    }

}
