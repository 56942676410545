// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
//

// export const environment = {
//     production: false,
//     apiUrl: 'https://api.buchen.musikquartier.at/index.php',
//     baseUrl: 'https://api.buchen.musikquartier.at/',
//     translateVersion: 'staging-2.5.0',
// };

export const environment = {
    production: false,
    apiUrl: 'https://germany.staging.api.buchen.musikquartier.at/index.php',
    baseUrl: 'https://germany.staging.api.buchen.musikquartier.at/',
    translateVersion: 'staging-2.11.0',
};
