import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";

import { combineLatest, from } from "rxjs";
import { mergeMap, map, withLatestFrom } from "rxjs/operators";
import { State } from "../../../reducers";
import { ApiService } from "../../../services/api/api.service";
import { DynamicFormsService } from "../../../services/dynamic-forms.service";
import { RequestManagerService } from "../../../services/requestManager/request-manager.service";

@Component({
  selector: "app-form-entry-detail",
  templateUrl: "./form-entry-detail.component.html",
  styleUrls: ["./form-entry-detail.component.scss"],
})
export class FormEntryDetailComponent implements OnInit {
  formEntry$;
  constructor(
    private requestManger: RequestManagerService,
    private api: ApiService,
    private dynService: DynamicFormsService,
    private route: ActivatedRoute,
    private store: Store
  ) {
    this.formEntry$ = this.route.params.pipe(
      mergeMap((params) => {
        return this.dynService.getFormEntry(params["id"]).pipe(
          mergeMap((formEntry) => {
            return combineLatest([this.store.select((state:State)=>state.data.allRooms),from(
              this.requestManger.getAuth(
                  '/bookings?where_condition=' +
                  JSON.stringify([
                      {
                          model: 'bookings',
                          field: 'formEntriesId',
                          value: params['id'],
                          operator: '=',
                      },
                  ]),
              ))])
            .pipe(
              map(([rooms,response]) => {
                  console.log(rooms);
                if (response == []) {
                  return formEntry;
                }
                formEntry.booking = Object.values<any>(response.bookings)[0];
                formEntry.room = rooms[formEntry.booking.roomId];
                return formEntry;
              })
            );
          }),
          mergeMap((formEntry) => {
            return this.api.getUser(formEntry.booking.userId).pipe( map ( (user:any) => {
               formEntry.user = user;
               return formEntry;
            }));
          })
        );
      })
    );
  }

  ngOnInit(): void {}
}
