import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundup'
})
export class RoundupPipe implements PipeTransform {

  transform(value: number, threshold: number): any {
    if(threshold){
      if((value - parseInt(value.toString()) >= threshold))
        return parseInt(value.toString())+1;
      else
        parseInt(value.toString())
    }
    return Math.ceil(value);
  }
}
