import { NgModule } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { ToDateObjPipe } from './Pipes/to-date-obj.pipe';
import { ReplaceEmail } from './Pipes/replace-email';
import { NgbDatePipe } from './Pipes/ngb-date-pipe';


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '../../../../assets/i18n/', '.json?ver=' + environment.translateVersion || '.json');
}

@NgModule({
    imports: [

        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),

    ],
    declarations: [
        ToDateObjPipe,
        ReplaceEmail,
        NgbDatePipe,
    ],
    exports: [ToDateObjPipe, ReplaceEmail, NgbDatePipe],
    providers: [],

})

export class SharedModule {

}