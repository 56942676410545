import { Component, OnInit } from '@angular/core';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { RequestManagerService } from '../../../services/requestManager/request-manager.service';

import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { compareKeys } from '@datorama/akita/lib/compareKeys';

@Component({
    selector: 'app-invoices-online',
    templateUrl: './invoices-online.component.html',
    styleUrls: ['./invoices-online.component.scss'],
})
export class InvoicesOnlineComponent implements OnInit {

    invoices$;
    loading$ = new BehaviorSubject<boolean>(false);
    selectedInvoices: any = {};
    limit: number = 25;
    offset: number = 0;
    paginationOptions: number[] = [10, 25, 50, 100];
    reload$ = new BehaviorSubject<boolean>(true);
    nextPageDisabled: boolean = false;

    dateRangeFormGroup = new FormGroup({
        start: new FormControl(null, Validators.required),
        end: new FormControl(null, Validators.required),
    });

    currentDate = null;
    firstDayOfMonth = null;

    constructor(private _rm: RequestManagerService,
                private route: ActivatedRoute) {

        // Aktuelles Datum
        this.currentDate = new Date();

        // Erster Tag des aktuellen Monats
        this.firstDayOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);


        this.dateRangeFormGroup.setValue(
            {
                start: this.firstDayOfMonth,
                end: this.currentDate,
            });
    }

    ngOnInit(): void {
        this.loading$.next(true);
        this.route.params.subscribe(params => {

            if (params['id'] != undefined) {

                this.invoices$ =
                    combineLatest([this.reload$]).pipe(
                        switchMap(([reloadValue]) => {
                            if (reloadValue) {
                                return this._rm.getAuth('/internalInvoices/all/user/'
                                    + params['id']
                                    + '?limit=' + this.limit
                                    + '&offset=' + this.offset
                                    + '&from=' + (this.dateRangeFormGroup.get('start').value ?
                                        this.getDate(this.dateRangeFormGroup.get('start').value) : this.getDate(this.firstDayOfMonth))
                                    + '&to=' + (this.dateRangeFormGroup.get('end').value ?
                                        this.getDate(this.dateRangeFormGroup.get('end').value) : this.getDate(this.currentDate))).pipe(
                                    map((invoices: any) => {
                                        if (invoices.length == 0) {
                                            this.loading$.next(false);
                                            this.nextPageDisabled = true;
                                            return [];
                                        }
                                        this.nextPageDisabled = false;
                                        this.loading$.next(false);
                                        return invoices;
                                    }),
                                );
                            }
                            this.nextPageDisabled = true;
                            this.loading$.next(false);
                            return of([]);
                        }),
                    );
            } else {
                this.invoices$ =
                    combineLatest([this.reload$]).pipe(
                        switchMap((value) => {
                            if (value) {
                                const whereCondition = [
                                    {
                                        'model': 'internalInvoices',
                                        'field': 'entryCreated',
                                        'operator': '>=',
                                        'value': this.dateRangeFormGroup.get('start').value ?? this.firstDayOfMonth,
                                        'followingOperator': 'AND',
                                    },
                                    {
                                        'model': 'internalInvoices',
                                        'field': 'entryCreated',
                                        'operator': '<=',
                                        'value': this.dateRangeFormGroup.get('end').value ?? this.currentDate,
                                    },
                                ];
                                return this._rm.getAuth('/internalInvoices?limit=' + this.limit
                                    + '&offset=' + this.offset
                                    + '&array_values=true'
                                    + '&order_by=table_internalInvoices_dao.entryCreated'
                                    + '&order_by_order=DESC'
                                    + '&where_condition=' + JSON.stringify(whereCondition)).pipe(
                                    map((res: any) => {
                                        if (res.length == 0) {
                                            this.nextPageDisabled = true;
                                            this.loading$.next(false);
                                            return [];
                                        }
                                        this.nextPageDisabled = false;
                                        this.loading$.next(false);
                                        return Object.values(res.internalInvoices);
                                    }),
                                );
                            }
                            this.nextPageDisabled = true;
                            this.loading$.next(false);
                            return of([]);
                        }),
                    );
            }
        });

    }
  getBaseURL(){
    return environment.apiUrl;
  }
  getDownload(id) {
    this._rm.getFile('/internalInvoice/'+id+'/pdf').subscribe( (response ) => {
      let reader = new FileReader();


        const file = new Blob([response as BlobPart], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(file);
        window.open(url);

    })
  }


    addOrRemoveInvoiceSelection(checked: boolean, invoice: any) {
        if (checked) {
            this.selectedInvoices[invoice.id] = true;
        } else {
            this.selectedInvoices[invoice.id] = false;
        }

    }


    addSelectionAll(checked: boolean, invoices) {
        for (const invoice of invoices) {
            this.addOrRemoveInvoiceSelection(checked, invoice);
        }
    }

    getLengthSelectedInvoices(selectedInvoices: any): number {
        let countSelectedInvoives = 0;
        Object.values(selectedInvoices).forEach((value: boolean) => {
            if (value) {
                countSelectedInvoives++;
            }
        });
        return countSelectedInvoives;
    }

    downloadInvoicesAsZip() {
        const invoicesIds = [];
        Object.entries(this.selectedInvoices).forEach((entry: [string, boolean]) => {
            if (entry[1] === true) {
                invoicesIds.push(entry[0]);
            }
        });
        const body = { invoiceIds: invoicesIds };
        this._rm.getZipFileInvoices('/internalInvoice/zip', body)
            .pipe(
                take(1),
            )
            .subscribe((response: any) => {
                    const arrayBuffer: ArrayBuffer = response.body; // Extract the ArrayBuffer from the response

                    const blob = new Blob([arrayBuffer], {
                        type: 'application/zip',
                    });

                    const blobUrl = window.URL.createObjectURL(blob);

                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = blobUrl;

                    a.download = 'Invoices.zip';

                    document.body.appendChild(a);
                    a.click();

                    // Aufräumen
                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(a);
                },
            );
    }

    limitChange() {
        this.loading$.next(true);
        this.reload$.next(true);
    }

    previousPageButtonIsDisabled() {
        return (this.offset - this.limit) < 0;
    }


    getNextPage() {
        this.offset += this.limit;
        this.loading$.next(true);
        this.reload$.next(true);
    }

    getPreviousPage() {
        this.offset -= this.limit;
        if (this.offset < 0) {
            this.offset = 0;
        }
        this.loading$.next(true);
        this.reload$.next(true);
    }


    dateRangeChanged() {
        this.loading$.next(true);
        this.reload$.next(true);
    }

    datePickerClosed() {
        this.reload$.next(true);
    }

    getDate(date: any): string {
        if (!(date instanceof Date)) {
            date = new Date(date);
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Monat beginnt bei 0
        const day = String(date.getDate()).padStart(2, '0');

        const formatiertesDatum = `${year}-${month}-${day}`;

        return formatiertesDatum;
    }

}
