import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ApiService} from '../services/api/api.service';
import {Store} from '@ngrx/store';
import {RequestManagerService} from '../services/requestManager/request-manager.service';
import {searchBookings , searchBookingsDone} from '../actions/booking.actions';
import {map, mergeMap} from 'rxjs/operators';

@Injectable()
export class BookingsEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store$: Store,
    private requestManager: RequestManagerService
  ) {
  }

  @Effect()
  search$ = this.actions$.pipe(
    ofType(searchBookings),
    mergeMap((value) => {

      return this.api.searchBookings(value['name']).pipe(
        map((bookings: []) => {
          return searchBookingsDone({bookings});
        })
      );
    })
  );
}
