import { createAction, props } from '@ngrx/store';


export const selectedCheckoutLocation = createAction(
  '[Admin Checkout] Select Admin Location',
  props<{selectedLocationId:string}>()
)


export const addBookingsToCheckout = createAction(
  '[Admin Checkout] Add Bookings',
  props<{bookings:any[]}>()
  )

export const removeBookingFromCheckout = createAction(
  '[Admin Checkout] Remove Booking',
  props<{booking:any}>()
  )


export const removeAllBookingsFromCheckout = createAction(
  '[Admin Checkout] Remove All Bookings'
  )

  

