import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../../services/utils/utils.service';

@Pipe({
  name: "toDateObj",
})
export class ToDateObjPipe implements PipeTransform {
    transform(value: any, checkOffset = true): any {

        if (value instanceof Date) {
            return value;
        }

        if (value) {
            const temp = value.toString().replace(' ', 'T');
            return new Date(temp);
        } else {
      return null;
    }
  }
}