import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../../services/utils/utils.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Pipe({
    name: 'ngbDatePipe',
})
export class NgbDatePipe implements PipeTransform {
    transform(value: NgbDate): string {
        return (value.day >= 10 ? value.day : ('0' + value.day))
            + '.' + (value.month >= 10 ? value.month : ('0' + value.month))
            + '.' + value.year;
    }
}