import { Component, OnInit } from '@angular/core';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';
import { CookieService } from 'ngx-cookie-service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import jwt_decode from 'jwt-decode';
import { LoginService } from './login.service';
import { Store } from '@ngrx/store';
import { changeLanguage } from '../../actions/ui.actions';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    email: string;
    password: string;


    loginForm: FormGroup;
    loggingIn$;


    constructor(
        private requestManager: RequestManagerService,
        private notification: NotifierService,
        private router: Router,
        private _t: TranslateService,
        private fb: FormBuilder,
        private cookieService: CookieService,
        private loginService: LoginService,
        private store: Store,
    ) {

        this.loggingIn$= this.loginService.loggingIn$;
      this.loginForm = this.fb.group({
          'email': new FormControl(undefined,[Validators.required,
              Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
          'password':  new FormControl(undefined,[Validators.required])
      })
  }

  ngOnInit() {}

  passwordReset() {
    this.requestManager
      .post("/password/reset", { username: this.email })
      .subscribe(
        () => {
          this.notification.show({
            message: this._t.instant("login.password_reset"),
            type: "success",
          });
        },
        () => {
          this.notification.show({
            message: this._t.instant("login.wrong"),
            type: "error",
          });
        }
      );
  }

  login(): void {
      this.loginService.showLoggingIn();
    this.requestManager.auth(this.email, this.password).then(
      (success) => {

          this.isUserAdmin();
      },
      (error) => {
        this.notification.show({
          message: this._t.instant("login.wrong"),
          type: "error",
        });
        this.loginService.hideLogginIn();
      }
    );
  }
    private isUserAdmin() {
        if (this.cookieService.get("jwt")) {
            const userId = (<any> jwt_decode(this.requestManager.getJWT())).data
                .user_id;
            this.requestManager
                .get("/user/" + userId, this.requestManager.getJWT())
                .then((response) => {
                    this.loginService.hideLogginIn();
                    if (response.user[userId].language !== undefined && response.user[userId].language !== null) {
                        this.store.dispatch(changeLanguage({ language: response.user[userId].language }));
                        this._t.use(response.user[userId].language.toLowerCase());
                    }
                    if (response.user[userId].isAdmin === '1' || response.user[userId].isOffice === '1') {
                        this.router.navigateByUrl('admin/bookings');
                    } else {
                        this.router.navigateByUrl('');
                    }
                    this.loginService.isUserAdmin();
                }, (error)=> {
                    this.router.navigateByUrl('');

                    this.loginService.hideLogginIn();
                });
        }
    }


    openRegistration() {
      this.router.navigateByUrl('register');
    }
}
