import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-user-address-data-modal',
  templateUrl: './edit-user-address-data-modal.component.html',
  styleUrls: ['./edit-user-address-data-modal.component.scss']
})
export class EditUserAddressDataModalComponent implements OnInit {

    form: FormGroup;
    userData;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private dialogRef: MatDialogRef<EditUserAddressDataModalComponent>,
                private fb: FormBuilder
    ) {
        this.form= this.fb.group({
            'street': new FormControl("",[Validators.required]),
            'zip': new FormControl("",[Validators.required]),
            'city': new FormControl("",[Validators.required]),
            'country': new FormControl("",[Validators.required])

        })

        this.form.setValue({
           street: this.data.street,
            zip: this.data.zip,
            city: this.data.city,
            country: this.data.country,
        });

    }

    ngOnInit(): void {
    }

    clickClose() {
        this.dialogRef.close('cancel');
    }

    clickSave()
    {
        this.data.street= this.form.get('street').value;
        this.data.zip= this.form.get('zip').value;
        this.data.city= this.form.get('city').value;
        this.data.country= this.form.get('country').value;

        this.dialogRef.close(this.data);
    }

}
