import { Component, Input, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { FilterRoomsService } from '../../../services/filter/filter-rooms/filter-rooms.service';
import { debounceTime } from 'rxjs/operators';
import * as _moment from 'moment';
import { NotifierService } from 'angular-notifier';

const moment = _moment;

@Component({
    selector: 'app-filter-rooms',
    templateUrl: './filter-rooms.component.html',
    styleUrls: ['./filter-rooms.component.scss'],
})
export class FilterRoomsComponent implements OnInit {


    @Input() color: string = 'primary';
    onlyFreeRoomsFilterActive$;

    sliderOptions: Options = {
        floor: 1,
        ceil: 100,
        translate: (value: number): string => {
            return value + ' €';
        },
    };


    constructor(public filterRoomService: FilterRoomsService,
                private notifier: NotifierService,
    ) {
        this.onlyFreeRoomsFilterActive$ = this.filterRoomService.onlyFreeRoomsFilterActive$;

        this.filterRoomService.filterDay.valueChanges.pipe(debounceTime(2000)).subscribe(() => {
            if (this.filterRoomService.onlyFreeRoomsFilterActive$.value && this.filterRoomService.filterActivated$.value) {
                this.checkFilterAndTriggerFilter();
            }
        });

        this.filterRoomService.filterTimeTo.valueChanges.pipe(debounceTime(2000)).subscribe(() => {
            if (this.filterRoomService.onlyFreeRoomsFilterActive$.value && this.filterRoomService.filterActivated$.value) {
                this.checkFilterAndTriggerFilter();
            }
        });

        this.filterRoomService.filterTimeFrom.valueChanges.pipe(debounceTime(2000)).subscribe(() => {
            if (this.filterRoomService.onlyFreeRoomsFilterActive$.value && this.filterRoomService.filterActivated$.value) {
                this.checkFilterAndTriggerFilter();
            }
        });
    }


    ngOnInit(): void {
    }

    checkFilterAndTriggerFilter() {
        if (this.filterRoomService.filterDay.valid && this.filterRoomService.filterTimeTo.valid && this.filterRoomService.filterTimeFrom.valid) {
            this.filterRoomService.filterActivated$.next(true);
        } else {
            if (this.onlyFreeRoomsFilterActive$.value) {
                this.filterRoomService.filterActivated$.next(false);
                this.notifier.notify('error', 'Filter sind fehlerhaft!');
            }
        }

    }

    menuClosed() {
        this.checkFilterAndTriggerFilter();
    }


    stopPropagation(event) {
        event.stopPropagation();
    }


    valueChanged(value: any) {
        this.filterRoomService.minValuePrice$.next(value);
        // this.filterRoomService.filterActivated$.next(true);
    }

    highValueChanged(highValue: any) {
        this.filterRoomService.maxValuePrice$.next(highValue);
        // this.filterRoomService.filterActivated$.next(true);
    }

    onlyFreeRoomsFilterActiveChanged(value: any) {
        this.filterRoomService.onlyFreeRoomsFilterActive$.next(value);
    }

    resetFilter(event) {
        this.stopPropagation(event);
        this.filterRoomService.resetParameters();
        this.checkFilterAndTriggerFilter();
    }
}
