import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import {AllroomsComponent} from './components/allrooms/allrooms.component';
import {AdminBookingsComponent} from './components/admin/admin-bookings/admin-bookings.component';
import {ClientListComponent} from './components/admin/client-list/client-list.component';

import {CustomerDashboardComponent} from './components/customer/customer-dashboard/customer-dashboard.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { AccountBalanceComponent } from './components/account-balance/account-balance.component';
import { CustomerDashboardBookingsComponent } from './components/customer/customer-dashboard/customer-dashboard-bookings/customer-dashboard-bookings.component';

import { AdminCustomerComponent } from './components/admin/admin-customer/admin-customer.component';
import { PageComponent } from './components/page/page.component';
import { BatchBookingComponent } from './components/batch-booking/batch-booking.component';
import { CheckoutComponent as AdminCheckout} from './components/admin/checkout/checkout.component';
import { FormEntryInputComponent } from './components/form-entry-input/form-entry-input.component';
import { FormEntryDetailComponent } from './components/admin/form-entry-detail/form-entry-detail.component';
import { LoginComponent } from './components/login/login.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { CustomerDashboardDataComponent } from './components/customer/customer-dashboard/customer-dashboard-data/customer-dashboard-data.component';
import { InvoicesComponent } from './components/customer/invoices/invoices.component';
import { AuthenticationGuard } from './authentication.guard';
import { TanValidationComponent } from './components/tan-validation/tan-validation.component';
import { BookingsExportComponent } from './components/admin/bookings-export/bookings-export.component';


export const routes: Routes = [
  {path: '',  canActivateChild:[AuthenticationGuard], component: DefaultLayoutComponent, data: { title: 'Home' }, children: [
          { path: '', component: AllroomsComponent },
          { path: 'login', component: LoginComponent },
          { path: 'register', component: RegistrationComponent },
          { path: 'location/:id', component: AllroomsComponent },
          { path: 'admin/bookings', component: AdminBookingsComponent },
          { path: 'admin/bookings/export', component: BookingsExportComponent },
          { path: 'admin/customers', component: ClientListComponent },
          {
              path: 'admin/voucher',
              loadChildren: () => import('./components/admin/voucher/admin.voucher.module').then(m => m.AdminVoucherModule),
          },
          {
              path: 'admin/room/cashRegister',
              loadChildren: () => import('./components/admin/rooms-cash-register-manager/admin.rooms-cash-register-manager.module').then(m => m.AdminRoomsCashRegisterManagerModule),
          },
          {
              path: 'admin/user/cashRegister',
              loadChildren: () => import('./components/admin/user-cash-register-manager/admin.user-cash-register-manager.module').then(m => m.AdminUserCashRegisterManagerModule),
          },
          {
              path: 'admin/room/create',
              loadChildren: () => import('./components/admin/room/create-room/admin.create-room.module').then(m => m.AdminCreateRoomModule),
          },
          {
              path: 'admin/room/search',
              loadChildren: () => import('./components/admin/room/room-list/admin.room-list.module').then(m => m.AdminRoomListModule),
          },
          {
              path: 'admin/room/order',
              loadChildren: () => import('./components/admin/room/room-order/admin.room-order.module').then(m => m.AdminRoomOrderModule),
          },
          {
              path: 'admin/roomtype/create',
              loadChildren: () => import('./components/admin/room/create-roomtype/admin.create-roomtype.module').then(m => m.AdminCreateRoomtypeModule),
          },
          {
              path: 'admin/roomtype/search',
              loadChildren: () => import('./components/admin/room/roomtype-list/admin.roomtype-list.module').then(m => m.AdminRoomtypeListModule),
          },
          {
              path: 'admin/settings',
              loadChildren: () => import('./components/admin/settings/admin.settings.module').then(m => m.AdminSettingsModule),

          },
          {
              path: 'admin/locations',
              loadChildren: () => import('./components/admin/location/admin.location.module').then(m => m.AdminLocationModule),
          },
          { path: 'admin/customer/:id', component: AdminCustomerComponent },
          { path: 'admin/booking-batch', component: BatchBookingComponent },
          { path: 'customer-dashboard', component: CustomerDashboardComponent },
          { path: 'admin/checkout', component: AdminCheckout },
          { path: 'customer-dashboard/bookings', component: CustomerDashboardBookingsComponent },
          {
              path: 'admin/dynamic-forms',
              loadChildren: () => import('./components/admin/dynamic-forms/dynamic-form-overview/admin.dynamic-form-overview.module').then(m => m.AdminDynamicFormOverviewModule),

          },
          {
              path: 'admin/dynamic-forms/:id',
              loadChildren: () => import('./components/admin/dynamic-forms/dynamic-form-edit/admin.dynamic-form-edit.module').then(m => m.AdminDynamicFormEditModule),
          },
          {
              path: 'admin/dynamic-form-entries',
              loadChildren: () => import('./components/admin/dynamic-forms/dynamic-form-entries/admin.dynamic-form-entries.module').then(m => m.AdminDynamicFormEntriesModule),
          },
          { path: 'admin/form-entry/:id', component: FormEntryDetailComponent },
          { path: 'customer-dashboard/bookings/:id', component: CustomerDashboardBookingsComponent },
          { path: 'customer-dashboard/balance/:id', component: AccountBalanceComponent },
          { path: 'customer-dashboard/balance', component: AccountBalanceComponent },
          { path: 'customer-dashboard/:id', component: CustomerDashboardComponent },
          { path: 'customer-invoices', component: InvoicesComponent },
          { path: 'customer-invoices/:id', component: InvoicesComponent },
          { path: 'checkout', component: CheckoutComponent },
          { path: 'page/:page', component: PageComponent },
          { path: 'form/:id', component: FormEntryInputComponent },
          {
              path: 'admin/accountsReceivable',
              loadChildren: () => import('./components/admin/accounts-receivable/admin.accounts-receivable.module').then(m => m.AdminAccountsReceivableModule),
          },
          {
              path: 'admin/r2o-checkout',
              loadChildren: () => import('./components/admin/r2o-checkout/admin.r2o-checkout.module').then(m => m.AdminR2oCheckoutModule),

          },
          { path: 'customer-dashboard-data/:id', component: CustomerDashboardDataComponent },
          { path: 'validation/:id', component: TanValidationComponent },
          { path: 'validation/booking/:id', component: TanValidationComponent },
      ]
  },
  // { path: '**', component: P404Component }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
