import { Injectable } from "@angular/core";
import { RequestManagerService } from '../requestManager/request-manager.service';
import { UtilsService } from '../utils/utils.service';
import { each, isDate, values } from 'lodash';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { from, Observable } from 'rxjs';
import { NgbDate, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutComponent } from '../../components/admin/checkout/checkout.component';
import * as moment from 'moment';
import 'moment-timezone';

@Injectable({
    providedIn: 'root',
})
export class BookingService {

    constructor(
        private requestManager: RequestManagerService,
        private UtilsService: UtilsService,
        private store: Store,
        private modalService: NgbModal,
    ) {
    }

    isStornoable(booking) {
    if (booking == undefined) {
      return false;
    }
    if (booking.storno == undefined) {
      booking.storno = 0;
    }
    return (
      (booking.storno == 0 || booking.storno == "" || booking.storno == null) &&
      this.isInTheFuture(booking)
    );
  }

  isInTheFuture(booking, addHours = 0) {
    let date = Date.parse(this.formatDate(booking.bookingTimeFrom));

    let today = new Date(Date.now() + 60 * 60 * addHours * 1000).getTime();

    return date > today;
  }


    cancelBookings(cancelBookingsList: { id: string, force: boolean }[]): Observable<any> {
        return this.requestManager.post('/bookings/cancel', cancelBookingsList, this.requestManager.getJWT());
    }


    openPayBookingModal(bookings: any[], customerId: number): NgbModalRef {
        const modal = this.modalService.open(CheckoutComponent);
        modal.componentInstance.bookingsInput = bookings;
        modal.componentInstance.customerIdInput = customerId;
        return modal;
    }

    getStornoState(booking): Observable<'storno' | 'stornoWait' | 'noStorno'> {
        return this.store
      .select((state: State) => state.data.cancellationPeriods)
      .pipe(
        map((cancellationPeriods) => {
          if (
            this.isStornoable(booking) &&
            this.isInTheFuture(
              booking,
              cancellationPeriods[booking.roomId].cancellationPeriod
            )
          ) {
            return "storno";
          } else if (this.isStornoable(booking)) {
            return "stornoWait";
          } else {
            return "noStorno";
          }
        })
      );
  }

  getBooking(id: string): Observable<any> {
    return this.requestManager.getAuth("bookings/"+id+"/room/roomDescription").pipe(
      map((response) => {
        if (Object.values(response.bookings)[0]) {
          let booking:any = Object.values(response.bookings)[0];
          booking.room = Object.values(booking.room);
          booking.room.forEach(room => {
              room.roomDescription = Object.values(room.roomDescription);
          })
            return booking;
        } else {
            return {};
        }
      })
    );
  }

    getNextDateNgb(todayDate: NgbDate) {


// Konvertiere das ngbDate-Objekt in ein Date-Objekt
        const jsDate = moment({
            year: todayDate.year,
            month: todayDate.month - 1,
            day: todayDate.day,
        });

        let currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        jsDate.tz(currentTimeZone);

        // Füge einen Tag zum aktuellen Datum hinzu
        jsDate.add(1, 'day');


        // Konvertiere das aktualisierte Moment-Date-Objekt zurück in ein NgbDate-Objekt
        const nextDay: NgbDate = new NgbDate(jsDate.year(), jsDate.month() + 1, jsDate.date());

        return nextDay;
    }

    getBookings(date: NgbDate, rooms, roomFilter = null) {
        let forceRefresh = true;
        let bookingsList = [];

        let toDateNext = this.getNextDateNgb(date);

        const zeroPad = (num, places) => String(num).padStart(places, '0');
        let fromDate =
            date.year + '-' + zeroPad(date.month, 2) + '-' + zeroPad(date.day, 2);
        let toDate =
            toDateNext.year + '-' + zeroPad(toDateNext.month, 2) + '-' + zeroPad(toDateNext.day, 2);
        let params;
        if (roomFilter == null) {
            params = {
                where_condition: JSON.stringify([
          {
            field: "bookingTimeFrom",
            model: "bookings",
            value: '"' + fromDate + ' 00:00:00"',
            operator: ">",
            followingOperator: "and",
          },
          {
            field: "bookingTimeTo",
            model: "bookings",
            value: '"' + toDate + ' 00:00:00"',
            operator: "<",
          },
        ]),
        forceWhereExplicit: true,
      };
    } else {
        let fromDate =
            roomFilter.filterDate.year() + '-' + zeroPad(roomFilter.filterDate.month() + 1, 2) + '-' + zeroPad(roomFilter.filterDate.date(), 2);

        let fromTime = ' ' + roomFilter.fromTime + ':00';
        let toTime = ' ' + roomFilter.toTime + ':00';
        params = {
            where_condition: JSON.stringify([
                {
                    field: 'bookingTimeFrom',
                    model: 'bookings',
                    value: '"' + fromDate + fromTime + '"',
                    operator: '>=',
                    followingOperator: 'and',
                },
                {
                    field: 'bookingTimeTo',
                    model: 'bookings',
                    value: '"' + fromDate + toTime + '"',
                    operator: '<=',
                },
        ]),
        forceWhereExplicit: true,
      };
    }

    // get bookings and set cells booked
      return from(
    this.requestManager
      .get("/bookings", this.requestManager.getJWT(), forceRefresh, params)).
      pipe(map((bookings) => {

        bookingsList = values(bookings["bookings"]);

          bookingsList = this.bookingTimeToDatePipe(bookingsList);

          bookingsList.forEach((booking) => {

              let bookingIdKey;

              if (rooms) {
            // The Array with the Rooms has not tbe bookingId as Key - lets find the Booking Id
            rooms.forEach((value, key) => {
              if (value.id == booking.roomId) {
                bookingIdKey = key;

              }

            });

            // create bookings property if undefined
            if (typeof rooms[bookingIdKey] !== "undefined") {
              if (rooms[bookingIdKey]["bookings"] == undefined) {
                rooms[bookingIdKey]["bookings"] = [];
              }

              rooms[bookingIdKey]["bookings"].push(booking);

              // copy cell to local variable
              const cells = rooms[bookingIdKey]["cells"];


                // create time string for comparison
              for (let i = 0; i < cells.length; i++) {
                const actBookingTimeString =
                  UtilsService.addEntityLength2TimeString(
                    "00:00",
                    new Date(
                      this.formatDate(booking["bookingTimeFrom"])
                    ).getMinutes() +
                      new Date(
                        this.formatDate(booking["bookingTimeFrom"])
                      ).getHours() *
                        60
                  );
                // if room is booked at time string set booked parameter to true
                if (
                  cells[i]["time"] === actBookingTimeString &&
                  booking.storno != "1"
                ) {
                    rooms[bookingIdKey]['cells'][i].paid = booking.paid;
                    rooms[bookingIdKey]['cells'][i].booked = true;
                }


              }
            }

          }

        });
          return rooms;
          })
      );
  }

  public formatDate(datestring: any) {
    if (datestring instanceof Date) {
      return datestring;
    }
    if (datestring.indexOf("T") == -1) {
      return datestring.replace(/-/g, "/");
    } else return datestring;
  }

  public bookingTimeToDatePipe(bookings: any[]) {
    each(bookings, (booking) => {
      if(!isDate(booking.bookingTimeFrom)) {
        booking.bookingTimeFrom = new Date(booking.bookingTimeFrom);
        let offsetFrom = booking.bookingTimeFrom.getTimezoneOffset();
        (booking.bookingTimeFrom as Date).setTime(
          booking.bookingTimeFrom.getTime() - offsetFrom * -60000
        );
      }

      if(!isDate(booking.bookingTimeTo)) {
        booking.bookingTimeTo = new Date(booking.bookingTimeTo);


        let offsetTo = booking.bookingTimeTo.getTimezoneOffset();

        (booking.bookingTimeTo as Date).setTime(
          booking.bookingTimeTo.getTime() - offsetTo * -60000
        );
      }


    });
      return bookings;
  }

    payBookingsByCredit(bookingIds: string[], userId: string): Observable<any> {
        return this.requestManager.put(
            '/user/' + userId + '/payBookings/voucher',
            this.requestManager.getJWT(),
            { bookingIds: bookingIds });
    }


    unpayBookings(bookingIds: string[], userId: string, confirmAttribute: boolean = false): Observable<any> {
        if (confirmAttribute == true) {
            if (!confirm('Wollen Sie die Buchungen wirklich auf unbezahlt setzen?')) {
                return;
            }
        }
        return this.requestManager
            .put('/user/' + userId + '/unpayBookings', this.requestManager.getJWT(), { bookingIds: bookingIds });
    }


}
