import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { RequestManagerService } from '../requestManager/request-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from '../booking/booking.service';
import { ApiService } from '../api/api.service';
import { NotifierService } from 'angular-notifier';
import { FilterRoomsService } from '../filter/filter-rooms/filter-rooms.service';
import { delay, map, switchMap, take } from 'rxjs/operators';
import { CartManagerService } from '../cartManager/cart-manager.service';
import { values } from 'lodash';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class RoomManagerService {
    roomList: any[];
    processedRoomList: any[];

    processedRooms$ = new BehaviorSubject([]);
    public tableSettings: { opening: string; closing: string; spanTotal: number };
    loadingSubject$ = new Subject();
    loading$ = new BehaviorSubject(false);
    onlyFreeRoomsFilterActive$;
    filterActivated$;

    public minDate: NgbDate;
    public maxDate: NgbDate;
    public selectedDate: NgbDate;
    oldDate;


    constructor(private requestManager: RequestManagerService,
                private translate: TranslateService,
                private cartManager: CartManagerService,
                private calendarService: NgbCalendar,
                private bookingService: BookingService,
                private api: ApiService,
                private notifier: NotifierService,
                private filterService: FilterRoomsService) {

        this.filterActivated$ = filterService.filterActivated$;
        this.onlyFreeRoomsFilterActive$ = this.filterService.onlyFreeRoomsFilterActive$;
        this.filterService.filterActivated$.subscribe((value) => {
            if (value) {
                const newDate: NgbDate = new NgbDate(
                    this.filterService.filterDay.value.year(),
                    this.filterService.filterDay.value.month() + 1,
                    this.filterService.filterDay.value.date());
                this.selectedDate = newDate;
                this.processRoomList(true);
            }
        });

        this.tableSettings = {
            opening: '09:00',
            closing: '21:00',
            spanTotal: undefined,
        };


        this.selectedDate = this.calendarService.getNext(this.calendarService.getToday(), 'd',
            UtilsService.getLocalDate().getDate() - this.calendarService.getToday().day);

        this.minDate = _.cloneDeep(this.selectedDate);
        this.maxDate = _.cloneDeep(this.minDate);
        this.maxDate.day = this.minDate.day;
        this.maxDate.month = this.minDate.month;
        this.maxDate.year = this.minDate.year + 1;

        this.loadingSubject$.pipe(delay(1)).subscribe(() => {
            this.processRoomList(true);
        });


        this.cartManager.restartedCart.subscribe((value) => {
            if (value) {
                this.processedRoomList = _.cloneDeep(this.roomList); // clone object without reference, deep means the object has child-objects
                this.processRoomList(true);
            }
        });

    }

    public getToday(): NgbDate {
        return this.calendarService.getToday();
    }

    public subDay(): void {

        if (this.minDate.before(this.selectedDate)) {
            this.selectedDate = this.calendarService.getPrev(
                this.selectedDate,
                'd',
                1,
            );
            this.loadingSubject$.next(true);
            //  this.processRoomList(true);
        }
    }

    public addDay(): void {

        if (this.maxDate.after(this.selectedDate)) {
            this.selectedDate = this.calendarService.getNext(this.selectedDate, 'd', 1);
            this.loadingSubject$.next(true);
        }
    }

    private getSelectedDate() {
        const month =
            this.selectedDate.month >= 10
                ? this.selectedDate.month
                : '0' + this.selectedDate.month.toString();
        const day =
            this.selectedDate.day >= 10
                ? this.selectedDate.day
                : '0' + this.selectedDate.day.toString();
        return {
            year: this.selectedDate.year,
            month: month,
            day: day,
        };
    }

    public processRoomList(forceRefresh): void {
        this.loading$.next(true);
        if (forceRefresh || this.processRoomList == undefined || this.tableSettings.opening == undefined || this.selectedDate != this.oldDate) {
            this.oldDate = this.selectedDate;
            this.processedRoomList = _.cloneDeep(values(this.roomList));
        }

        _.values(this.processedRoomList).forEach((room) => {
            room['colspan'] = room['entityLength'] / 15;
        });
        const selDate = this.getSelectedDate();
        this.api
            .getOpeningHours(selDate.year, selDate.month, selDate.day)
            .pipe(take(1)).pipe(
            map((openingHours: any) => {
                this.tableSettings['opening'] = '24:00';
                this.tableSettings['closing'] = '00:00';
                _.values(this.processedRoomList).forEach((room) => {
                    if (
                        !(
                            openingHours.roomType[room.roomTypeId] &&
                            openingHours.roomType[room.roomTypeId].roomTypeOpeningHour &&
                            Object.values(
                                openingHours.roomType[room.roomTypeId].roomTypeOpeningHour,
                            )[0]
                        )
                    ) {
                        return;
                    }


                    /*
                    Smartphone required is not bookable on same day till 31.10.2022
                     */
                    if (room['smartphoneRequired'] == '1') {

                        let today = this.calendarService.getToday();
                        if (selDate.year == today.year
                            && selDate.month == today.month
                            && selDate.day == today.day) {
                            room['bookableSmartphone'] = true;
                            return;
                        }
                    }

                    room['colspan'] = room['entityLength'] / 15; // calculate colspan for each time entry
                    this.getRoomsOpeningHours(
                        room,
                        Object.values(
                            openingHours.roomType[room.roomTypeId].roomTypeOpeningHour,
                        )[0],
                    );
                    // get rooms opening hours depending on room type

                    this.getOpeningHoursToday(room); // get min opening and max closing hour for today
                });
                this.getAllCells();

                return this.processedRoomList;
            }),
            switchMap(() => {
                return this.getBookings(forceRefresh);
            }),
        ).subscribe();
    }

    private getBookings(forceRefresh = true) {


        let filter = null;
        if (this.filterService.filterActivated$.value && this.filterService.onlyFreeRoomsFilterActive$.value) {
            filter = {
                filterDate: this.filterService.filterDay.value,
                fromTime: this.filterService.filterTimeFrom.value,
                toTime: this.filterService.filterTimeTo.value,
            };
        }
        return this.bookingService.getBookings(
            this.selectedDate,
            this.processedRoomList,
            filter,
        ).pipe(
            map((rooms: any[]) => {
                if (this.filterService.filterActivated$.value) {
                    let filteredPriceRooms = rooms.filter((room) => room.price >= this.filterService.minValuePrice$.value && room.price <= this.filterService.maxValuePrice$.value);
                    if (this.filterService.onlyFreeRoomsFilterActive$.value) {
                        this.processedRoomList = this.getRoomsFilteredByDated(filteredPriceRooms);
                    } else {
                        this.processedRoomList = filteredPriceRooms;
                    }
                } else {
                    this.processedRoomList = rooms;
                }

                this.processedRooms$.next(this.processedRoomList);
                this.loading$.next(false);
            }),
        );

    }

    getRoomsFilteredByDated(rooms) {
        return rooms.filter((room) => {
            if (room.cells != undefined) {
                let cellAvailable = false;
                for (let cell of room.cells) {
                    if (UtilsService.compareTimeStrings(cell.time, this.filterService.filterTimeFrom.value)
                        && !UtilsService.compareTimeStrings(cell.time, this.filterService.filterTimeTo.value)) {
                        if (cell.available == true && (cell.booked == false)) {
                            cellAvailable = true;
                        }
                    }

                }
                if (cellAvailable) {
                    return true;
                }
                return false;
            }
        });
    }


    public getRoomsOpeningHours(room, openingHour): void {
        if (openingHour != undefined) {
            room['openingHours'] = openingHour;

            if (openingHour.openingFrom == '--:--' || openingHour.openingTo == '--:--') {
                return;
            }
            const fromTime = UtilsService.transformTimeString2EntityLength(
                openingHour.openingFrom,
            );
            const toTime = UtilsService.transformTimeString2EntityLength(
                UtilsService.addEntityLength2TimeString(openingHour.openingTo, -room['entityLength']),
            );

            room['roomSpan'] = toTime - fromTime;
            room['cells'] = Math.floor(
                (room['roomSpan'] / (room['entityLength'] / 15)),
            );

        }
    }

    private getOpeningHoursToday(room): void {

        if (room.openingHours != undefined) {
            if (
                UtilsService.compareTimeStrings(
                    this.tableSettings['opening'],
                    room.openingHours.openingFrom,
                )
            ) {

                this.tableSettings['opening'] = room.openingHours.openingFrom;
            }

            if (
                UtilsService.compareTimeStrings(
                    UtilsService.addEntityLength2TimeString(room['openingHours'].openingTo, -room['entityLength']),
                    this.tableSettings['closing'],
                )
            ) {
                this.tableSettings['closing'] = UtilsService.addEntityLength2TimeString(room['openingHours'].openingTo, -room['entityLength']);
            }
        }

        const fromTime = UtilsService.transformTimeString2EntityLength(
            this.tableSettings['opening'],
        );
        const toTime = UtilsService.transformTimeString2EntityLength(
            this.tableSettings['closing'],
        );
        //TODO Span muss anders berechnet werden für Räume mit entityLength != 60
        this.tableSettings['spanTotal'] = toTime - fromTime + 4;
    }

    public getAllCells(): void {
        _.values(this.processedRoomList).forEach((room) => {
            const cells = this.getRoomCells(room);
            if (cells == null || cells.length == 0) {
                room['cells'] = [];
            } else {
                let startPosition = this.getStartPosition(cells);
                let position = startPosition;

                for (let i = 0; i < cells.length; i++) {

                    cells[i].position = position;
                    position += room['colspan'];
                    if (room['openingHours'] != undefined) {
                        const now = new Date();
                        const nowTimeString = UtilsService.transformEntityLength2TimeString(
                            now.getHours() * 60 + now.getMinutes(),
                        );

                        if (
                            UtilsService.compareTimeStrings(
                                cells[i].time,
                                room['openingHours'].openingFrom,
                            ) &&
                            UtilsService.compareTimeStrings(
                                UtilsService.addEntityLength2TimeString(room['openingHours'].openingTo, -room['entityLength']),
                                cells[i].time,
                            )
                        ) {
                            let checkSameDate = true;
                            //check if filter is activated
                            if (this.filterService.filterActivated$.value && this.filterService.onlyFreeRoomsFilterActive$.value) {
                                //check if selected Date is same as filter date
                                if (this.selectedDate.day != this.filterService.filterDay.value.date() ||
                                    this.selectedDate.month != (this.filterService.filterDay.value.month() + 1) ||
                                    this.selectedDate.year != this.filterService.filterDay.value.year()) {
                                    checkSameDate = false;
                                }
                            }
                            if (checkSameDate == true &&
                                this.getToday().equals(this.selectedDate) &&
                                UtilsService.compareTimeStrings(nowTimeString, cells[i].time)
                            ) {
                                cells[i].available = false;
                            } else {
                                cells[i].available = true;
                            }
                        }
                    } else {
                        cells[i].available = false;
                    }
                }
                room['cells'] = cells;
            }
        });
    }

    private getStartPosition(cells: { time: string; available: boolean; booked: boolean; position: number }[]) {

        let openingTime = UtilsService.transformTimeString2EntityLength(this.tableSettings.opening);

        let cellOpeningTime = UtilsService.transformTimeString2EntityLength(cells[0].time);

        let difference = cellOpeningTime - openingTime;


        return difference + 2;
    }


    private getRoomCells(
        room,
    ): { time: string; available: boolean; booked: boolean, position: number }[] {
        let fromTime = room.openingHours != undefined && room.openingHours.openingFrom != '--:--' ? room.openingHours.openingFrom : this.tableSettings.opening;
        let toTime = room.openingHours != undefined && room.openingHours.openingTo != '--:--' ? room.openingHours.openingTo : this.tableSettings.closing;
        return UtilsService.createTimeStringArray(
            fromTime,
            toTime,
            room.entityLength,
        ).map((x) => {
            return {
                time: x,
                available: false,
                booked: false,
                position: 0,
            };
        });
    }

    getIterableRooms(): Observable<any> {
        return this.processedRooms$.pipe(map((processedRoomList) => {
            return _.filter(_.values(processedRoomList), room => room != undefined);
        }));
    }


    getProcessedRoomListCards() {
        this.processedRoomList = [];
        let params = {
            expand: 'true',
        };

        this.requestManager.get('/room', params).then(
            response => {
                this.roomList = _.values(response['Room']);
                this.roomList.forEach((room) => {
                    let newRoom = {};
                    newRoom['type'] = room['RoomTypeId'];
                    newRoom['price'] = room['price'];
                    newRoom['images'] = this.getRoomImages(room);
                    newRoom['instruments'] = this.getRoomInstruments(room);
                    newRoom['description'] = this.getRoomDescriptionFromLanguage(room);
                    this.processedRoomList.push(newRoom);
                });
                return this.processedRoomList;
            });
    }

  getRoomDetail(id: number) {

    let params = {
      expand: 'true'
    };
    let room;
    this.requestManager.get('/room/' + id, params).then(
      response => {
        room= _.values(response['room']);
      }
        
        );
        return room;
  }

  getRoomImages(room) {
    let imageList = [];
    _.values(room['RoomImages']).forEach((image) => {
      if (image.name) {
        const newImage = {
          name: image.name,
          image: image.image
        };
        imageList.push(newImage);
      }
    });
    return imageList;
  }

  getRoomInstruments(room) {
    let instruments = [];
    _.values(room['RoomInstruments']).forEach((instrument) => {
      instruments.push(instrument.instrument);
    });
    return instruments;
  }

  /*
  * Add 'description' for every room depending on actual language
  * */
  getRoomDescriptionFromLanguage(room) {
    // loop over all description to find right language
    _.values(room['RoomDescription']).forEach((description) => {
      if (description.language.toLowerCase() == this.translate.currentLang)
        return {
          name: description.name,
          description: description.description,
        };
    });
  }

    getRoomOpeningHours(room) {
        _.values(room['RoomDescription']).forEach((description) => {
            if (description.language.toLowerCase() == this.translate.currentLang) {
                return {
                    name: description.name,
                    description: description.description,
                };
            }
        });
    }

    getFilteredDate() {
        return this.selectedDate;
    }
}
