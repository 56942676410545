import { Component, Input, OnInit } from '@angular/core';
import { RequestManagerService } from '../../../../services/requestManager/request-manager.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { VoucherService } from '../../../../services/voucher/voucher.service';

@Component({
  selector: 'app-customer-dashboard-voucher',
  templateUrl: './customer-dashboard-voucher.component.html',
  styleUrls: ['./customer-dashboard-voucher.component.scss']
})
export class CustomerDashboardVoucherComponent implements OnInit {

    voucherCode: string;
    voucherCodePlaceholder = 'XXXX-XXXX-XXXX-XXXX';
    @Input() customerId =null;

    constructor(private _requestManager: RequestManagerService,
                private route: ActivatedRoute,
                private translate: TranslateService,
                private _notifyService: NotifierService,
                private voucherService: VoucherService) {
    }

  ngOnInit(): void {
        if(this.customerId==null){
            this.customerId = this._requestManager.getIdOfUserThatIsLoggedIn();
        }

      this.voucherCode = '';
  }

    formatVoucherCode(event: any) {

        const charCode = (event.which) ? event.which : event.keyCode;

        if (this.voucherCode.length > 18 && charCode !== 8 ) {
            event.preventDefault();
        }

        if (charCode !== 8 && (
                this.voucherCode.length === 4  ||
                this.voucherCode.length === 9  ||
                this.voucherCode.length === 14)
            && this.voucherCode.length < 16) {

            this.voucherCode = this.voucherCode.concat('-');

        }

    }

    validateVoucherCode() {

        this.formatVoucherPlaceholder();
        const regex = new RegExp('^[a-zA-Z\\d]{4}\\-[a-zA-Z\\d]{4}\\-[a-zA-Z\\d]{4}\\-[a-zA-Z\\d]{4}$', 'gm');
        return regex.test(this.voucherCode);

    }

    validateVoucherCodeError() {
        if(this.voucherCode == ''){
            return false;
        }
        if(this.voucherCode.length <= 18){
            return false;
        }
        const regex = new RegExp('^[a-zA-Z\\d]{4}\\-[a-zA-Z\\d]{4}\\-[a-zA-Z\\d]{4}\\-[a-zA-Z\\d]{4}$', 'gm');
        return !regex.test(this.voucherCode);

    }
    formatVoucherPlaceholder() {
        const voucherCodePlaceholderC = 'XXXX-XXXX-XXXX-XXXX';
        this.voucherCodePlaceholder = this.voucherCode +
            voucherCodePlaceholderC.substring(this.voucherCode.length, voucherCodePlaceholderC.length);

    }

    public redeemVoucher() {
        let id = undefined;
        this.route.params.subscribe(params => {

            if (params['id'] != undefined) {
                id = +params['id'];
            } else {
                id = this._requestManager.getIdOfUserThatIsLoggedIn();
            }

            if (this.customerId) {
                id = this.customerId;
            }


            const body = {
                voucherCode: this.voucherCode,
                userId: id
            };
            // this.loadingSpinner.next(true);
            this._requestManager.put('/voucherRedeem', this._requestManager.getJWT(), body)
                .subscribe(
                    () => {

                        this._notifyService.show({ message: this.translate.instant('voucher.topUp'), type: 'success' });
                        this.voucherService.voucherRedeemed.next(true);
                    },
                    (err) => {

                        if (err.status == 400) {
                            this._notifyService.show({ message: this.translate.instant('voucher.alreadyUsed'), type: 'error' });

                        } else if (err.status == 402) {
                            this._notifyService.show({ message: this.translate.instant('voucher.notEnoughBalance'), type: 'error' });
                        } else {
                            this._notifyService.show({ message: this.translate.instant('voucher.error'), type: 'error' });
                        }


                    }
                );
        });
    }


}
