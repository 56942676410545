import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Inject } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxProgressiveImgLoaderModule } from 'ngx-progressive-img-loader';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import {NotifierModule} from 'angular-notifier';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppComponent} from './app.component';

import {RequestManagerService} from './services/requestManager/request-manager.service';
import {UtilsService} from './services/utils/utils.service';

import {AllroomsComponent} from './components/allrooms/allrooms.component';
import {BookingsComponent} from './components/allrooms/bookings/bookings.component';
import {RoomsComponent} from './components/allrooms/rooms/rooms.component';
import {LoginComponent} from './components/login/login.component';
import {RegistrationComponent} from './components/registration/registration.component';
import {ModalMoreInformationComponent} from './components/modal-more-information/modal-more-information.component';
import {ClientListComponent} from './components/admin/client-list/client-list.component';
import {AdminBookingsComponent} from './components/admin/admin-bookings/admin-bookings.component';
import {NgxUploaderModule} from 'ngx-uploader';
import {CustomerDashboardComponent} from './components/customer/customer-dashboard/customer-dashboard.component';
import {CustomerDashboardDataComponent} from './components/customer/customer-dashboard/customer-dashboard-data/customer-dashboard-data.component';
import {OneOrZeroPipe} from './services/utils/one-or-zero.pipe';
import {EmptyValuePipe} from './services/utils/empty-value.pipe';
import { CustomerDashboardBookingsComponent } from './components/customer/customer-dashboard/customer-dashboard-bookings/customer-dashboard-bookings.component';
import { CustomerDashboardAccountingComponent } from './components/customer/customer-dashboard/customer-dashboard-accounting/customer-dashboard-accounting.component';
import { CompareTimeStringsPipe } from './services/utils/compare-time-strings.pipe';
import { TimeStringInHourPipe } from './services/utils/time-string-in-hour.pipe';
import { CustomerDashboardActionsComponent } from './components/customer/customer-dashboard/customer-dashboard-actions/customer-dashboard-actions.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RoundupPipe } from './services/utils/roundup.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxImgModule } from 'ngx-img';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { MatBadgeModule } from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';


// Import containers
import { DefaultLayoutComponent } from './containers';

const APP_CONTAINERS = [
    DefaultLayoutComponent,
];

import {

    AppAsideModule,
    AppBreadcrumbModule,
} from '@coreui/angular';

// Import routing module
import {AppRoutingModule} from './app.routing';

// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppEffects } from './app.effects';
import { LocationEffects } from './effects/location.effects';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RoomEffects } from './effects/room.effects';
import { RoomtypeEffects } from './effects/roomtype.effects';
import { AuthEffects } from './effects/auth.effects';
import { CustomerEffects } from './effects/customer.effects';
import { ModalLocationSelectComponent } from './components/modal-location-select/modal-location-select.component';
import { OrderRoomTypeSpecialComponent } from './components/order-room-type-special/order-room-type-special.component';
import { AccountBalanceComponent } from './components/account-balance/account-balance.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CheckoutComponent as AdminCheckout} from './components/admin/checkout/checkout.component';


import { AgbComponent } from './components/agb/agb.component';
import { AdminCustomerComponent } from './components/admin/admin-customer/admin-customer.component';
import { InvoicesOnlineComponent } from './components/customer/invoices-online/invoices-online.component';
import { InvoicesCashComponent } from './components/customer/invoices-cash/invoices-cash.component';
import { PageComponent } from './components/page/page.component';
import { ReplaceLineBreakPipe } from './services/utils/replace-line-break.pipe';
import { BatchBookingComponent } from './components/batch-booking/batch-booking.component';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';

import { AdminEffects } from './effects/admin.effects';

import { CurrentLocationComponent } from './components/current-location/current-location.component';
import { FormEntryInputComponent } from './components/form-entry-input/form-entry-input.component';
import { FormEntryDetailComponent } from './components/admin/form-entry-detail/form-entry-detail.component';
import { ModalStornoBookingComponent } from './components/modal-storno-booking/modal-storno-booking.component';
import { CashRegisterModalComponent } from './components/admin/checkout/cash-register-modal/cash-register-modal.component';
import { CashRegisterInfoComponent } from './components/admin/checkout/cash-register-info/cash-register-info.component';
import { SearchBookingsByIdComponent } from './components/admin/search-bookings-by-id/search-bookings-by-id.component';
import {BookingsEffects} from './effects/bookings.effects';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOptionModule, NativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RoomCardComponent } from './components/allrooms/rooms/room-card/room-card.component';
import {MatRadioModule} from '@angular/material/radio';
import { CustomerDashboardVoucherComponent } from './components/customer/customer-dashboard/customer-dashboard-voucher/customer-dashboard-voucher.component';
import { EditUserBaseDataModalComponent } from './components/customer/customer-dashboard/customer-dashboard-data/edit-user-base-data-modal/edit-user-base-data-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EditUserAddressDataModalComponent } from './components/customer/customer-dashboard/customer-dashboard-data/edit-user-address-data-modal/edit-user-address-data-modal.component';
import { ChangeUserRightsConfirmModalComponent } from './components/customer/customer-dashboard/customer-dashboard-data/change-user-rights-confirm-modal/change-user-rights-confirm-modal.component';
import { ChangePasswordModalComponent } from './components/customer/customer-dashboard/customer-dashboard-data/change-password-modal/change-password-modal.component';
import { InvoicesComponent } from './components/customer/invoices/invoices.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { DataProtectionComponent } from './components/data-protection/data-protection.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WINDOW } from 'ngx-window-token';
import { FooterComponent } from './components/footer/footer.component';
import { RouterComponent } from './components/router/router/router.component';
import { DynamicMenuPageComponent } from './components/dynamic-menu-page/dynamic-menu-page.component';
import { MatSliderModule } from '@angular/material/slider';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FilterRoomsComponent } from './components/allrooms/filter-rooms/filter-rooms.component';
import { EditSettingsModalComponent } from './components/customer/customer-dashboard/customer-dashboard-data/edit-settings-modal/edit-settings-modal.component';
import { TanValidationComponent } from './components/tan-validation/tan-validation.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AdminUserSearchModalModule } from './components/admin/user-search-modal/admin.user-search-modal.module';
import { SharedModule } from './SharedModule/shared.module';
import { ModalSmartphoneRequiredComponent } from './components/modal-smartphone-required/modal-smartphone-required.component';
import { ModalAirkeyAppNotRegisteredComponent } from './components/modal-airkey-app-not-registered/modal-airkey-app-not-registered.component';
import { BookingsExportComponent } from './components/admin/bookings-export/bookings-export.component';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    fgsColor: 'white',
    fgsType: 'rectangle-bounce-pulse-out',
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json?ver='+environment.translateVersion || '.json');
}


const MY_DATE_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'DD.MM.YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};



@NgModule({
    imports: [
        DragDropModule,

        BrowserModule,
        AppRoutingModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxPaginationModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NotifierModule.withConfig({
                position: {
                    horizontal: {
                        position: 'right',
                        distance: 20,
                    },
                    vertical: {
                        position: 'top',
                        distance: 120,
                    },
                },
                behaviour: {
                    autoHide: 4000,
                },
            },
        ),
        NgxUploaderModule,
        Ng2SearchPipeModule,
        ColorPickerModule,
        NgxImgModule.forRoot(),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        StoreModule.forRoot({}, {}),
        EffectsModule.forRoot([AppEffects, LocationEffects, RoomEffects, RoomtypeEffects, AuthEffects, CustomerEffects, AdminEffects, BookingsEffects]),
        StoreModule.forRoot(reducers, { metaReducers }),
        !environment.production ? StoreDevtoolsModule.instrument({ name: 'NGRX' }) : [],
        FontAwesomeModule,
        environment.production ? [] : AkitaNgDevtools.forRoot({ name: 'aKITA' }),
        CommonModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatExpansionModule,
        MatBadgeModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        NativeDateModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDialogModule,
        MatTabsModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        FontAwesomeModule,
        NgxProgressiveImgLoaderModule,
        MatSliderModule,
        NgxSliderModule,
        NgxIntlTelInputModule,
        AdminUserSearchModalModule,
        SharedModule,
    ],
    exports: [
        MatIconModule,
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        AdminCheckout,
        AppComponent,
        AllroomsComponent,
        BookingsComponent,
        RoomsComponent,
        LoginComponent,
        RegistrationComponent,
        ModalMoreInformationComponent,
        ClientListComponent,
        CustomerDashboardComponent,
        CustomerDashboardDataComponent,
        AdminBookingsComponent,
        OneOrZeroPipe,
        EmptyValuePipe,
        CustomerDashboardBookingsComponent,
        CustomerDashboardAccountingComponent,
        CustomerDashboardActionsComponent,
        CompareTimeStringsPipe,
        TimeStringInHourPipe,
        RoundupPipe,
        LoadingScreenComponent,
        ModalLocationSelectComponent,
        OrderRoomTypeSpecialComponent,
        AccountBalanceComponent,
        CheckoutComponent,
        AgbComponent,
        AdminCustomerComponent,
        InvoicesOnlineComponent,
        InvoicesCashComponent,
        PageComponent,
        ReplaceLineBreakPipe,
        BatchBookingComponent,
        CurrentLocationComponent,
        FormEntryInputComponent,
        FormEntryDetailComponent,
        ModalStornoBookingComponent,
        CashRegisterModalComponent,
        CashRegisterInfoComponent,
        SearchBookingsByIdComponent,
        RoomCardComponent,
        CustomerDashboardVoucherComponent,
        EditUserBaseDataModalComponent,
        EditUserAddressDataModalComponent,
        ChangeUserRightsConfirmModalComponent,
        ChangePasswordModalComponent,
        InvoicesComponent,
        DataProtectionComponent,
        FooterComponent,
        RouterComponent,
        DynamicMenuPageComponent,
        FilterRoomsComponent,
        EditSettingsModalComponent,
        TanValidationComponent,
        ModalSmartphoneRequiredComponent,
        ModalAirkeyAppNotRegisteredComponent,
        BookingsExportComponent,
    ],
    providers: [{
        provide: LocationStrategy,
        useClass: HashLocationStrategy,
    },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        RequestManagerService,
        UtilsService,
        CookieService,
        RoomsComponent,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        /* {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
            }),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
          },*/

    ],
    bootstrap: [AppComponent],

    entryComponents: [
        LoginComponent,
        RegistrationComponent,
        ModalMoreInformationComponent,
        ModalStornoBookingComponent,
    ]
})



export class AppModule {
    constructor(@Inject(WINDOW) _window) {


    }
}
