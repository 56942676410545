import { createAction, props } from '@ngrx/store';

export const loadCustomers = createAction(
  '[Customer] Load Customers',
  props<{searchTerm: string, offset:number, limit:number}>()
);

export const loadCustomersSuccess = createAction(
  '[Customer] Load Customers Success',
  props<{ customers: any }>()
);

export const loadCustomersFailure = createAction(
  '[Customer] Load Customers Failure',
  props<{ error: any }>()
);
export const countCustomers = createAction(
  '[Customer] Count Customers'
);

export const countCustomersSuccess = createAction(
  '[Customer] Count Customers Success',
  props<{ count: any }>()
);
