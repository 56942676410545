import {
    Component,
    Inject,
    ElementRef,
    OnInit,
    EventEmitter,
    Output,
    HostListener,
    ViewChild, AfterViewInit,
} from '@angular/core';
import { DOCUMENT } from "@angular/common";
import {
    NavData,
} from '../../_nav';

import { CartManagerService } from "../../services/cartManager/cart-manager.service";
import { CookieService } from "ngx-cookie-service";
import { RequestManagerService } from "../../services/requestManager/request-manager.service";

import { TranslateService } from "@ngx-translate/core";
import { NotifierService } from "angular-notifier";
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from "../../services/utils/utils.service";
import {  select, Store } from "@ngrx/store";
import { State } from "../../reducers";
import {
  loadLocations,
  openLocationSelectModal,
  selectCurrentLocation,
  ILocation,
} from "../../actions/location.actions";
import { map} from "rxjs/operators";
import { ModalLocationSelectComponent } from "../../components/modal-location-select/modal-location-select.component";
import { Actions, ofType } from "@ngrx/effects";
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { LoginService } from '../../components/login/login.service';
import { LocationService } from '../../services/location/location.service';
import { RouterService } from '../../services/router/router.service';
import { ISettingsValue } from '../../components/admin/settings/settingsValue';
import { DynamicMenuPageComponent } from '../../components/dynamic-menu-page/dynamic-menu-page.component';
import { changeLanguage } from '../../actions/ui.actions';

@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html',
    styleUrls: ['default-layout.scss'],
})
export class DefaultLayoutComponent implements OnInit, AfterViewInit {
    public navMenu: BehaviorSubject<NavData[]>;

    public element: HTMLElement;

    mobileSiteTitle: string;

    languageDisplay = 'DE';


    @Output() booked: EventEmitter<string>;
    public animate: boolean = false;

    isLoggingIn$;
    isLoggedIn$;
    username: string;
    user$;
    public isAdmin$;
    public isOffice$;

    public innerWidth: any;
    public innerHeight: any;
    public mobile: boolean;

    language$;

    @ViewChild('sidenavMobile') sidenav: MatSidenav;

    public color: string;
    location$;
    locations$;
    discount: number;
    sideNavShow$;
    sideNavShowMobile = false;

    dynamicMenuPointTitle: ISettingsValue = {
        settingsKey: 'page_dynamicMenuPointTitle',
        settingsValue: false,
        settingsId: 1,
        id: 7,
    };
    dynamicMenuPointIcon: ISettingsValue =
        {
            settingsKey: 'page_dynamicMenuPointIcon',
            settingsValue: 'info',
            settingsId: 1,
            id: 9,
        };
    dynamicMenuPointActiveInNavigation: ISettingsValue =
        {
            settingsKey: 'page_dynamicMenuPointActiveInNavigation',
            settingsValue: false,
            settingsId: 1,
            id: 10,
        };

    pathToNavbarLogo: ISettingsValue =
        {
            settingsKey: 'pathToNavbarLogo',
            settingsValue: '',
            settingsId: 1,
            id: 35,
        };

    constructor(
        public translate: TranslateService,
        private requestManager: RequestManagerService,
        private cookieService: CookieService,
        public router: Router,
        public cartManager: CartManagerService,
        private elem: ElementRef,
        private notifier: NotifierService,
        private store: Store,
        private actions$: Actions,
        private modalService: MatDialog,
        private activeRoute: ActivatedRoute,
        private loginService: LoginService,
        public locationService: LocationService,
        public routerService: RouterService,
        @Inject(DOCUMENT) _document?: any,
    ) {

        this.isLoggingIn$ = this.loginService.isLoggingIn;
        this.isLoggedIn$ = this.loginService.isLoggedIn;
        this.navMenu = this.loginService.navMenu;
        this.user$ = this.loginService.user;
        this.isAdmin$ = this.loginService.isAdmin;
        this.isOffice$ = this.loginService.isOffice;
        this.sideNavShow$ = this.loginService.sideNavShow;

        this.requestManager.get('/settings/settingsValue', this.requestManager.getJWT(), true)
            .then(
                (res) => {
                    let companySettings = UtilsService.getSettingsOfCategoryByName('companySettings', res);
                    this.dynamicMenuPointTitle = UtilsService.getSettingByKeynameFromCategory('page_dynamicMenuPointTitle', companySettings);
                    this.dynamicMenuPointIcon = UtilsService.getSettingByKeynameFromCategory('page_dynamicMenuPointIcon', companySettings);
                    this.dynamicMenuPointActiveInNavigation = UtilsService.getSettingByKeynameFromCategory('page_dynamicMenuPointActiveInNavigation', companySettings);
                    this.dynamicMenuPointActiveInNavigation.settingsValue = this.dynamicMenuPointActiveInNavigation.settingsValue == 'true';
                    this.pathToNavbarLogo = UtilsService.getSettingByKeynameFromCategory('pathToNavbarLogo', companySettings);

                },
            );

        this.language$ = this.store
            .select((state: State) => state.ui.language)
            .pipe(
                map((langcode) => {

                    switch (langcode) {
                        case 'DE':
                            this.languageDisplay = 'DE';
                            return { lang: 'de', flag: 'at' };
                        case 'EN':
                            this.languageDisplay = 'EN';
                            return { flag: 'gb', lang: 'en' };
                        default:
                            this.languageDisplay = 'Deutsch';
                            return { lang: 'de', flag: 'at' };
                    }
                }),
            );
    }

  getVersion() {
    return environment.translateVersion;
  }


@HostListener('window:popstate', ['$event'])
    onPopState(event) {


    if(this.router.url === '/login'){
        this.loginService.isLoggingIn.next(false);
    }
    else if(this.router.url === '/register'){
        this.loginService.isLoggingIn.next(true);
    }

    this.ngOnInit();
}

    @HostListener('window:pushstate', ['$event'])
    onPushState(event) {
        console.log(event);
        if(this.cookieService.get("jwt") == undefined)
        {
            this.router.navigateByUrl('login');
        }

        this.ngOnInit();
    }

  ngOnInit() {

      this.loginService.isUserAdmin();
      if(this.router.url== '/'){
          this.mobileSiteTitle= 'Buchen';
      }


      this.locations$ = this.store.pipe(select((state: State) => state.location.all),
          map((locations) => {
              if (locations !== undefined) {
                  return Object.values(locations).filter((location: any) => location.disabled != '1');
              }
              return [];
          })
      );

    let selectedLocation = localStorage.getItem("pp-location-select");
    if (selectedLocation) {
      this.store.dispatch(
        selectCurrentLocation(<ILocation>JSON.parse(selectedLocation))
      );
    }

    this.location$ = this.store.pipe(
      select((state: State) => state.location.current)
    );


      let openModal$ = this.actions$.pipe(
          ofType(openLocationSelectModal),
          tap(() => {
              this.openLocationModal();
          }),
      );
      openModal$.subscribe();


      this.cartManager.cartLengthChanged.subscribe((size) => {
          this.animateCart(size);
      });
      this.getColor();
      this.store.dispatch(loadLocations());

      this.getUserData();
    this.resolveState();
  }

  ngAfterViewInit() {
      if(this.sidenav != undefined){
          this.sidenav.openedChange.subscribe((status) => {
              if(status === false){
                  this.sideNavShowMobile= false;
              }
          })
      }
  }

    openLocationModal() {
        const modalRef = this.modalService.open(ModalLocationSelectComponent, { disableClose: true, hasBackdrop: false });
        modalRef.componentInstance.modalRef= modalRef;
    }


    @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.resolveState();
  }



  private getUserData() {
    if (this.cookieService.get("email")) {
      this.username = this.cookieService.get("email");
    }
  }


    private resolveState() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    if (this.innerHeight > this.innerWidth) {
      this.mobile = true;
    } else {

        if ((innerWidth / 16) <= 62) {
            this.mobile = true;
        } else {
            this.mobile = false;
        }
    }
    }


    changeLanguage(language) {
        language = language.toUpperCase();
        // set current lang;
        this.translate.use(language.toLowerCase());
        if (language === 'en') {
            language = 'gb';
        }
        this.store.dispatch(changeLanguage({ language: language }));
    }

    changeLanguageDisplay(language: string) {
        this.languageDisplay = language;
    }


    public openLogin() {
        this.loginService.isLoggingIn.next(
            true,
        );


        this.sideNavShow$.next(false);
        this.router.navigateByUrl('login');
    }

    openLoginMobile() {
      this.sideNavShowMobile=false;
        this.loginService.isLoggingIn.next(
            true
        );
        this.updateMobileTitle('Login');
        this.sideNavShow$.next(false);
        this.router.navigateByUrl('login');
    }

  public logout() {
    this.cookieService.deleteAll("/");
    this.loginService.user.next(null);
    this.loginService.isLoggedIn.next(false);
      this.loginService.isAdmin.next(false);
      this.loginService.isOffice.next(false);
    this.loginService.updateMenu();
    this.router.navigateByUrl("/").then(() => {
    });
  }


  goToCheckout(): void {
    this.router.navigateByUrl("checkout");
  }



  animateCart(size: number) {
    this.animate = true;
    // console.log(this.animate);
    setTimeout(() => {
      this.animate = false;
    }, 1500);
  }

  getColor() {
    this.requestManager
      .get("/settings/settingsValue", this.requestManager.getJWT(), true)
      .then((res) => {
        const companySettings = UtilsService.getSettingsOfCategoryByName(
          "companySettings",
          res
        );
        const setting = UtilsService.getSettingByKeynameFromCategory(
          "overlayColor",
          companySettings
        );
        this.color = setting.settingsValue;
        // console.log("COLORRRRRRRRRRR: ", this.color);
      });
  }


  toggleSideNav(){

      this.sideNavShow$.next(!this.sideNavShow$.value)
  }

    toggleSideNavMobile(){
      this.sideNavShowMobile = !this.sideNavShowMobile;
    }

    selectLocation(location: any) {

        this.locationService.locationChange$.next(location.name);
        this.store.dispatch(selectCurrentLocation(location));
        if (this.mobile) {
            this.sideNavShowMobile = false;
        }

    }

    backToHomeScreen() {
        this.loginService.isLoggingIn.next(false);
        this.sideNavShow$.next(true);
        if (!this.isAdmin$.value && !this.isOffice$.value) {
            this.router.navigateByUrl('/');
        }
    }

    toggleExpanded(item: NavData) {
      this.navMenu.subscribe((navMenuItems) => {
          const menuItem = navMenuItems.find((menuItem) => item=== menuItem);
          menuItem.expanded = !menuItem.expanded;
      })
    }

    checkIfLocationIsActive(location: any) : Observable<boolean>{
        return this.location$.pipe(map((selectedLocation: any) => {
            if(selectedLocation == null || selectedLocation == undefined){
                return false;
            }
            if(selectedLocation.id== location.id){
                return true;
            }
            return false;
        }))
    }


    updateMobileTitle(title: string) {
        this.mobileSiteTitle = title;
        this.sideNavShowMobile = false;
    }

    openLinkToDynamiceMenuPage() {
        this.modalService.open(DynamicMenuPageComponent);
    }

    getAddressOfLocation(address: string) {
        let addressArray: string[] = address.split(',');
        if (addressArray.length > 1) {
            return addressArray[0];
        }
        return address;
    }
}
