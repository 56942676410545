import { Component, Inject, OnInit } from '@angular/core';
import { RequestManagerService } from '../../../../../services/requestManager/request-manager.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {


    userIsAdmin;
    userIsOffice;
    customerId;

    public passwordUpdateObject = {
        'oldPassword': '',
        'newPassword': '',
        'newPassword2': ''
    };


    constructor(private _requestManager: RequestManagerService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ChangePasswordModalComponent>,
                private _notifyService: NotifierService,
                private translate: TranslateService) {

        this.customerId= this.data.customerId;

  }


    ngOnInit(): void {
        this.userIsAdmin = this._requestManager.getUserIsAdmin();
        this.userIsOffice = this._requestManager.getUserIsOffice();
    }

    checkPasswordsValid() {

      if(!this.checkPasswordValid(this.passwordUpdateObject.newPassword)){
          return false;
      };

        if(!this.checkPasswordValid(this.passwordUpdateObject.newPassword2)){
            return false;
        };

        if(this.passwordUpdateObject.newPassword !== this.passwordUpdateObject.newPassword2){
            return false;
        };

        return true;
    }

    clickClose() {
        this.dialogRef.close('cancel');
    }

    clickSave() {

        const userBody = {password: ''};

        if(!this.checkPasswordValid(this.passwordUpdateObject.newPassword)){
            this._notifyService.notify('error', this.translate.instant('PASSWORD_TOO_SHORT'));
            return;
        };

        if(!this.checkPasswordValid(this.passwordUpdateObject.newPassword2)){
            this._notifyService.notify('error', this.translate.instant('PASSWORD_TOO_SHORT'));
            return;
        };

        if(this.passwordUpdateObject.newPassword !== this.passwordUpdateObject.newPassword2){
            this._notifyService.notify('error', this.translate.instant('PASSWORD_DO_NOT_MATCH'));
            return;
        };

        userBody.password = this.passwordUpdateObject.newPassword;

        this._requestManager.put('/user/' + this.customerId, this._requestManager.getJWT(), userBody).subscribe(success => {

            this.dialogRef.close('save');
            this._notifyService.notify('success', this.translate.instant('PASSWORD_CHANGED'));
            this.passwordUpdateObject = {
                'oldPassword': '',
                'newPassword': '',
                'newPassword2': ''
            };
        });

    }

    checkPasswordValid(newPassword: string) {
        if(newPassword.length < 3){
            return false;
        }
        return true;
    }
}
