import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbToast } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NotifierService } from "angular-notifier";
import { tap } from "rxjs/operators";
import { DynamicFormsService } from "../../services/dynamic-forms.service";

export interface IFormEntryListItem {
  label: string;
  control: FormControl;
  field: any;
}
@Component({
  selector: "app-form-entry-input",
  templateUrl: "./form-entry-input.component.html",
  styleUrls: ["./form-entry-input.component.scss"],
})
export class FormEntryInputComponent implements OnInit {
  form;
  formLines = [];
  formId;
  @Input()
  id;

  @Input()
  saveButtonName = "Speichern";

  @Output()
  saveEvent = new EventEmitter<any>();
  constructor(
    private dynService: DynamicFormsService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toast: NotifierService,
    private translate: TranslateService  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.initById(params.id);
      }
    });
    if (this.id) {
      this.initById(this.id);
    }
  }

  initById(id) {
    this.form = this.dynService.getFormById(id).pipe(
      tap((form) => {
        this.buildFormControls(form);
        this.formId = id;
      })
    );
  }

  buildFormControls(form) {
    for (let field of form.formFields) {
      let control = new FormControl("");
      if (field.required == "1") {
        control.setValidators(Validators.required);
      }
      field.required = field.required == '1'? true: false;

      this.formLines.push({ label: field.displayName, control, field });
    }
  }

  private getFormValues() {}

  saveButtonClicked() {
    let value = { formId: Number.parseInt(this.formId), selections: [] };
    let valid = true;
    this.formLines.forEach((line) => {
      if (line.control.valid) {
          value.selections.push({
              id: line.field.id,
              name: line.field.name,
              displayName: line.field.displayName,
              selectedValue: line.control.value,
          });
      } else {
        valid = false;
      }
    });
    if (valid) {
      this.saveEvent.next(value);
    }
    else {
      this.toast.show({type:'error',message:this.translate.instant('dynamicForms.notFilled')})
    }

    // this.dynService.saveFormEntry(value).subscribe();
  }
}
