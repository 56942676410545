import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { map, mergeMap, tap } from 'rxjs/operators';
import { State } from '../../../../reducers';
import { AdminCheckoutService } from '../checkout.service';

@Component({
  selector: 'app-cash-register-modal',
  templateUrl: './cash-register-modal.component.html',
  styleUrls: ['./cash-register-modal.component.scss']
})
export class CashRegisterModalComponent implements OnInit {
  locations$;
  payerUser$;
  cashRegisterUserId;
  formGroup = this.fb.group({
    locationId: new FormControl("", Validators.required),
  });
  constructor(private store: Store, private fb:FormBuilder, private checkoutService: AdminCheckoutService, private modalService:NgbActiveModal) { 
    this.locations$ = this.store
    .select((state: State) => state.location.all)
    .pipe(
      map((locations) => {
        
      setTimeout( () => {
        let cashLocation = localStorage.getItem('selectedCashregisterLocation');
        if(cashLocation) {
          this.formGroup.patchValue({locationId:cashLocation}, {emitEvent:false});
        }
      },50);
        return Object.values(locations);
      })
    );
    this.formGroup.controls.locationId.valueChanges.subscribe( locationId => {
      console.log(locationId);
      localStorage.setItem('selectedCashregisterLocation',locationId);
      this.modalService.close();
    })
    this.payerUser$ = this.formGroup.get("locationId").valueChanges.pipe(
      mergeMap((locationId) => {
        return this.checkoutService.getPayerUser(locationId);
      }),
      map((response: any) => {
        if (response.length == 0) {
          
          return {};
        } else {
       
          return Object.values(response.receiptEmployees)[0];
        }
      },
      ), 
      tap((userResponse :any ) => {
        this.cashRegisterUserId = userResponse?.cashRegisterUserId;
      })
    );
  }

  ngOnInit(): void {
  }

  

}
