import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, timer } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { State } from '../../../../reducers';
import { CashRegisterModalComponent } from '../cash-register-modal/cash-register-modal.component';

@Component({
  selector: 'app-cash-register-info',
  templateUrl: './cash-register-info.component.html',
  styleUrls: ['./cash-register-info.component.scss']
})
export class CashRegisterInfoComponent implements OnInit {
  selectedLocation$
  auth$
  refresh$=new BehaviorSubject(true);
  constructor(private store:Store, private dialogService:NgbModal) { 
    this.auth$ = this.store.select((state:State)=>state.auth);
    
    this.selectedLocation$ = combineLatest([timer(0,10000),this.refresh$]).pipe(mergeMap(() => store.select((state:State)=> state.location.all)),map( (locations) => {
      return locations[localStorage.getItem('selectedCashregisterLocation')];
    }))
  }

  ngOnInit(): void {
    this.auth$.pipe(take(1)).subscribe(auth => {
      if(this.isAllowed(auth)) {
        let locationId = localStorage.getItem('selectedCashregisterLocation');
        if(locationId=="" || locationId == null) {
          this.clickOpenModal();
        }
      }
    });
  }
  clickOpenModal()  {
    this.dialogService.open(CashRegisterModalComponent).result.then( () =>  {
      console.log('closed');
      this.refresh$.next(true);
    });

  }

  isAllowed(auth:any) {
    
    return (auth.isAdmin=="1" || auth.isOffice=="1")
  }

}
