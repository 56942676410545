import { NgModule } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { UserSearchModalComponent } from './user-search-modal.component';
import { environment } from '../../../../environments/environment';


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '../../../../assets/i18n/', '.json?ver=' + environment.translateVersion || '.json');
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        FontAwesomeModule,
    ],
    declarations: [
        UserSearchModalComponent,
    ],
    exports: [UserSearchModalComponent],
    providers: [],

})

export class AdminUserSearchModalModule {

}