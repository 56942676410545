import { createAction, props } from '@ngrx/store';

export const searchBookings = createAction(
  '[Booking] Search Bookings',
  props<{name: string}>()
);

export const searchBookingsDone = createAction(
  '[Booking] Search Bookings Done',
  props<{bookings: []}>()
);

