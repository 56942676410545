import { createAction, props } from '@ngrx/store';


export const loadAllRooms = createAction(
  '[Room] Load All Rooms'
);

export const loadAllRoomsDone = createAction(
  '[Room] Load All Rooms Done',
  props<{rooms:[]}>()
);


export const loadCancellationPeriodSuccess = createAction(
  '[Room] cancellationPeriod Success',
  props<{cancellationPeriod:any}>()
);
export const loadCancellationPeriods = createAction(
  '[Room] cancellationPeriod'
)


export const loadRooms = createAction(
  '[Room] Load Rooms'
);
export const loadRoomsDone = createAction(
  '[Room] Load Rooms Done',
  props<{rooms:[]}>()
);

export const roomsUpdateDone = createAction(
  '[Room] Update Done'
)

export const searchRooms = createAction(
  '[Room] Search Rooms',
  props<{name:string}>()
)
export const searchRoomsDone = createAction(
  '[Room] Search Rooms Done',
  props<{rooms:[]}>()
)


