import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';
import { UtilsService } from '../../services/utils/utils.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-dynamic-menu-page',
    templateUrl: './dynamic-menu-page.component.html',
    styleUrls: ['./dynamic-menu-page.component.scss'],
})
export class DynamicMenuPageComponent implements OnInit {

    dynamicMenuPointContent = {
        settingsKey: 'page_dynamicMenuPointContent',
        settingsValue: '',
        settingsId: 1,
        id: 8,
    };
    dynamicMenuPointTitle = {
        settingsKey: 'page_dynamicMenuPointTitle',
        settingsValue: '',
        settingsId: 1,
        id: 7,
    };
    loading$ = new BehaviorSubject<boolean>(false);

    constructor(private dialogRef: MatDialogRef<DynamicMenuPageComponent>,
                private requestManager: RequestManagerService) {
        this.loading$.next(true);
        this.requestManager.get('/settings/settingsValue', this.requestManager.getJWT(), true).then(
            (res) => {
                let companySettings = UtilsService.getSettingsOfCategoryByName('companySettings', res);
                this.dynamicMenuPointContent = UtilsService.getSettingByKeynameFromCategory('page_dynamicMenuPointContent', companySettings);
                this.dynamicMenuPointTitle = UtilsService.getSettingByKeynameFromCategory('page_dynamicMenuPointTitle', companySettings);

                this.loading$.next(false);
            },
        );
    }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close();
    }

}
