import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import {
  NgbCalendar,
  NgbDate,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { RequestManagerService } from "../../../services/requestManager/request-manager.service";
import * as _ from "lodash";
import { UtilsService } from "../../../services/utils/utils.service";
import { TranslateService } from "@ngx-translate/core";
import {
    BehaviorSubject,
    combineLatest,
    from,
    Subject,
    Subscription,
    timer,
} from 'rxjs';
import {
  delay,
  map,
  bufferTime,
  filter,
  take,
} from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import { State } from "../../../reducers";
import { loadRooms } from "../../../actions/room.actions";
import { cloneDeep, isArray } from "lodash";
import { ApiService } from "../../../services/api/api.service";
import { UserSearchModalComponent } from "../user-search-modal/user-search-modal.component";
import { NotifierService } from "angular-notifier";
import { BookingService } from "../../../services/booking/booking.service";
import { Router } from "@angular/router";
import { faChevronLeft,faChevronRight, faCheckCircle,faTimesCircle} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: "app-admin-bookings",
  templateUrl: "./admin-bookings.component.html",
  styleUrls: ["./admin-bookings.component.css"],
})
export class AdminBookingsComponent implements OnInit, OnChanges {
  loadingNewDate$ = new Subject();
  bookingsLoaded$ = new Subject();
  loading = true;
  public minDate: NgbDate;
  public selectedDate: NgbDate;
  public hourSelectList: any[];
  public hourSelectListBooking: any[];
  public allHourSelectListBooking: any;
  bookedCell: any = {};
  @Input() roomList;

  bookForCustomrLoading$ = new BehaviorSubject(false);


  public bookingsList;
  public processedRoomList;
  public bookingsByRoomId = {};
  public tableSettings: { opening: string; closing: string; spanTotal: number };
  public singleBooking: any;
  public oldDate:any;
  bookings$;
  customer;
  popoverRef:any;

  faChevronLeft= faChevronLeft;
  faChevronRight=faChevronRight;
    faCheckCircle= faCheckCircle;
    faTimesCircle= faTimesCircle;
  lastHash: string = "";
  private popoverVisible = false;

  customerCart: any[] = [];
  constructor(
      private calendarService: NgbCalendar,
      private requestManager: RequestManagerService,
      public translate: TranslateService,
      private store: Store,
      private ref: ChangeDetectorRef,
      private api: ApiService,
      private modal: NgbModal,
      private notifier: NotifierService,
      private bookingService: BookingService,
      private router: Router,
  ) {}

  subscriptions = [];

  ngOnInit() {
    let first = 0;
    let timerValue = timer(5000, 5000)
    .pipe(
      filter(() => {
        return first++ ==0;
        return !this.popoverVisible;
      })
    );
    this.subscriptions.push(
      combineLatest([timerValue, this.loadingNewDate$])
        .pipe(
          bufferTime(100),
          filter((buffer) => buffer.length > 0),
          delay(1)
        )
        .subscribe((values) => {
          this.processRoomList(false, true);
        })
    );
    this.getRooms();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
  /*
   * Get all rooms and reorder in list with right language
   * */
  // this.requestManager.get('/room/roomDescription/roomInstruments/roomType/roomTypeOpeningHour').then(
  getRooms(reload = true) {
    this.loading = true;
    this.store.dispatch(loadRooms());
    let select$ = this.store.pipe(
      select((state: State) => state.data.rooms),
      map((rooms: any[]) => {
        if (rooms.length == 0) {
          return;
        }
        this.roomList = cloneDeep(rooms);
        this.roomList = _.sortBy(this.roomList, (room) =>
          Number.parseInt(room.roomOrder)
        );

        this.roomList = _.keyBy(this.roomList, (room) => room.id);
        _.values(this.roomList).forEach((room) => {
          UtilsService.getDescriptionFromLanguage(
            room,
            this.translate.currentLang
          );
          room["instruments"] = _.values(room["roomInstruments"]);
        });
        this.initVariables(reload);

        this.processedRoomList = _.cloneDeep(this.roomList); // clone object without reference, deep means the object has child-objects
        this.loadingNewDate$.next(true);
        //this.processRoomList(true);

        this.bookingsLoaded$.subscribe(() => {
          // console.log("bookingsLoaded");
          this.generatePopoverSelectList(this.hourSelectList);
          this.loading = false;
        });
      })
    );
    this.subscriptions.push(select$.subscribe());
  }
  initVariables(reload = true): void {
      if (reload) {
          this.selectedDate = this.calendarService.getNext(this.getToday(), 'd',
              UtilsService.getLocalDate().getDate() - this.getToday().day);
      }


      this.minDate = this.getMinDate();
      this.tableSettings = {
          opening: undefined,
          closing: undefined,
          spanTotal: undefined,
      };
  }

  btnFormEntryClicked(formEntryId) {
    this.router.navigateByUrl("/admin/form-entry/" + formEntryId);
  }

  book() {
      this.bookForCustomrLoading$.next(true);
    from(
      this.requestManager.post(
          '/bookings/create?bookingForUser=' + this.customer.id,
          this.customerCart,
          this.requestManager.getJWT(),
      )
    ).subscribe(() => {
      this.customerCart = [];
      this.getBookings(true);
    },(error) =>{
        this.bookForCustomrLoading$.next(false);
    });
  }

  removeFromCart(cartObject) {
    delete this.bookedCell[
      cartObject.roomId + "_" + cartObject.bookingTimeFrom
    ];
    let index = this.customerCart.indexOf(cartObject);
    if (index >= 0) {
      this.customerCart.splice(index, 1);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // process roomList when got data about it
    if (changes.roomList.currentValue != undefined) {
      this.initVariables();
      this.processedRoomList = _.cloneDeep(this.roomList); // clone object without reference, deep means the object has child-objects
      //this.loadingNewDate$.next(true);

      //this.processRoomList(true);
      this.hourSelectList = UtilsService.createTimeStringArray(
        this.tableSettings.opening,
        this.tableSettings.closing,
        60
      );
    }
  }

  generatePopoverSelectList(hourSelectList: any[]) {
    this.allHourSelectListBooking = {};
    hourSelectList.forEach((hour, index) => {
      let popoverList = this.createHourBookingsTable(hour);
      let flag = "";
      if (popoverList.length > 0) {
        let paid = true;
        popoverList.forEach((row) => {
          paid = row.isPaid === '1' && paid;
        });
          flag = paid ? 'paid' : 'booked';
      }


        this.allHourSelectListBooking[index] = {
            popover: popoverList,
            flag: flag,
        };

    });

  }

  selectPopover(cell, index) {
    this.hourSelectListBooking = this.allHourSelectListBooking[index].popover;
  }

  popoverShown(popoverRef?:any) {
    if(popoverRef) {
      this.popoverRef = popoverRef;
    }
    this.popoverVisible = true;
  }

  popoverHidden() {
    this.popoverVisible = false;
  }


  public getToday(): NgbDate {
    return this.calendarService.getToday();
  }

  public getIterableRooms(): any[] {
    return _.values(this.processedRoomList);
  }

  public colorCell(cell, room): string {
    const classes = "table-padding table-time ";

    if (!cell.available) {
      return classes + "closed";
    } else if (cell.paid === "1") {
      return classes + "paid";
    } else if (cell.booked) {
      return classes + "booked";
    } else if (this.isCellOnCart(room, cell)) {
      return classes + " selected";
    } else {
      return classes + "free";
    }
  }

  private isCellOnCart(room: any, cell: any): boolean {
    let isOnCart = false;

    // create ISO-Time-Strings
    const bookingTimeToTimeString = UtilsService.addEntityLength2TimeString(
      cell.time,
      room.entityLength
    );
    const cellBooking = {
      roomId: room.id,
      bookingTimeFrom: UtilsService.getISOStringOfTimeString(
        this.selectedDate.year,
        this.selectedDate.month,
        this.selectedDate.day,
        cell.time
      ), // translate Date to ISO-Time-String
      bookingTimeTo: UtilsService.getISOStringOfTimeString(
        this.selectedDate.year,
        this.selectedDate.month,
        this.selectedDate.day,
        bookingTimeToTimeString
      ), // translate Date to ISO-Time-String
    };

    // check if created cellBooking is on cart
    this.customerCart.forEach((booking) => {
      if (
        booking.roomId == cellBooking.roomId &&
        booking.bookingTimeFrom == cellBooking.bookingTimeFrom
      ) {
        isOnCart = true;
        return;
      }
    });
    return isOnCart;
  }

  public colorColumn(index): string {
    if (this.allHourSelectListBooking && this.allHourSelectListBooking[index]) {
      let hour = this.allHourSelectListBooking[index];

      return hour.flag;
    }
    return "";
  }

  public subDay(): void {
    this.loading = true;
    this.ref.markForCheck();
    this.selectedDate = this.calendarService.getPrev(this.selectedDate, "d", 1);
    this.loadingNewDate$.next(true);
    //this.processRoomList(true);
  }

  public addDay(): void {
    this.loading = true;
    this.ref.markForCheck();
    this.selectedDate = this.calendarService.getNext(this.selectedDate, "d", 1);
    this.loadingNewDate$.next(true);
  }

  public processRoomList(forceRefresh, refreshBookings = false): void {
    if (forceRefresh || this.processRoomList == undefined||this.tableSettings.opening==undefined||this.selectedDate != this.oldDate) {
      this.oldDate = this.selectedDate;
      this.processedRoomList = _.cloneDeep(this.roomList);

      const selDate = this.getSelectedDate();
      this.api
        .getOpeningHours(selDate.year, selDate.month, selDate.day)
        .pipe(take(1))
        .subscribe((openingHours: any) => {
          this.tableSettings["opening"] = "24:00";
          this.tableSettings["closing"] = "00:00";
          _.values(this.processedRoomList).forEach((room) => {
            if (
                !(
                    openingHours.roomType[room.roomTypeId] &&
                    openingHours.roomType[room.roomTypeId].roomTypeOpeningHour &&
                    Object.values(
                        openingHours.roomType[room.roomTypeId].roomTypeOpeningHour,
                    )[0]
                )
            ) {
                return;
            }



              room['colspan'] = room['entityLength'] / 15; // calculate colspan for each time entry
              this.getRoomsOpeningHours(
                  room,
                  Object.values(
                      openingHours.roomType[room.roomTypeId].roomTypeOpeningHour,
                  )[0],
              ); // get rooms opening hours depending on room type

              this.getOpeningHoursToday(room); // get min opening and max closing hour for today
          });

          this.getAllCells();

          this.getBookings(forceRefresh || refreshBookings);
          this.hourSelectList = UtilsService.createTimeStringArray(
            this.tableSettings.opening,
            this.tableSettings.closing,
            60
          );
        });
    } else {
      this.getBookings(forceRefresh || refreshBookings);
    }
  }

  private getSelectedDate() {
    const month =
      this.selectedDate.month >= 10
        ? this.selectedDate.month
        : "0" + this.selectedDate.month.toString();
    const day =
      this.selectedDate.day >= 10
        ? this.selectedDate.day
        : "0" + this.selectedDate.day.toString();
    return {
      year: this.selectedDate.year,
      month: month,
      day: day,
    };
  }

  initBookingsByRoomIdForRoomId(roomId, bookingsByRoomId) {
    if (bookingsByRoomId[roomId] == undefined) {
      bookingsByRoomId[roomId] = { bookings: [], cells: [] };
    }
  }

  public getRoomName(id) {
    if (isArray(this.processedRoomList)) {
      return this.processedRoomList.filter((room) => {
        return room.id == id;
      })[0].name;
    }
    if (this.processedRoomList[id]) {
      return this.processedRoomList[id].name;
    }
    return "Raum #" + id;
  }

  private getBookings(forceRefresh = false): void {
      this.bookingsList = undefined;
    // prepend 0 to day/month if necessary
    const month =
      this.selectedDate.month >= 10
        ? this.selectedDate.month
        : "0" + this.selectedDate.month.toString();
    const day =
      this.selectedDate.day >= 10
        ? this.selectedDate.day
        : "0" + this.selectedDate.day.toString();
    let ids = _.map(this.roomList, (item) => {
      return item.id;
    });

    let where_condition = [
      {
        field: "roomId",
        operator: "IN",
        model: "bookings",
        value: "(" + ids.join(",") + ")",
      },
    ];
    // get bookings and set cells booked
    this.requestManager
      .get(
        "/bookings/" +
          this.selectedDate.year +
          "-" +
          month +
          "-" +
          day +
          "/user?where_condition=" +
          JSON.stringify(where_condition),
        this.requestManager.getJWT(),
        forceRefresh
      )
      .then((bookings) => {
        let tempHash = ""; //MD5(JSON.stringify(bookings)).toString();
        if (tempHash == this.lastHash) {
          //return;
        }

        this.lastHash = tempHash;
        let update = false;
        let bookingsListTemp;
        if (!this.bookingsList) {
          this.bookingsList = _.values(bookings["bookings"]).filter(
            (booking) => {
              return booking.storno !== "1";
            }
          );
          bookingsListTemp = this.bookingsList;
        } else {
          bookingsListTemp = _.values(bookings["bookings"]).filter(
            (booking) => {
              return booking.storno !== "1";
            }
          );
          update = true;
        }

        bookingsListTemp =
          this.bookingService.bookingTimeToDatePipe(bookingsListTemp);
        let bookingByRoomTemp = this.bookingsByRoomId;
        if (update) {
          bookingByRoomTemp = cloneDeep(this.bookingsByRoomId);
          _.each(bookingByRoomTemp, (room:any)=> {
            _.each(room.cells, cell => {
              cell.booked = false;
              cell.paid = false;
            });
          })

        }
        bookingsListTemp.forEach((booking) => {
          if (this.processedRoomList[booking.roomId] == undefined) {
            return;
          }

          // create bookings property if undefined
          this.initBookingsByRoomIdForRoomId(booking.roomId, bookingByRoomTemp);
          bookingByRoomTemp[booking.roomId]["bookings"].push(booking);

          // copy celly to local variable
          const cells = bookingByRoomTemp[booking.roomId]["cells"];

          // create time string for comparison
          for (let i = 0; i < cells.length; i++) {
            const actBookingTimeString =
              UtilsService.addEntityLength2TimeString(
                "00:00",
                new Date(
                  this.bookingService.formatDate(booking["bookingTimeFrom"])
                ).getMinutes() +
                  new Date(
                    this.bookingService.formatDate(booking["bookingTimeFrom"])
                  ).getHours() *
                    60
              );

            // if room is booked at time string set booked parameter to true
            if (cells[i]["time"] === actBookingTimeString) {
              bookingByRoomTemp[booking.roomId]["cells"][i].booked = true;
              bookingByRoomTemp[booking.roomId]["cells"][i].paid = booking.paid;
            }
          }
        });
        if (update) {
          _.each(this.bookingsByRoomId, (room: any, key) => {
            room.cells.forEach((cell, index) => {
              if (!bookingByRoomTemp[key]) return;
              let cellOther = bookingByRoomTemp[key].cells[index];
              if (cellOther.booked != cell.booked || cellOther.paid != cell.paid) {
                this.bookingsByRoomId[key].bookings = bookingByRoomTemp[key].bookings;
                this.bookingsByRoomId[key].cells[index].booked = cellOther.booked;
                this.bookingsByRoomId[key].cells[index].paid = cellOther.paid;
              }
            });
          });
        }
        if (this.loading) {
          this.loading = false;
        }

        this.bookForCustomrLoading$.next(false);
        //this.bookingsList = bookingsListTemp;
        this.bookingsLoaded$.next(bookings);

      });
  }

  getRoomBookings(room) {
    if (this.bookingsByRoomId && this.bookingsByRoomId[room.id]) {
      return this.bookingsByRoomId[room.id]["bookings"];
    }
  }

  getRoomCells(room) {
    let roomId;
    if (room && room.id) {
      roomId = roomId;
    } else {
      roomId = room;
    }
    if (this.bookingsByRoomId && this.bookingsByRoomId[roomId]) {
      return this.bookingsByRoomId[roomId]["cells"];
    }
    return [];
  }

  private getRoomsOpeningHours(room, openingHour): void {
      if (!openingHour) {
          return;
      }
      room['openingHours'] = openingHour;

      if (openingHour.openingFrom == '--:--' || openingHour.openingTo == '--:--') {
          return;
      }


      let fromTime = UtilsService.transformTimeString2EntityLength(
          openingHour.openingFrom,
      );
      let toTime = UtilsService.transformTimeString2EntityLength(
          UtilsService.addEntityLength2TimeString(openingHour.openingTo, -room['entityLength']),
      );
      room['roomSpan'] = toTime - fromTime;

    this.initBookingsByRoomIdForRoomId(room.id, this.bookingsByRoomId);
    this.bookingsByRoomId[room.id].cells =
      Math.floor(room["roomSpan"] / (room["entityLength"] / 15)) + 1;
    room["cells"] =
      Math.floor(room["roomSpan"] / (room["entityLength"] / 15)) + 1;
  }

  private getOpeningHoursToday(room): void {
    if (room.openingHours != undefined) {
      if (
        UtilsService.compareTimeStrings(
          this.tableSettings["opening"],
          room.openingHours.openingFrom
        )
      )
        this.tableSettings["opening"] = room.openingHours.openingFrom;

        if (
            UtilsService.compareTimeStrings(
                UtilsService.addEntityLength2TimeString(room.openingHours.openingTo, -room['entityLength']),

                this.tableSettings['closing'],
            )
        ) {
            this.tableSettings['closing'] = room.openingHours.openingTo;
        }
    }

    let fromTime = UtilsService.transformTimeString2EntityLength(
      this.tableSettings["opening"]
    );
    let toTime = UtilsService.transformTimeString2EntityLength(
      this.tableSettings["closing"]
    );
    this.tableSettings["spanTotal"] = toTime - fromTime + 4;
  }

  private getAllCells(): void {
    _.values(this.processedRoomList).forEach((room) => {
      let cells = this.createRoomCells(room);


        for (let i = 0; i < cells.length; i++) {
        if (room["openingHours"] != undefined) {
          if (
            UtilsService.compareTimeStrings(
              cells[i].time,
              room["openingHours"].openingFrom
            ) &&
            UtilsService.compareTimeStrings(
              UtilsService.addEntityLength2TimeString(room["openingHours"].openingTo, -room['entityLength']),
              cells[i].time
            )
          ) {
              cells[i].available = true;
          }
        }
      }

      this.initBookingsByRoomIdForRoomId(room.id, this.bookingsByRoomId);
      this.bookingsByRoomId[room.id].cells = cells;
      room["cells"] = cells;
    });
  }

  private createRoomCells(
    room
  ): { time: string; available: boolean; booked: boolean }[] {
      let fromTime = room.openingHours != undefined && room.openingHours.openingFrom != '--:--' ? room.openingHours.openingFrom : this.tableSettings.opening;
      let toTime = room.openingHours != undefined && room.openingHours.openingTo != '--:--' ? room.openingHours.openingTo : this.tableSettings.closing;

      let cells = UtilsService.createTimeStringArray(
          this.tableSettings.opening,
          this.tableSettings.closing,
          room.entityLength,
      ).map((x) => {
          return {
              time: x,
              available: false,
              booked: false,
          };
    });
    return cells;
  }

  getMinDate(): NgbDate {
      return new NgbDate(1990, 1, 1);
  }

  createHourBookingsTable(cell) {
    if (this.bookingsList != undefined) {
      let hourSelectListBooking = [];
        this.bookingsList.forEach((booking) => {
            if (
                new Date(
                    this.bookingService.formatDate(booking['bookingTimeFrom']),
                ).getHours() === Number(cell.substr(0, 2))
            ) {
                if (this.roomList[booking['roomId']]) {

                    hourSelectListBooking.push({
                        room: this.roomList[booking['roomId']].name,
              price: this.roomList[booking["roomId"]].price,
              bookingTimeFrom: booking["bookingTimeFrom"],
              bookingTimeTo: booking["bookingTimeTo"],
              customerId: booking.userId,
              customerName: _.first<any>(_.values(booking.user)).name,
              customerSurname: _.first<any>(_.values(booking.user)).surname,
              isPaid: booking.paid,
              bookingId: booking.id,
              formEntriesId: booking.formEntriesId,
            });
          }
        }
      });

        return hourSelectListBooking;
    }
  }

  createBookingForCustomer(cell, room): void {
      /*
         Smartphone required is not bookable on same day till 31.12.2022
          */
      if (room['smartphoneRequired'] == '1') {

          let today = this.getToday();
          if (this.selectedDate.year == today.year
              && this.selectedDate.month == today.month
              && this.selectedDate.day == today.day) {
              alert(this.translate.instant('INFO_SMARTPHONE_REQUIRED_ROOM_BOOK'));
              return;
          }
      }
      if (!cell.available) {
          return;
      }
      let now = new Date();
      const bookingTimeToTimeString = UtilsService.addEntityLength2TimeString(
          cell.time,
          room.entityLength,
      );

      let date = this.selectedDate;

    let bookingTimeFrom = UtilsService.getISOStringOfTimeString(
      date.year,
      date.month,
      date.day,
      cell.time
    );
    let bookingDate = new Date(bookingTimeFrom);
    if (now > bookingDate) {
      return;
    }

    const cartObject = {
      roomId: room.id,
      bookingTimeFrom: bookingTimeFrom, // translate Date to ISO-Time-String
      bookingTimeTo: UtilsService.getISOStringOfTimeString(
        date.year,
        date.month,
        date.day,
        bookingTimeToTimeString
      ), // translate Date to ISO-Time-String
    };

    let found = false;

    for (let i = 0; i < this.customerCart.length; i++) {
      if (JSON.stringify(this.customerCart[i]) === JSON.stringify(cartObject)) {
        this.customerCart.splice(i, 1);
        found = true;
      }
    }

    if (!found) {
      this.bookedCell[room.id + "-" + cartObject.bookingTimeFrom] = true;
      this.customerCart.push(cartObject);
      this.notifier.show({
        type: "success",
        message: "Buchung wurde zum Warenkorb hinzugefügt.",
      });
    }
  }

  openCustomerSelect() {
    let modalref = this.modal.open(UserSearchModalComponent);
    modalref.result.then((customer) => {
      this.customer = customer;
    });
  }
  createHourBookingPopover(cell, room): void {
    if (!room) {
      return;
    }
    this.singleBooking = [];
    this.singleBooking["cell"] = cell;
    this.singleBooking["room"] = room;
    let bookings = this.getRoomBookings(room);
    if (room && bookings) {
      bookings.forEach((roomBooking) => {
        if (
          new Date(
            this.bookingService.formatDate(roomBooking["bookingTimeFrom"])
          ).getHours() === Number(cell.time.substr(0, 2))
        ) {
          this.singleBooking["booking"] = {
            room: this.roomList[roomBooking["roomId"]].name,
            price: this.roomList[roomBooking["roomId"]].price,
            bookingTimeFrom: roomBooking["bookingTimeFrom"],
            bookingTimeTo: roomBooking["bookingTimeTo"],
            customerId: roomBooking.userId,
            customerName: roomBooking.user[roomBooking.userId].name,
            customerSurname: roomBooking.user[roomBooking.userId].surname,
            isPaid: roomBooking.paid,
            bookingId: roomBooking.id,
            formEntriesId: roomBooking.formEntriesId,
          };
        }
      });
    }
  }

  setBookingOfUser( setPaid: boolean,
    customerId: number,
    bookingId: string,
    bookingData: any) {
      if(setPaid) {

        this.bookingService.getBooking(bookingId).subscribe(booking => {
            if (this.popoverRef) {
                this.popoverRef.close();
            }
            const modalRef = this.bookingService.openPayBookingModal([booking], customerId);

            modalRef.closed.subscribe((result) => {
                if (result === true) {
                    this.loading = true;
                    this.loadingNewDate$.next(true);
                }
            });

        })

      }

  }


  isStornoable(booking) {
    return this.bookingService.isStornoable(booking);
  }

  stornoBooking(booking) {

      this.bookingService.cancelBookings([{ id: booking.bookingId, force: true }]).subscribe(() => {
              this.notifier.show({
                  type: 'success',
                  message: 'Buchung erfolgreich storniert!',
              });
              this.loadingNewDate$.next();
          },
      );
  }

  selectToday() {
      this.selectedDate = this.calendarService.getNext(this.getToday(), 'd',
          UtilsService.getLocalDate().getDate() - this.getToday().day);
      this.loading = true;
      this.loadingNewDate$.next(true);
  }
}
