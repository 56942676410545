import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PhoneValidationService {

    private errorState = new BehaviorSubject(null);

    constructor(private translate: TranslateService) {

    }

    public setErrorState(errorState: string | null): void {
        this.errorState.next(errorState);
    }

    public getErrorState(): Observable<string> {
        return this.errorState.pipe(
            map((error: string | null) => {
                if (error === null) {
                    return null;
                } else if (error === '500') {
                    return this.translate.instant('INITIATE_PHONE_VALIDATION_WARNING_ERROR');
                }
                return this.translate.instant('INITIATE_PHONE_VALIDATION_WARNING_ERROR');
            }),
        );
    }
}
