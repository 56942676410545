import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { BatchBookingStore, BatchBookingState } from "./batch-booking.store";
@Injectable({ providedIn: "root" })
export class BatchBookingQuery extends Query<BatchBookingState> {
  constructor(protected store: BatchBookingStore) {
    super(store);
  }

 
}
