import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RequestManagerService } from '../../../../services/requestManager/request-manager.service';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { VoucherService } from '../../../../services/voucher/voucher.service';

@Component({
    selector: 'app-customer-dashboard-accounting',
    templateUrl: './customer-dashboard-accounting.component.html',
    styleUrls: ['./customer-dashboard-accounting.component.css'],
})
export class CustomerDashboardAccountingComponent implements OnInit, OnChanges {
    @Input() refreshChild : any;
    public loading = new BehaviorSubject(false);

    @Input()
    customerId;

    @Output()
    loadingDone = new EventEmitter<string>();


    protected accountsReceivableSaldo;
    protected voucherSaldo;
    protected unpaidSaldo;

    constructor(private _requestManager: RequestManagerService,
                private voucherService: VoucherService,
                ) { }

    ngOnInit() {
        this.loadAccountingInformation();

        this.voucherService.voucherRedeemed.subscribe((voucherRedeemed: boolean) => {
            if (voucherRedeemed) {
                this.loadAccountingInformation();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges){
      this.loadAccountingInformation();
    }

    disableLoading() {
        this.loading.next(false);
        this.loadingDone.next('accounting');

    }

    loadAccountingInformation() {

        this.loading.next(true);

        const requests = [];

        const requestAccountsRecievable = this._requestManager.get("/user/" + this.customerId + "/accountsReceivable/saldo", this._requestManager.getJWT(), true);
        const requestVoucherSaldo = this._requestManager.get("/user/" + this.customerId  + "/voucher/saldo", this._requestManager.getJWT(), true);
        const unpaidSaldo = this._requestManager.get("/user/" + this.customerId + "/accountsReceivable/saldo?dueNow=true", this._requestManager.getJWT(), true);

        requests.push(requestAccountsRecievable);
        requests.push(requestVoucherSaldo);
        requests.push(unpaidSaldo);

        forkJoin(requests).toPromise().then((success) => {
          this.accountsReceivableSaldo = {amount: success[0]['saldo']};
          this.voucherSaldo = {amount: success[1]['saldo']};
          this.unpaidSaldo = {amount: success[2]['saldo']};
          this.disableLoading();
        },
          error => {
            console.log(error);
            this.disableLoading();
          });
    }

}
