interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
  expanded?: boolean
}

export const navItemsDefault: NavData[] = [
  {
      name: 'BOOK',
      url: '/',
      icon: 'dashboard',
  },
];

export const navItemsUser: NavData[] = [
    {
        name: 'BOOK',
        url: '/',
        icon: 'dashboard',
    },
    {
        name: 'MY BOOKINGS',
        url: '/customer-dashboard/bookings',
        icon: 'calendar_month',
    },
    {
        name: 'BALANCE',
        url: '/customer-dashboard/balance/',
        icon: 'credit_card',
    },
    {
        name: 'INVOICES',
        url: '/customer-invoices',
        icon: 'receipt',
    },
];

export const navItemsAdmin: NavData[] = [

    {
        name: 'MY BOOKINGS',
        url: '/customer-dashboard/bookings',
        icon: 'calendar_month',
    },
    {
        name: 'BALANCE',
        url: '/customer-dashboard/balance/',
        icon: 'credit_card',
    },
    {
        name: 'INVOICES',
        url: '/customer-invoices',
        icon: 'receipt',
    },
  {
    title: true,
    name: "Administration",
  },
  {
    name: "Locations",
    url: "/admin/locations",
    icon: "location_pin",
  },
  {
    name: "Räume",
    url: "/admin/room/",
    icon: "home",
    expanded: false,
    children: [
      {
        name: "erstellen",
        url: "/admin/room/create",
        icon: "add_circle_outline",
      },
      {
        name: "suchen/bearbeiten",
        url: "/admin/room/search",
        icon: "edit",
      },
      {
        name: "sortieren",
        url: "/admin/room/order",
        icon: "sort",
      },
    ],
  },
  {
    name: "Raumkategorien",
    url: "/admin/roomtype/",
    icon: "extension",
      expanded: false,
    children: [
      {
        name: "erstellen",
          url: '/admin/roomtype/create',
          icon: 'add_circle_outline',
      },
        {
            name: 'suchen/bearbeiten',
            url: '/admin/roomtype/search',
            icon: 'edit',
        },
    ],
  },
    {
        name: 'Buchungen',
        url: '/admin/bookings',
        icon: 'calendar_month',
        expanded: true,
        children: [
            {
                name: 'Übersicht',
                url: '/admin/bookings',
                icon: 'calendar_month',
            },
            {
                name: 'Export',
                url: '/admin/bookings/export',
                icon: 'share',
            },
        ],
    },

    {
        name: 'Kundenübersicht',
        url: '/admin/customers',
        icon: 'list',
    },
    {
        name: 'Kassa',
        url: 'admin/r2o-checkout',
        icon: 'credit_card',
    },
  {
    name: "Gutscheine",
    url: "/admin/voucher",
    icon: "badge",
  },
  {
    name: "Dynamische Formulare",
    url: "/admin/dynamic-forms",
    icon: "badge",
      expanded: false,
    children: [
      {
        name: "Erstellen / Bearbeiten",
        url: "/admin/dynamic-forms",
        icon: "add_circle_outline",
      },
      {
        name: "Formulareinträge",
        url: "/admin/dynamic-form-entries",
        icon: "dynamic_form",
      },
    ],
  },
  {
    name: "Einstellungen",
    url: "/admin/settings",
    icon: "settings",
      expanded: false,
    children: [
      {
          name: "Allgemein",
          url: "/admin/settings",
          icon: "settings" },
      {
        name: "Räume Zuordnung",
        url: "/admin/room/cashRegister",
        icon: "assignment",
      },
      {
        name: "User Zuordnung ",
        url: "/admin/user/cashRegister",
        icon: "assignment",
      },
      {
        name: "User Kontenansicht ",
        url: "/admin/accountsReceivable",
        icon: "people",
      },
    ],
  },
];

export const navItemsOffice: NavData[] = [
    {
        name: "Meine Buchungen",
        url: "/customer-dashboard/bookings",
        icon: "calendar_month",
    },
    {
        name: "Guthaben",
        url: "/customer-dashboard/balance/",
        icon: "credit_card",
    },
    {
        name: 'Rechnungen',
        url: '/customer-invoices',
        icon: 'receipt',
    },
    {
        title: true,
        name: 'Office',
    },

    {
        name: 'Buchungen',
        url: '/admin/bookings',
        icon: 'calendar_month',
        expanded: true,
        children: [
            {
                name: 'Übersicht',
                url: '/admin/bookings',
                icon: 'calendar_month',
            },
            {
                name: 'Export',
                url: '/admin/bookings/export',
                icon: 'share',
            },
        ],
    },

    {
        name: 'Kundenübersicht',
        url: '/admin/customers',
        icon: 'list',
    },
    {
        name: 'Kassa',
        url: 'admin/r2o-checkout',
        icon: 'credit_card',
    },
];
