import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { tap } from 'lodash';
import { map, mergeMap } from 'rxjs/operators';
import { authUser, loadUser, loadUserSuccess } from '../actions/auth.actions';
import { ApiService } from '../services/api/api.service';
import { SessionManagerService } from '../services/requestManager/session-manager.service';



@Injectable()
export class AuthEffects {



  constructor(private actions$: Actions, private session: SessionManagerService, private api:ApiService) {}
  @Effect()
  authLoginHandle = this.actions$.pipe(
    ofType(authUser),
    map( ({jwt, data}) => {

      this.session.setItem('jwt',jwt);
      return loadUser({userid: data['data']['user_id']});
     }
  ));
  @Effect()
  authUser = this.actions$.pipe(
    ofType(loadUser),
    mergeMap( ({userid}) => {
      return this.api.getUser(userid).pipe(map ( user  => {
        return loadUserSuccess({user:user});
      }));
    })
  )

}
