import { Injectable } from "@angular/core";
import { exception } from "console";
import { request } from "http";
import * as _ from "lodash";
import { isArray, isString } from "lodash";
import { forkJoin, from, of, scheduled } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { RequestManagerService } from "./requestManager/request-manager.service";

@Injectable({
  providedIn: "root",
})
export class DynamicFormsService {
  constructor(private requestManager: RequestManagerService) {}

public saveFormEntry(formEntry) {
  return this.requestManager.post('/formEntries', formEntry, this.requestManager.getJWT());
}

  public deleteFormFields(idArray) {
    console.log("Delete IDS ", idArray);
    let requests = [];
    for (let id of idArray) {
      requests.push(
        this.requestManager.delete(
          "formField/" + id,
          this.requestManager.getJWT()
        )
      );
    }
    return forkJoin(requests);
  }

  public saveForm(formData) {
    if(formData.notificationEmail == "") {
      formData.notificationEmail = null;
    }
    if (formData.id) {
      return this.updateForm(formData);
    } else {
      return this.createForm(formData);
    }
  }

  private updateForm(formData) {
    let formId = formData.id;
    return this.requestManager
      .put(
        "/form/" + formData.id,
        this.requestManager.getJWT(),
        _.pick(formData, ["name", "notificationEmail"])
      )
      .pipe(
        mergeMap((response: any) => {
          let requests = [];

          for (let field of formData.formFields) {
            requests.push(this.handleFormField(field, formId));
          }
          return forkJoin(requests);
        })
      );
  }

  private createForm(formData) {
    let formId;
    return this.requestManager
      .post("/form", _.pick(formData, ["name", "notificationEmail"]), this.requestManager.getJWT())
      .pipe(
        mergeMap((response: any) => {
          formId = response[0];
          let requests = [];

          for (let field of formData.formFields) {
            requests.push(this.handleFormField(field, formId));
          }
          return forkJoin(requests);
        })
      );
  }

  deleteForm(form) {
    return from(
      this.requestManager.get(
        "/formField?where_condition=" +
          JSON.stringify([
            {
              field: "formId",
              model: "formField",
              value: form.id,
              operator: "=",
            },
          ])
      )
    ).pipe(
      mergeMap((response: any) => {
        let observable;
        if (response.formField) {
          let requests = [];
          for (let field of Object.values<any>(response.formField)) {
            requests.push(
              this.requestManager.delete(
                "/formField/" + field.id,
                this.requestManager.getJWT()
              )
            );
          }
          observable = forkJoin(requests);
        } else {
          observable = of(1);
        }

        return observable.pipe(
          mergeMap((response) => {
            return this.requestManager.delete(
              "/form/" + form.id,
              this.requestManager.getJWT()
            );
          })
        );
      })
    );
  }
  private handleFormField(field, formId) {
    if (!field.id) {
      return this.createFormField(field, Number.parseInt(formId));
    } else {
      return this.updateFormField(field, Number.parseInt(formId));
    }
  }


  public getFormEntry(formEntryId) {
    return from(this.requestManager.getAuth('/formEntries/' + formEntryId)).pipe(map((response: any) => {
        if (response == []) {
            return response;
        }

        return Object.values<any>(response.formEntries)[0];
    }));
  }
  public getFormById(formId) {
    let form: any;
    return from(this.requestManager.get("/form/" + formId)).pipe(
      mergeMap((response: any) => {
        if (response.form) {
          form = Object.values(response.form)[0];
        }
        return from(
          this.requestManager.get(
            "/formField?where_condition=" +
              JSON.stringify([
                {
                  field: "formId",
                  model: "formField",
                  value: formId,
                  operator: "=",
                },
              ])
          )
        ).pipe(
          map((res: any) => {
            if (res.formField) {
              form.formFields = Object.values(res.formField);
              return form;
            }
          })
        );
      })
    );
  }

  private getFormFieldBody(formField) {
    if (isString(formField.formId)) {
      formField.formId = Number.parseInt(formField.formId);
    }
    if (isString(formField.required)) {
      formField.required = formField.required == "1" ? true : false;
    }
    if (isString(formField.sorting)) {
      formField.sorting = Number.parseInt(formField.sorting);
    }

    if (formField.name == "") {
      formField.name = formField.displayName.replaceAll(" ", "");
    }
    if (formField.sorting == 0) {
      formField.sorting = 1;
    }

    if (formField.type == "text" || formField.type == 'checkbox') {
      formField.options = undefined;
    }
    formField = _.defaults(formField, { sorting: 1 });
    return _.pick(formField, [
      "type",
      "formId",
      "required",
      "name",
      "displayName",
      "options",
      "validation",
      "sorting",
    ]);
  }
  private updateFormField(formField, formId) {
    let body = this.getFormFieldBody(formField);
    body.formId = formId;
    return this.requestManager.put(
      "/formField/" + formField.id,
      this.requestManager.getJWT(),
      body
    );
  }

  private createFormField(formField, formId) {
    let body = this.getFormFieldBody(formField);
    body.formId = formId;
    return this.requestManager.post(
      "/formField",
      body,
      this.requestManager.getJWT()
    );
  }
  }
