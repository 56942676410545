import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-user-rights-confirm-modal',
  templateUrl: './change-user-rights-confirm-modal.component.html',
  styleUrls: ['./change-user-rights-confirm-modal.component.scss']
})
export class ChangeUserRightsConfirmModalComponent implements OnInit {

    modalTitle;
    modalBodyText;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ChangeUserRightsConfirmModalComponent>) {
      this.modalBodyText= this.data.modalBodyText;
      this.modalTitle= this.data.modalTitle;
  }

  ngOnInit(): void {
  }

    clickClose(mode) {
        this.dialogRef.close(mode);
    }
}
