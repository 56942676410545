import { Component, OnInit } from '@angular/core';
import {RequestManagerService} from '../../services/requestManager/request-manager.service';
import {UtilsService} from '../../services/utils/utils.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-data-protection',
    templateUrl: './data-protection.component.html',
    styleUrls: ['./data-protection.component.scss'],
})
export class DataProtectionComponent implements OnInit {

    dataProtectionText = {
        settingsKey: 'page_dataProtection',
        settingsValue: '',
        settingsId: 1,
        id: 6,
    };

    constructor(private dialogRef: MatDialogRef<DataProtectionComponent>,
                private requestManager: RequestManagerService) {
        this.requestManager.get('/settings/settingsValue', this.requestManager.getJWT(), true).then(
            (res) => {
                let companySettings = UtilsService.getSettingsOfCategoryByName('companySettings', res);
                this.dataProtectionText = UtilsService.getSettingByKeynameFromCategory('page_dataProtection', companySettings);
            },
        );
    }

  ngOnInit(): void {

  }

  close() {
    this.dialogRef.close();
  }
}
