import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-user-base-data-modal',
  templateUrl: './edit-user-base-data-modal.component.html',
  styleUrls: ['./edit-user-base-data-modal.component.scss']
})
export class EditUserBaseDataModalComponent implements OnInit {

    form: FormGroup;
    userData;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<EditUserBaseDataModalComponent>,
              private fb: FormBuilder
  ) {
      this.form= this.fb.group({
          'firstname': new FormControl("",[Validators.required]),
          'surname': new FormControl("",[Validators.required])
      })

      this.form.setValue({
          firstname: this.data.name,
          surname: this.data.surname
      });

  }

  ngOnInit(): void {
  }

    clickClose() {
        this.dialogRef.close('cancel');
    }

    clickSave()
    {
        this.data.name= this.form.get('firstname').value;
        this.data.surname= this.form.get('surname').value;

        this.dialogRef.close(this.data);
    }
}
