import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';



export interface ImportBookingRow {
  roomId:number,
  time:number,
  timeFrom:string,
  userId:number,
  date:number|Date,
  isPossible?:boolean
}

export interface BatchBookingState {
   importingBookings: ImportBookingRow[];
}

export function createInitialState(): BatchBookingState {
  return {
    importingBookings: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'batch-booking' })
export class BatchBookingStore extends Store<BatchBookingState> {

  constructor() {
    super(createInitialState());
  }

}
