import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { loadAllRooms, loadCancellationPeriods } from './actions/room.actions';
import { RequestManagerService } from './services/requestManager/request-manager.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ColorsService } from './services/colors/colors.service';
import { Colors } from './services/colors/colors';
import { NotifierService } from 'angular-notifier';

@Component({
    // tslint:disable-next-line
    selector: 'body',
    templateUrl: './app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'app';
    color = undefined;
    supported = true;
    latestSupportedVersion = 0;
    userAgent = null;

    constructor(
        private router: Router,
        private translate: TranslateService,
        private requestManager: RequestManagerService,
        private store: Store,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private colorService: ColorsService,
        private notifierService: NotifierService,
    ) {

        this.colorService.colors$.subscribe((colors: Colors) => {
            const root = document.documentElement;
            root.style.setProperty('--primary-color', colors.primary);
            root.style.setProperty('--primary-color-selected', colors.primaryColorSelected);
            root.style.setProperty('--primary-color-hover', colors.primaryColorHover);
            root.style.setProperty('--black-inactive', colors.blackInactive);
            root.style.setProperty('--black-disabled', colors.blackDisabled);
            root.style.setProperty('--success-color', colors.success);
            root.style.setProperty('--warn-color', colors.warn);
            root.style.setProperty('--font-family-primary', colors.font);

            // const elements = document.querySelectorAll(':not(mat-icon):not(.material-icons-outlined)');
            // Array.from(elements).forEach((element) => {
            //     this.renderer.setStyle(element, 'font-family', colors.font);
            // });
        });


        const { userAgent } = navigator;
        console.log(userAgent);
        this.userAgent = userAgent;


        if (userAgent.toLowerCase().includes('fbav') || userAgent.toLowerCase().includes('fban') || userAgent.toLowerCase().includes('fbios')) {
            /*
            * always allow userAgent for facebook browser
             */
            this.supported = true;
        } else if (userAgent.includes('OPR/')) {
            this.latestSupportedVersion = 70;
            this.checkBrowserVersion(userAgent, 'OPR/', this.latestSupportedVersion);
        } else if (userAgent.includes('Firefox/')) {
            this.latestSupportedVersion = 90;
            this.checkBrowserVersion(userAgent, 'Firefox/', this.latestSupportedVersion);
        } else if (userAgent.includes('Edg/')) {
            this.latestSupportedVersion = 91;
            this.checkBrowserVersion(userAgent, 'Edg/', this.latestSupportedVersion);
        } else if (userAgent.includes('Chrome/')) {
            this.latestSupportedVersion = 92;
            this.checkBrowserVersion(userAgent, 'Chrome/', this.latestSupportedVersion);
        } else if (userAgent.includes('Safari/')) {
            this.latestSupportedVersion = 14;
            this.checkBrowserVersion(userAgent, 'Safari/', this.latestSupportedVersion);
        }

        this.matIconRegistry
            .addSvgIcon('tune', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/maticon/tune.svg'))
            .addSvgIcon('info', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/maticon/info.svg'))
            .addSvgIcon('shopping_cart', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/maticon/shopping_cart.svg'))
            .addSvgIcon('smartphone', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/maticon/smartphone.svg'));
        translate.setDefaultLang('de');
        translate.use('de');

        if (this.supported) {
            this.store.dispatch(loadAllRooms());
            this.store.dispatch(loadCancellationPeriods());
            this.store.dispatch(loadAllRooms());
        }

    }

    ngOnInit() {


        this.requestManager.updateUserInfo().subscribe();
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });

    }


    checkBrowserVersion(userAgent: string, browserString: string, latestSupportedVersion: number): void {
        if (browserString === 'Safari/') {
            browserString = 'Version/';
        }
        try {
            if (this.latestSupportedVersion === -1) {
                this.supported = false;
            } else {
                let version = parseInt(userAgent.split(browserString)[1]
                    .split(' ')[0]
                    .split('.')[0]);
                if (version != undefined) {
                    if (version < latestSupportedVersion) {
                        this.supported = false;
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

}
