import { Injectable } from '@angular/core';

import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { from, of } from 'rxjs';
import { mergeMap, map, shareReplay, withLatestFrom, switchMap, tap } from 'rxjs/operators';
import { addBookingsToCheckout } from '../actions/admin.actions';
import { countCustomers, countCustomersSuccess, loadCustomers, loadCustomersSuccess } from '../actions/customer.actions';
import { State } from '../reducers';
import { ApiService } from '../services/api/api.service';



@Injectable()
export class AdminEffects {

  constructor(private actions$: Actions, private notifier: NotifierService) {}

  @Effect({dispatch:false})
  bookingAdded$ = this.actions$.pipe(ofType(
    addBookingsToCheckout
  ),
  tap( () => {
    this.notifier.show({message:"Buchung zum Admin Checkout hinzugefügt.", type:"success"});
  }));

}
