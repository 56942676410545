import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivateChild {
    constructor(private cookieService: CookieService,
                private router: Router,) {
    }
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (state.url !== '/login'
            && state.url !== '/register'
            && state.url.startsWith('/validation') == false
            && state.url !== '/'
            && state.url !== '/checkout'
            && state.url.startsWith('/page') == false
            && state.url.startsWith('/location') == false) {
            if (this.cookieService.get('jwt') == undefined || this.cookieService.get('jwt') == '') {
                this.router.navigateByUrl('login');
                return false;
            }
            return true;
        }

        return true;
    }
}
