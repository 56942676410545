import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';

@Component({
  selector: 'app-order-room-type-special',
  templateUrl: './order-room-type-special.component.html',
  styleUrls: ['./order-room-type-special.component.css']
})
export class OrderRoomTypeSpecialComponent implements OnInit {

  @Input()
  formId;
  @Input()
  room:any;
  @Input()
  booking:any;

  eveningEventForm: {
    end:string,
    paymentMethod: string,
    requiredInstruments:string,
    seats:string,
    recording:string,
    sound:string,
    beamer:string,
    buffet:string,
    table:string
    eventProgram:string,
    image:File,
    bookingTimeFrom?:string

  } = {
    end:'early',
    paymentMethod:'',
    requiredInstruments:'',
    seats:'',
    recording:'',
    sound:'',
    beamer:'',
    buffet:'',
    table:'',
    eventProgram:'',
    image:null
  }
  constructor(private _rm: RequestManagerService, private active:NgbActiveModal) { }

  ngOnInit(): void {
  }
  save(data) {
    
    

    this.active.close(data);
    
    
  }

}
