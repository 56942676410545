import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {RequestManagerService} from '../../services/requestManager/request-manager.service';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../../services/utils/utils.service';
import { select, Store } from '@ngrx/store';
import { loadCancellationPeriods, loadRooms } from '../../actions/room.actions';
import { State } from '../../reducers';
import { take,map } from 'rxjs/operators';
import { openLocationSelectModal, selectCurrentLocation } from '../../actions/location.actions';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from '../../services/location/location.service';
import { RoomManagerService } from '../../services/roomManager/room-manager.service';

@Component({
  selector: 'app-allrooms',
  templateUrl: './allrooms.component.html',
  styleUrls: ['./allrooms.component.scss']
})
export class AllroomsComponent implements OnInit {
  loading = {
    parent: true,
    rooms : true,
    bookings : true
  };

  roomList: any[];
  public roomDescription: any[];
  currentLang: string;
  rooms$;
    noRooms = false;
    location$;
  constructor(
      private requestManager: RequestManagerService,
      public translate: TranslateService,
      private store$: Store,
      private route: ActivatedRoute,
      public locationService: LocationService,
      private roomManager: RoomManagerService,
  ) {
    this.location$ = this.store$.pipe(
      select((state: State) => state.location.current)
    );
    this.roomDescription = [];
    route.params.subscribe( params => {
      if(params['id']) {
        this.store$.pipe(select((state:State) => state.location.all)).subscribe(locations=> {
          if(locations[params['id']]) {
              this.locationService.locationChange$.next(locations[params['id']].name);
          this.store$.dispatch(selectCurrentLocation(locations[params['id']]))
          }
        })
        
      }
    })
  }


  ngOnInit() {
    // this.store$.dispatch(loadRooms());
    this.rooms$ = this.store$.select((state:State) => state.data.rooms).pipe(map((rooms) => {
            let roomsToShow = [];
            if (rooms) {
                this.noRooms = false;
                if (Object.values(rooms).length === 0) {
                    this.noRooms = true;
                }
                Object.values(rooms).forEach((room: any) => {
                    if (room.disabled == 1) {
                        return;
                    } else {
                        roomsToShow.push(room);
                    }
                });
            } else {
                this.noRooms = true;
            }
            return _.sortBy(roomsToShow, (room) => Number.parseInt(room.roomOrder));

        }),
        map((rooms) => {
            this.roomManager.roomList = rooms;
            this.roomManager.loadingSubject$.next();
            return rooms;
        }));
      this.loading.parent = false;
    let locations$ = this.store$.select((state: State) => state.location.current);
    let openModal$ = locations$.pipe(take(1),
    map(current => {
      if(!current) {
        this.store$.dispatch(openLocationSelectModal());
      }
    }));

    openModal$.subscribe();

  }
  /*
* Get all rooms and reorder in list with right language
* */
  getRooms() {
    this.loading.parent = true;
    let whereCondition = [{
      field: "disabled",
      operator: "<>",
      model: "rooms",
      value: "1",
    }];
    this.requestManager.get('/room/roomDescription/roomInstruments/roomType/roomTypeOpeningHour?where_condition='+JSON.stringify(whereCondition), null, true).then(
      rooms => {
        let tempRoomList = [];
        _.values(rooms['room']).forEach(
          (room) => {
            UtilsService.getDescriptionFromLanguage(room, this.translate.currentLang);
            room['instruments'] = _.values(room['roomInstruments']);

            if (room.disabled !== '1') {
              tempRoomList.push(_.cloneDeep(room));
            }
          }
        );
        this.roomList = tempRoomList;
        this.loading.parent = false;
      }
    );
  }

}
