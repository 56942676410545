import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { environment } from '../../../../../environments/environment';
import { NgbCalendar, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { ApiService } from '../../../../services/api/api.service';
import { UtilsService } from '../../../../services/utils/utils.service';
import { BookingService } from '../../../../services/booking/booking.service';
import { OrderRoomTypeSpecialComponent } from '../../../order-room-type-special/order-room-type-special.component';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { CartManagerService } from '../../../../services/cartManager/cart-manager.service';
import { State } from '../../../../reducers';
import { Store } from '@ngrx/store';
import { ModalSmartphoneRequiredComponent } from '../../../modal-smartphone-required/modal-smartphone-required.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-room-card',
    templateUrl: './room-card.component.html',
    styleUrls: ['./room-card.component.scss'],
})
export class RoomCardComponent implements OnInit, OnChanges {
    @Input('cardRoom') room: any;
    @Output() bookingsLoading = new EventEmitter();
    @Input() filteredDate;

    bookingsLoading$= new BehaviorSubject(false);


    mode: 'booking' | 'image' | 'information' = 'image';

    loadingSubject$ = new Subject();
    public selectedDate: NgbDate;
    public minDate: NgbDate;
    public maxDate: NgbDate;
    public processedRoom: any;
    notOpened: boolean = false;
    smartphoneRequiredSameDay = false;


    constructor(private calendarService: NgbCalendar,
                private api: ApiService,
                private bookingService: BookingService,
                private notifier: NotifierService,
                public translate: TranslateService,
                private cartManager: CartManagerService,
                private modalServiceNgb: NgbModal,
                private matDialog: MatDialog,
                private store: Store,
    ) {
        if (this.filteredDate != null) {
            this.selectedDate.day = this.filteredDate.date();
            this.selectedDate.month = this.filteredDate.month() + 1;
            this.selectedDate.year = this.filteredDate.year();
        } else {
            this.selectedDate = this.calendarService.getNext(this.calendarService.getToday(), 'd',
                UtilsService.getLocalDate().getDate() - this.calendarService.getToday().day);
        }

        this.minDate = _.cloneDeep(this.selectedDate);
        this.maxDate = _.cloneDeep(this.minDate);
        this.maxDate.day = this.minDate.day;
        this.maxDate.month = this.minDate.month;
        this.maxDate.year = this.minDate.year + 1;

    }

  ngOnInit(): void {
      this.processedRoom = _.cloneDeep(this.room);
      this.loadingSubject$.pipe(tap(() => {
          this.bookingsLoading.next(true);
      }));


  }

    ngOnChanges(changes: SimpleChanges): void {
        this.bookingsLoading.next(true);
        // process roomList when got data about it
        if (this.filteredDate != null) {
            this.selectedDate = this.filteredDate;
        }

        if (changes.room != undefined) {
            if (changes.room.currentValue != undefined) {
                this.processedRoom = _.cloneDeep(this.room);// clone object without reference, deep means the object has child-objects
            }
        }

    }

    public processRoomList(forceRefresh): void {
        if (forceRefresh) {
            this.processedRoom = _.cloneDeep(this.room);

        }

        const selDate = this.getSelectedDate();
        this.api
            .getOpeningHours(selDate.year, selDate.month, selDate.day)
            .pipe(take(1))
            .subscribe((openingHours: any) => {
                if (openingHours.roomType[this.processedRoom.roomTypeId] == undefined ||
                    openingHours.roomType[this.processedRoom.roomTypeId].roomTypeOpeningHour == undefined ||
                    Object.values(
                        openingHours.roomType[this.processedRoom.roomTypeId].roomTypeOpeningHour,
                    )[0] == undefined
                ) {
                    this.bookingsLoading.next(false);
                    this.notOpened = true;
                    return;
                }

                /*
                   Smartphone required is not bookable on same day till 31.10.2022
                    */


                if (this.processedRoom['smartphoneRequired'] == '1') {

                    let today = this.calendarService.getToday();

                    if (selDate.year == today.year
                        && selDate.month == today.month
                        && selDate.day == today.day) {
                        this.bookingsLoading.next(false);
                        this.smartphoneRequiredSameDay = true;
                        console.log(this.processedRoom);
                        return;
                    }
                }

                if (this.room['smartphoneRequired'] == '1') {

                    let today = this.calendarService.getToday();

                    if (selDate.year == today.year
                        && selDate.month == today.month
                        && selDate.day == today.day) {
                        this.bookingsLoading.next(false);
                        this.smartphoneRequiredSameDay = true;
                        console.log(this.processedRoom);
                        return;
                    } else {
                        this.room['bookableSmartphone'] = false;
                    }
                }

                this.smartphoneRequiredSameDay = false;
                this.notOpened = false;


                this.getRoomsOpeningHours(this.processedRoom, Object.values(
                    openingHours.roomType[this.processedRoom.roomTypeId].roomTypeOpeningHour,
                )[0]);

                this.getAllCells();

                this.getBookings(forceRefresh);
            });
    }


    private getBookings(forceRefresh = true): void {
        this.bookingsLoading$.next(true);
        this.bookingService.getBookings(
            this.selectedDate,
            [this.processedRoom],
        ).subscribe((room) => {
            this.processedRoom = Object.values<any>(room).pop();

            this.bookingsLoading$.next(false);
        });
    }


    private getRoomsOpeningHours(room, openingHour): void {
        // console.log(openingHour);
        if (openingHour != undefined) {
            this.processedRoom = { ...room, 'openingHours': openingHour };

            if (openingHour.openingFrom == '--:--' || openingHour.openingTo == '--:--') {
                return;
            }
            const fromTime = UtilsService.transformTimeString2EntityLength(
                openingHour.openingFrom,
            );
            const toTime = UtilsService.transformTimeString2EntityLength(
                openingHour.openingTo,
            ) - room['entityLength'];

            this.processedRoom = { ...this.processedRoom, 'roomSpan': toTime - fromTime };

            this.processedRoom = {
                ...this.processedRoom, 'cells': Math.floor(
                    this.processedRoom['roomSpan'] / (this.processedRoom['entityLength'])),
            };


        }
    }

    private getAllCells(): void {

        const cells = this.getRoomCells(this.processedRoom);
        for (let i = 0; i < cells.length; i++) {
            if (this.processedRoom['openingHours'] != undefined) {
                const now = new Date();
                const nowTimeString = UtilsService.transformEntityLength2TimeString(
                    now.getHours() * 60 + now.getMinutes(),
                );

                if (
                    UtilsService.compareTimeStrings(
                        cells[i].time,
                        this.processedRoom['openingHours'].openingFrom,
                    ) &&
                    UtilsService.compareTimeStrings(
                        UtilsService.addEntityLength2TimeString(this.processedRoom['openingHours'].openingTo, -this.processedRoom['entityLength']),
                        cells[i].time,
                    )
                ) {
                    if (
                        this.getToday().equals(this.selectedDate) &&
                        UtilsService.compareTimeStrings(nowTimeString, cells[i].time)
                    ) {
                        cells[i].available = false;
                    } else {
                        cells[i].available = true;
                    }
                }
            } else {
                const now = new Date();
                const nowTimeString = UtilsService.transformEntityLength2TimeString(
                    now.getHours() * 60 + now.getMinutes(),
                );
                cells[i].available = false;
            }
        }
        this.processedRoom['cells'] = cells;
    }

    public getToday(): NgbDate {
        return this.calendarService.getToday();
    }

    private getRoomCells(
        room,
    ): { time: string; available: boolean; booked: boolean }[] {
        const cells = UtilsService.createTimeStringArray(
            room['openingHours'].openingFrom,
            room['openingHours'].openingTo,
            room.entityLength,
        ).map((x) => {
            return {
                time: x,
                available: false,
                booked: false,
            };
        });
        return cells;
    }


    private getSelectedDate() {
        const month =
            this.selectedDate.month >= 10
                ? this.selectedDate.month
                : '0' + this.selectedDate.month.toString();
        const day =
            this.selectedDate.day >= 10
                ? this.selectedDate.day
                : '0' + this.selectedDate.day.toString();
        return {
            year: this.selectedDate.year,
            month: month,
            day: day,
        };
    }

    getInstruments(room: any) {
        let instruments = [];
        _.values(room['roomInstruments']).forEach((instrument) => {
            instruments.push(instrument.instrument);
        });
        return instruments.join(',');
    }

    getDescription(room: any) {
        return this.store
            .select((state: State) => state.ui.language)
            .pipe(
                map((langcode) => {
                    let languageObject = Object.values<any>(room['roomDescription']).find((description) => description.language == langcode) != undefined ?
                        Object.values<any>(room['roomDescription']).find((description) => description.language == langcode) : Object.values<any>(room['roomDescription'])[0];

                    return languageObject.description;
                }),
            );

    }

    getBase(){
        return environment.apiUrl;
    }


    changeMode(mode)
    {
        this.mode = mode;
    }

    getOpeningUnit() {

      let number= Number.parseInt(this.processedRoom.entityLength)/60;
        if(number===1)
        {
            return '';
        }
        return number.toFixed(2);
    }

    public subDay(): void {
        this.bookingsLoading.next(true);
        if (this.minDate.before(this.selectedDate)) {
            this.selectedDate = this.calendarService.getPrev(
                this.selectedDate,
                'd',
                1,
            );
            this.loadingSubject$.next(true);
            this.processRoomList(true);

        }
    }
    addToCart(room: any, cell: any): void {

        const cartObject: any = this.getCartObject(room,cell);

        // check if booking is available
        if (cell.available && !cell.booked) {
            let formId = room.formId;
            if (formId) {
                let modalRef = this.modalServiceNgb.open(OrderRoomTypeSpecialComponent);
                modalRef.componentInstance.room = room;
                modalRef.componentInstance.formId = formId;
                modalRef.componentInstance.booking = UtilsService.getISOStringOfTimeString(
                    this.selectedDate.year,
                    this.selectedDate.month,
                    this.selectedDate.day,
                    cell.time
                );
                modalRef.result.then((result) => {
                    if (result !== false) {
                        cartObject.specialInfo = result;
                    }
                    if (!this.cartManager.deleteByObjectFromCart(cartObject)) {
                        this.cartManager.addToCart(cartObject);
                        this.notifier.show({
                            type: "success",
                            message: this.translate.instant("cart.addProduct"),
                        });
                    }
                });
            } else {
                // console.log(cell);
                // create ISO-Time-Strings

                // handle input in cartManagerService
                if (!this.cartManager.deleteByObjectFromCart(cartObject)) {
                    this.cartManager.addToCart(cartObject);
                    this.notifier.show({
                        type: "success",
                        message: this.translate.instant("cart.addProduct"),
                    });
                }
            }
        }
    }

    public addDay(): void {
        this.bookingsLoading.next(true);
        if (this.maxDate.after(this.selectedDate)) {
            this.selectedDate = this.calendarService.getNext(this.selectedDate, 'd', 1);
            this.loadingSubject$.next(true);
            this.processRoomList(true);
        }

    }

    cellIsInCart(room: any, cell: any) {

        if (cell.booked == true) {

            return false;
        }
        const cartObject: any = this.getCartObject(room, cell);

        if (this.cartManager.cart.some(cartObjectFromArray => JSON.stringify(cartObjectFromArray) == JSON.stringify(cartObject))) {
            return true;
        }

        return false;
    }

    getNumberOfCellsInCart() {
        let number = 0;

        this.cartManager.cart.forEach((booking) => {
            if (booking.roomId == this.processedRoom.id) {
                number++;
            }
        })
        return number;
    }

    getCartObject(room: any,cell: any){
        const bookingTimeToTimeString = UtilsService.addEntityLength2TimeString(
            cell.time,
            room.entityLength
        );

        const cartObject: any = {
            roomId: room.id,
            bookingTimeFrom: UtilsService.getISOStringOfTimeString(
                this.selectedDate.year,
                this.selectedDate.month,
                this.selectedDate.day,
                cell.time
            ), // translate Date to ISO-Time-String
            bookingTimeTo: UtilsService.getISOStringOfTimeString(
                this.selectedDate.year,
                this.selectedDate.month,
                this.selectedDate.day,
                bookingTimeToTimeString
            ), // translate Date to ISO-Time-String
        };

        return cartObject;
    }


    dateChanged(value) {
        let today = new Date();

        let selectedValue = new Date();

        selectedValue.setDate(value._i.date);
        selectedValue.setMonth(value._i.month);
        selectedValue.setFullYear(value._i.year);

        let todayNextYear = new Date();
        todayNextYear.setFullYear(today.getFullYear() + 1);
        if (today <= selectedValue && selectedValue < todayNextYear) {
            const newDate: NgbDate = new NgbDate(value._i.year, value._i.month + 1, value._i.date);
            this.selectedDate = newDate;
            this.processRoomList(true);
        }

    }

    imageLoaded() {
    }

    thumbnailLoaded() {

    }

    getUrl() {
        return this.getBase() + '/rooms/' + this.processedRoom.id + '/image/0';
    }

    openSmartphoneRequired() {
        this.matDialog.open(ModalSmartphoneRequiredComponent);
    }
}
