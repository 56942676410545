import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import {DomSanitizer} from "@angular/platform-browser";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-modal-more-information',
  templateUrl: './modal-more-information.component.html',
  styleUrls: ['./modal-more-information.component.css']
})
export class ModalMoreInformationComponent implements OnInit {
  @Input() room;
  openedToday = false;
  instruments = [];

  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    this.isOpenToday();
    this.instruments = Object.values(this.room.roomInstruments);
  }

  getImage(room: any) {
    
    return environment.apiUrl+"/rooms/"+room.id+"/image/0";
    return;
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + _.values(room['roomImages'])[0].image);
  }

  isOpenToday(){
    const openingHours = _.values(_.values(this.room['roomType'])[0].roomTypeOpeningHour);
    const days = { 1: "Mon", 2: "Tue", 3: "Wed", 4: "Thu", 5: "Fri", 6: "Sat", 0: "Sun"};

    const today = days[new Date().getDay()];
    openingHours.forEach(
      day => {
        if (day.day === today) {
          this.openedToday = true;
        }
      }
    );
  }

}
