import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { State } from '../../reducers';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IndividualTextService } from '../../services/individualTexts/individual-text.service';
import { forkJoin } from 'rxjs';
import { DataProtectionComponent } from '../data-protection/data-protection.component';
import { MatDialog } from '@angular/material/dialog';
import { ISettingsValue } from '../admin/settings/settingsValue';
import { UtilsService } from '../../services/utils/utils.service';
import { LoginService } from '../login/login.service';


@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
    loading = false;
    registrationForm = new FormGroup({
      title: new FormControl('', [Validators.required, Validators.minLength(1)]),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      birthYear: new FormControl(null, Validators.required),
      birthMonth: new FormControl(null, Validators.required),
        birthDay: new FormControl(null, Validators.required),
        address: new FormGroup({
            country: new FormControl('', [Validators.required, Validators.minLength(1)]),
            street: new FormControl('', Validators.required),
            zip: new FormControl('', [Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)]),
            city: new FormControl('', Validators.required),
        }),
        phone: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
        emailRepeat: new FormControl('', [Validators.required, Validators.email]),
        newsletter: new FormControl(false, Validators.required),
        dataProtection: new FormControl(false, [Validators.requiredTrue]),
        sendBookingConfirmationEmails: new FormControl(false, [Validators.required]),
        language: new FormControl('DE', Validators.required),
    });
    today: NgbDate;
    errors: any = {};

    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.Austria, CountryISO.Germany];

    language$;
    newsletterLabel = null;
    dataprotectionLabel = null;
    sendBookingConfirmationEmailsLabel = null;

    settingPhoneCountry: ISettingsValue =
        {
            settingsKey: 'phoneCountry',
            settingsValue: '',
            settingsId: 1,
            id: 37,
        };

    constructor(private requestManager: RequestManagerService,
                private ngbCalendar: NgbCalendar,
                private notification: NotifierService,
                private translate: TranslateService,
                private router: Router,
                private store: Store,
                private individualTextService: IndividualTextService,
                private matDialog: MatDialog,
                private loginService: LoginService) {

        this.language$ = this.store
            .select((state: State) => state.ui.language)
            .pipe(
                switchMap((langcode) => {
                    return forkJoin(
                        this.individualTextService.getIndividualTextByLanguage(langcode, 'dataProtectionLabel'),
                        this.individualTextService.getIndividualTextByLanguage(langcode, 'newsletterLabel'),
                        this.individualTextService.getIndividualTextByLanguage(langcode, 'sendBookingConfirmationEmailsLabel'));
                }),
            ).subscribe(([dataProtectionLabel, newsletterLabel, sendBookingConfirmationEmailsLabel]) => {
                this.dataprotectionLabel = dataProtectionLabel;
                this.newsletterLabel = newsletterLabel;
                this.sendBookingConfirmationEmailsLabel = sendBookingConfirmationEmailsLabel;
            });

        this.requestManager.get('/settings/settingsValue', this.requestManager.getJWT(), true)
            .then(
                (res) => {
                    let companySettings = UtilsService.getSettingsOfCategoryByName('companySettings', res);
                    this.settingPhoneCountry = UtilsService.getSettingByKeynameFromCategory('phoneCountry', companySettings);
                },
            );
    }

    ngOnInit() {
    }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  register() {
    setTimeout(() => {
      this.loading = true;
    })


    if (this.registrationForm.invalid) {
      this.errors = {};
      Object.keys(this.registrationForm.controls).forEach( (key) => {
        if (this.registrationForm.get(key).errors) {
          this.errors[key]=true;
        }
        if(key == "address") {
          Object.keys((<FormGroup>this.registrationForm.get(key)).controls).forEach( (key2) => {
            if((<FormGroup>this.registrationForm.get(key)).get(key2).errors){
              this.errors[key2]=true;
            }
          })
        }
      })
      this.notification.show({message: this.translate.instant('registration.form.uncomplete'), type: 'warning'});
      setTimeout(() => {
        this.loading=false;
      },200);

      return;
    } else if (this.registrationForm.value['email'].localeCompare(this.registrationForm.value['emailRepeat'])){
      this.notification.show({message: this.translate.instant('registration.form.emailDifferent'), type: 'warning'});
      setTimeout(() => {
        this.loading=false;
      },200);

      return;
    } else {

      const birthDate = new Date(this.registrationForm.get('birthYear').value, this.registrationForm.get('birthMonth').value - 1, this.registrationForm.get('birthDay').value, 12);
      const body = {
          title: this.registrationForm.value['title'],
          name: this.registrationForm.value['firstName'],
          surname: this.registrationForm.value['lastName'],
          street: this.registrationForm.value.address['street'],
          zip: this.registrationForm.value.address['zip'],
          city: this.registrationForm.value.address['city'],
          country: this.registrationForm.value.address['country'],
          email: this.registrationForm.value['email'],
          phone: this.registrationForm.value['phone'].e164Number,
          newsletter: this.registrationForm.value['newsletter'] ? 1 : 0,
          dateOfBirth: birthDate.toISOString().substr(0, 10) + ' 00:00:00',
          language: this.registrationForm.value['language'],
          sendBookingConfirmationEmails: this.registrationForm.value['sendBookingConfirmationEmails'] ? 1 : 0,
      }

        this.requestManager.post('/registration', body).pipe(
        ).subscribe(
            response => {
                /**
                 * Due to cost factors, phone numbers with +1 prefix (USA, Canada) are not validated.
                 */
                if (this.registrationForm.value['phone'].e164Number.startsWith('+1')) {
                    this.notification.show({ message: this.translate.instant('registration.form.complete'), type: 'success' });
                    this.router.navigateByUrl('/').then(() => {
                        this.loginService.isLoggingIn.next(false);
                    });
                } else {
                    this.router.navigateByUrl('validation/' + response + '?phone=' + this.registrationForm.value['phone'].e164Number ?? '');
                }

            },
            error => {
                console.log(error);
                if (error.error.includes('E-Mail') || error.error.includes('Phone')) {
                    this.notification.show({ message: this.translate.instant('registration.form.emailOrPhoneUsed'), type: 'error' });
                } else if (error.error.indexOf('street') != -1) {
                    this.notification.show({ message: this.translate.instant('registration.form.wrongStreetFormat'), type: 'error' });
                } else {
                    this.notification.show({ message: this.translate.instant('registration.form.error'), type: 'error' });
                }
                this.loading = false;
            },
            () => {
                this.loading = false;
            },
        );
    }
  }

    counter(i: number) {
        return new Array(i);
    }

    openDataProtection() {

        this.matDialog.open(DataProtectionComponent);

    }
}
