import {Component, OnInit} from '@angular/core';
import {RequestManagerService} from "../../../services/requestManager/request-manager.service";
import * as _ from "lodash";
import {debounceTime, distinctUntilChanged, map, tap} from "rxjs/operators";
import {Observable} from "rxjs/internal/Observable";
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {NotifierService} from "angular-notifier";
import { Store } from '@ngrx/store';
import { countCustomers, countCustomersSuccess, loadCustomers, loadCustomersSuccess } from '../../../actions/customer.actions';
import { State } from '../../../reducers';
import { Actions, ofType } from '@ngrx/effects';
import { isThisTypeNode } from 'typescript';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {
  loading = true;
  clients: any[];
  emails: any[];
  page = 1;
  pages = 1;
  pageSize = 10;
  countResult = 0;
  countCustomers$: Observable<number>;
  public searchTerm: string;
  customers$: Observable<any>;
  search$:Subject<string>=new Subject();
  searchField:FormControl;

  constructor(
    private requestManager: RequestManagerService,
    private router: Router,
    private translationService: TranslateService,
    private notifier: NotifierService,
    private store : Store,
    private actions$: Actions
  ) {
  }

  ngOnInit() {
    this.searchField = new FormControl()
    this.loading = false;
    this.searchEvent(this.searchField.valueChanges);
    this.store.dispatch(countCustomers());
    this.countCustomers$ = this.store.select((state:State) => state.counts.customers);
   
  }

  search(value) {
    // console.log(value);
    this.search$.next(value);
  }
  searchEvent = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
      ).subscribe( (term) => {
        // console.log(term)
        this.searchTerm = term;
        this.getUserList();
      });
      

    

    changePage(page){
      this.page = page;
        // console.log(page);

    }
  getUserList(forceRefresh = false) {
    this.customers$ = this.store.select((state:State) => state.data.customers).pipe(map(customers => {
      return _.values(customers);
    }));
   
    this.loading = true;

    this.store.dispatch(loadCustomers({searchTerm: this.searchTerm, offset:0, limit:200}));
    this.customers$.subscribe((customers)=>{
      this.countResult = Object.values(customers).length;
        // console.log(this.countResult);
      
    })
    this.actions$.pipe(
      ofType(loadCustomersSuccess),
      tap((customers) => {
        this.loading = false;
        this.countResult= Object.values(customers).length;
        
      })
    )
    
    this.loading = false;
    this.emails = [];
    
  }

  openBookings(id:number) {
    this.router.navigateByUrl('customer-dashboard-bookings/'+id);

  }
  editUser(customerId: number) {
    this.router.navigateByUrl('admin/customer/' + customerId);
  }

  deleteUser(customerId: number) {
    this.translationService.get('QUESTION_DELETE_CUSTOMER')
      .subscribe(
        translated => {
          if (confirm(translated)) {
            this.requestManager.delete('/user/' + customerId, this.requestManager.getJWT())
              .subscribe(
                response => {
                  this.translationService.get('SUCCESS_DELETE_CUSTOMER')
                    .subscribe(
                      translated => {
                        this.notifier.show({type: 'success', message: translated});
                        this.getUserList(true);
                      }
                    )
                },
                error => {
                  this.translationService.get('ERROR_DELETE_CUSTOMER')
                    .subscribe(
                      translated => {
                        this.notifier.show({type: 'danger', message: translated});
                      }
                    )
                }
              )
          }
        });
  }
}
