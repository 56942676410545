import { Component, OnInit } from '@angular/core';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';
import { from } from "rxjs";
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.css']
})
export class AgbComponent implements OnInit {

  content$;
  constructor(private _rm:RequestManagerService) { }

  ngOnInit(): void {
    let whereCondition = {
      field:'settingsKey',
      operator:'=',
      value:'page_agb',
      model:'settingsValue'
    }
    this.content$ = from(this._rm.get('/settingsValue?where_condition='+JSON.stringify(whereCondition))).pipe(map( (result:any) => {
      return Object.values<any>(result.settingsValue)[0].settingsValue;
    }))
  }

}
