import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from } from "rxjs";
import { map } from 'rxjs/operators';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';


@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  content$;
  constructor(private _rm:RequestManagerService, private route:ActivatedRoute) { }

  ngOnInit(): void {

    this.route.params.subscribe( (param) => {
      let whereCondition = [{
        'field':'settingsKey',
        'value':'page_agb',
        'operator':'=',
        'model':'settingsValue'
      }]
      whereCondition[0]['value'] = '"page_'+param['page']+'"';
      this.content$ = from(this._rm.get('/settingsValue?where_condition='+JSON.stringify(whereCondition))).pipe(map( (result:any) => {
        return Object.values<any>(result.settingsValue)[0].settingsValue;
      }))
    })

  }

}
