import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {
  getAllDataFromLocalForage,
  default as localForage,
} from 'ngrx-store-persist';
 
getAllDataFromLocalForage({
  driver: localForage.INDEXEDDB,
  keys: [
    'admin',
  ],
}).then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
});

if (environment.production) {
  enableProdMode();
}

/*
Sentry.init({
  dsn: "https://65920d7b53d248ab85c3f80f229b6f86@o519111.ingest.sentry.io/5628938",
  integrations: [
  /*  new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "musikquartier.klaviergalerie.at", "beta.proberaum.klaviergalerie.at"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
*/
