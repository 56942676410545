import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { mergeMap, map, shareReplay, withLatestFrom, switchMap } from 'rxjs/operators';
import { countCustomers, countCustomersSuccess, loadCustomers, loadCustomersSuccess } from '../actions/customer.actions';
import { State } from '../reducers';
import { ApiService } from '../services/api/api.service';



@Injectable()
export class CustomerEffects {



  constructor(private actions$: Actions, private api: ApiService, private store : Store) { }

  @Effect()
  customerLoad$ = this.actions$.pipe(
    ofType(loadCustomers),
    withLatestFrom(this.store.select((state) => {
      return (<State>state);
    })),
    mergeMap( ([action, state]) => {
     
      return this.api.getCustomers(action.searchTerm,action.limit).pipe(
        map(data => loadCustomersSuccess({customers:data}))
      )
    }),

  )
      
  @Effect()
  customerCount$ = this.actions$.pipe(
    ofType(countCustomers),
    mergeMap( () => {
      return this.api.countCustomers().pipe(map(data => {
        console.log(data);
        return countCustomersSuccess({count: data});
      }
    ))
    }
  )
  );

}
