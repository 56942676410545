import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestManagerService } from '../../../services/requestManager/request-manager.service';

@Component({
  selector: 'app-admin-customer',
  templateUrl: './admin-customer.component.html',
  styleUrls: ['./admin-customer.component.css']
})
export class AdminCustomerComponent implements OnInit {

  sub;
  id;
  bookingId;
  componentsLoading={
    data:true,
    actions:true,
    accounting:true
  };
  subComponentsAreLoading = true;
  constructor(private route: ActivatedRoute,
    private _requestManager: RequestManagerService,
    public modalService: NgbModal) { }

  ngOnInit(): void {

    this.route.fragment.subscribe((fragment) => {
        if (fragment) {
          this.bookingId = fragment;
        }
      }
    );


    this.sub = this.route.params.subscribe(params => {
      if (params['id'] != undefined) {
        this.id = +params['id'];
      } else {
        this.id = this._requestManager.getIdOfUserThatIsLoggedIn();
      }
    });
  }


  loadingDone(name){
    this.componentsLoading[name]=false;
    this.updateLoadingStatus()
  }

  updateLoadingStatus() {

    if (
      !this.componentsLoading.data &&
      !this.componentsLoading.actions &&
      !this.componentsLoading.accounting) {
      this.subComponentsAreLoading = false;
    } else {
      this.subComponentsAreLoading = true;
    }
  }
  getCustomerId() {
    return this.id;
  }

  showErrorPage(httpCode, errorMessage) {

  }

  onRefresh($event: any) {
    // console.log($event);
    this.modalService.dismissAll();
  }

}
