import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-airkey-app-not-registered',
    templateUrl: './modal-airkey-app-not-registered.component.html',
    styleUrls: ['./modal-airkey-app-not-registered.component.scss'],
})
export class ModalAirkeyAppNotRegisteredComponent implements OnInit {

    constructor(private dialogRef: MatDialogRef<ModalAirkeyAppNotRegisteredComponent>) {
    }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close();
    }
}
