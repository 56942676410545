
import { storageSyncMetaReducer } from 'ngrx-store-persist';

import {
  ActionReducerMap,
  createReducer,
  MetaReducer,
  on,
} from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { environment } from '../../environments/environment';
import { authUser, loadUserSuccess } from '../actions/auth.actions';
import { countCustomersSuccess, loadCustomersSuccess } from '../actions/customer.actions';
import { loadLocationsDone, selectCurrentLocation } from '../actions/location.actions';
import { loadAllRoomsDone, loadCancellationPeriodSuccess, loadRoomsDone, searchRoomsDone } from '../actions/room.actions';
import { loadRoomtypesDone } from '../actions/roomtype.actions';
import { changeLanguage } from '../actions/ui.actions';
import { addBookingsToCheckout, removeAllBookingsFromCheckout, removeBookingFromCheckout, selectedCheckoutLocation } from '../actions/admin.actions';
import {searchBookingsDone} from '../actions/booking.actions';


export interface State {
  location:ILocationState
  auth:  IAuthState,
  data: {
    roomTypes:any[],
    rooms:{},
    customers:{},
    user:any,
    allRooms: any,
    cancellationPeriods: {[key:string]: {id:string, cancellationPeriod: number|null}}
  },
  searchResult: {
    rooms:any[],
    bookings: any[]
  }
  counts: {
    customers:number,
  },
  ui: IUIState,
  admin : {
    checkoutBookings: any[],
    selectedLocation:string,
  }

}
interface IUIState {
  language:string;
}
interface IAuthState {
  user: any,
    isLoggedIn?: boolean,
    isAdmin: boolean,
    isOffice: boolean,
    jwt:string
}

interface ILocationState {

    current: any,
    all: any

}


export const selectLocation = (state: State) => state.location;

export const reducers: ActionReducerMap<State> = {
  admin : createReducer(
    {checkoutBookings:[],
      selectedLocation:""},
      on(selectedCheckoutLocation, (state, payload) => ({
        ...state,
        selectedLocation : payload.selectedLocationId
      })),
      on(addBookingsToCheckout, (state, payload) => {
        let bookings = payload.bookings.filter( (object) => {
          if(state.checkoutBookings.findIndex((objectSearch) => {
            return object.id == objectSearch.id;
          }) != -1) {
            return false;
          } else {
            return true;
          }
        })
        return {
        ...state,
        checkoutBookings : state.checkoutBookings.concat(bookings)
        }
      }),
      on(removeBookingFromCheckout, (state, payload) => ({
        ...state,
        checkoutBookings: state.checkoutBookings.filter( (object) => {
         return object.id !== payload.booking.id
        })
      })),
      on(removeAllBookingsFromCheckout, (state, payload) => ({
          ...state,
          checkoutBookings:[]
      }))
  ),
  location: createReducer(
    { current: null, all: [] },
    on(loadLocationsDone, (state, { locations }) => ({
      ...state,
      all: locations
    })),
    on(selectCurrentLocation, (state, location) => ({
      ...state,
      current:location
    }))
    ),
  auth: createReducer<IAuthState>(
    {
      user: null,
      isLoggedIn: false,
      isAdmin: false,
      isOffice: false,
      jwt:null
    },
    on(authUser, (state, {data,isAdmin, isOffice,jwt}) => ({
      ...state,
      user: data,
      isLoggedIn: true,
      isAdmin: isAdmin,
      isOffice: isOffice,
      jwt:jwt
    }))
  ),
  data: createReducer(
    {roomTypes : [], rooms: [], customers: {}, user: {}, allRooms:{}, cancellationPeriods:{}},
    on(loadRoomtypesDone, (state, {roomTypes}) => ({
      ...state,
      roomTypes: roomTypes
    })),
    on(loadAllRoomsDone, (state, {rooms})=> {
      return {
        ...state,
        allRooms:rooms
      }
    }),
    on(loadRoomsDone, (state, {rooms}) => {


     return {
      ...state,
      rooms: rooms
    }
  }),
  on(loadCancellationPeriodSuccess, (state, {cancellationPeriod}) => {
    return {
      ...state,
      cancellationPeriods: cancellationPeriod
    }
  }),
    on((loadCustomersSuccess), (state, {customers}) => {
    return {
      ...state,
      customers:customers
    }
    }),
    on((loadUserSuccess), (state, {user}) => {
      return {
        ...state,
        user:user
      }
    })
  ),
  ui: createReducer(
    {language:'DE'},
    on(changeLanguage, (state, {language}) => ({
      ...state,
      language: language
    }))
  ),
  searchResult: createReducer(
    {rooms:[], bookings: []},
    on(searchRoomsDone, (state,{rooms}) => ( {
      ...state,
      rooms
    })),
    on(searchBookingsDone, (state,{bookings}) => ( {
      ...state,
      bookings
    }))
  ),

  counts: createReducer(
    {customers:0},
    on(countCustomersSuccess, (state, {count}) => ({
      ...state,
      customers:count
    })
  )
  )

};



export const metaReducers: MetaReducer<State>[] = !environment.production ? [storageSyncMetaReducer] : [storageSyncMetaReducer];
