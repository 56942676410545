import { Injectable, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import jwt_decode from "jwt-decode";
import { CookieService } from "ngx-cookie-service";
import { Store } from "@ngrx/store";
import { authUser, loadUserSuccess } from "../../actions/auth.actions";
import { SessionManagerService } from "./session-manager.service";
import { environment } from "./../../../environments/environment";
import { catchError, debounceTime, map } from "rxjs/operators";
import {
  from,
  Observable,
  observable,
  ObservableInput,
  of,
  Subject,
  throwError,
} from "rxjs";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgbToast } from "@ng-bootstrap/ng-bootstrap";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class RequestManagerService implements OnInit {
  public base = environment.apiUrl;
  memory = {};

  private jwt;
  private jwtDecoded;
  private userIsOffice;
  private userIsAdmin;
  private errorSubject = new Subject();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private store: Store,
    private session: SessionManagerService,
    private ngxService: NgxUiLoaderService,
    private notifier: NotifierService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.initError();
  }

  ngOnInit() {
    this.memory = [];
  }

  resetMemory() {
    this.memory = [];
  }

  updateUserInfo() {
    this.reloadJWT();
    if (this.jwt != '') {
      return this.getAuth("/auth/jwt/check",(error: HttpErrorResponse) => {
        if (error.status == 401) {
          this.resetJWT();
          this.router.navigateByUrl("/");
          window.location.reload();
          return of([]);
        }
        throw error;
      });
    }

    return of([]);
  }

  getAuth(url, errorHandler = null, responseType = 'json') {

      if (errorHandler == null) {
          errorHandler = this.errorPipe;
      }
      let httpOptions: any = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: this.getJWT(),
          }),
          parameters: {},
          responseType: responseType,
      };
    return this.http
      .get(this.base + url, httpOptions)
      .pipe(catchError(errorHandler));
  }

  initError() {
    this.errorSubject.pipe(debounceTime(1000)).subscribe((error) => {
      setTimeout(() => {
        this.ngxService.stopAll();
        this.notifier.show({
          type: "error",
          message: this.translate.instant('general.error'),
        });
      }, 1);
    });
  }
  errorPipe = (error: HttpErrorResponse, observable) => {
    if (error.status == 401) {
      // this.resetJWT();
      return;
    }
    this.sendErrorToBackend(error);

    return throwError(error);
  };

  sendErrorToBackend(error: HttpErrorResponse) {
    this.http.post(environment.baseUrl + "debug.php", { error }).subscribe();
  }

  getFile(url) {
    let httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this.getJWT(),
        }),
        responseType: 'blob' as 'json',
    };
      return this.http
          .get<Blob>(this.base + url, httpOptions)
          .pipe(catchError(this.errorPipe));
  }

    getZipFileInvoices(url, body: { invoiceIds: string[] }) {
        let httpOptions = {
            headers: new HttpHeaders({
                Authorization: this.getJWT(),
            }),
            responseType: 'arraybuffer' as 'json',
            observe: 'response' as const,
        };
        return this.http
            .post(this.base + url, body, httpOptions)
            .pipe(catchError(this.errorPipe));
    }

    async get(url, token = null, forceRefresh = false, params = {}) {
        if (this.memory.hasOwnProperty(url) && !forceRefresh) {
            // console.log("REQUEST MEMORY: " + url);
            // console.log(this.memory);
            return this.memory[url];
        } else {
            let httpOptions;
            if (token != null) {
                httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: token,
          }),
        };
      } else {
        httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        };
      }
      httpOptions.params = params;
      this.memory[url] = await this.http
        .get(this.base + url, httpOptions)
        .pipe(catchError(this.errorPipe))
        .toPromise();
      return this.memory[url];
    }
  }

  post(
    url,
    body,
    token = null,
    errorHandler: (
      err: any,
      caught: Observable<any>
    ) => ObservableInput<any> = null
  ) {
    if (errorHandler == null) {
      errorHandler = this.errorPipe;
    }

    if (token != null || this.jwt != null) {
      if (this.jwt) {
        token = this.jwt;
      }
    }
    if (token != null) {
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: token,
        }),
      };
      return this.http
        .post(this.base + url, body, httpOptions)
        .pipe(catchError(errorHandler));
    } else {
      return this.http
        .post(this.base + url, body)
        .pipe(catchError(errorHandler));
    }
  }

  postFile(url, body, token = null) {
    if (token != null) {
      let httpOptions = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      };
      return this.http
        .post(this.base + url, body, httpOptions)
        .pipe(catchError(this.errorPipe));
    } else {
      return this.http
        .post(this.base + url, body)
        .pipe(catchError(this.errorPipe));
    }
  }

  put(url, token = null, body) {
    if (token != null || this.jwt != null) {
      if (this.jwt) {
        token = this.jwt;
      }
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: token,
        }),
      };
      return this.http
        .put(this.base + url, body, httpOptions)
        .pipe(catchError(this.errorPipe));
    } else {
      return this.http
        .put(this.base + url, body)
        .pipe(catchError(this.errorPipe));
    }
  }

  delete(url, token = null) {
    if (token != null || this.jwt != null) {
      if (this.jwt) {
        token = this.jwt;
      }
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: token,
        }),
      };
      return this.http
        .delete(this.base + url, httpOptions)
        .pipe(catchError(this.errorPipe));
    } else {
      return this.http.delete(this.base + url).pipe(catchError(this.errorPipe));
    }
  }

    deleteWithBody(url, token = null,body) {
        if (token != null || this.jwt != null) {
            if (this.jwt) {
                token = this.jwt;
            }
            const httpOptions = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: token,
                }),
                body: body
            };
            return this.http
                .delete(this.base + url, httpOptions)
                .pipe(catchError(this.errorPipe));
        } else {
            return this.http.delete(this.base + url).pipe(catchError(this.errorPipe));
        }
    }

  auth(username, password) {
    return new Promise((resolve, reject) => {
      this.post(
        "/auth",
        {
          username: username,
          password: password,
        },
        "",
        (err: HttpErrorResponse) => {
          return throwError(err);
        }
      ).subscribe(
        (success) => {
          this.jwt = success["data"];
          this.jwtDecoded = jwt_decode(this.jwt);
          this.cookieService.set("jwt", this.jwt);
          this.updateLoggedInUserObject().then((success) => {
            this.cookieService.set("userIsAdmin", this.userIsAdmin);
            this.cookieService.set("userIsOffice", this.userIsOffice);
            this.store.dispatch(
              authUser({
                username: username,
                data: this.jwtDecoded,
                isAdmin: this.userIsAdmin,
                isOffice: this.userIsOffice,
                jwt: this.jwt,
              })
            );
            resolve(true);
          });
        },
        (error) => {
          reject();
        }
      );
    });
  }

  resetJWT() {
    this.jwt = "";
    this.cookieService.set("jwt", "");

    this.router.navigateByUrl("/");
  }

  reloadJWT() {
    this.userIsAdmin = this.cookieService.get("userIsAdmin");
    this.userIsOffice = this.cookieService.get("userIsOffice");
    if (!this.jwt || this.jwt == "") {
      this.jwt = this.cookieService.get("jwt");

      if (this.jwt != "") {
        this.jwtDecoded = jwt_decode(this.jwt);
        this.store.dispatch(
          authUser({
            username: this.jwtDecoded["data"]["user_email"],
            data: this.jwtDecoded,
            isAdmin: this.userIsAdmin,
            isOffice: this.userIsOffice,
            jwt: this.jwt,
          })
        );
        return true;
      }
      return false;
    }
    return true;
  }

  getJWT() {
    this.reloadJWT();
    return this.jwt;
  }

  getIdOfUserThatIsLoggedIn() {
    this.reloadJWT();
    return this.jwtDecoded['data']['user_id'] ?? null;
  }

  getEmailAddressOfUserThatIsLoggedIn() {
    this.reloadJWT();
    return this.jwtDecoded["data"]["user_email"];
  }

  getUserIsAdmin() {
    this.reloadJWT();
    return this.userIsAdmin;
  }

  getUserIsOffice() {
    this.reloadJWT();
    return this.userIsOffice;
  }

  updateLoggedInUserObject() {
    return new Promise((resolve, reject) => {
      this.get("/user/" + this.getIdOfUserThatIsLoggedIn(), this.getJWT()).then(
        (success) => {
          let user;
          Object.keys(success["user"]).forEach((key) => {
            user = success["user"][key];
          });
          this.userIsAdmin = user["isAdmin"];
          this.userIsOffice = user["isOffice"];

          resolve(true);
        },
        (error) => {
          reject();
        }
      );
    });
  }
}
