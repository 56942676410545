import { HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { NotifierService } from "angular-notifier";
import { cloneDeep } from "lodash";
import { Subject, from, Observable, forkJoin, of, throwError } from 'rxjs';
import {
    tap,
    take,
    mergeMap,
    withLatestFrom,
    map,
    defaultIfEmpty, catchError, concatMap,
} from 'rxjs/operators';
import { State } from '../../reducers';
import { DynamicFormsService } from '../dynamic-forms.service';
import { RequestManagerService } from '../requestManager/request-manager.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PhoneValidationService } from '../phoneValidation/phone-validation.service';

@Injectable({
    providedIn: 'root',
})
export class CartManagerService {
    public cartRefresh$ = new Subject();
    @Output() restartedCart: EventEmitter<boolean> = new EventEmitter();
    public cartLengthChanged = new Subject<number>();
    public cart: {
        roomId: number;
    bookingTimeFrom: string;
    bookingTimeTo: string;
    specialInfo?: any;
  }[];
  private rooms$: Observable<any>;
  private user$: Observable<any>;

  constructor(
      private requestManager: RequestManagerService,
      private store: Store,
      private notifier: NotifierService,
      private dynamicService: DynamicFormsService,
      private router: Router,
      private translate: TranslateService,
      private phoneValidationService: PhoneValidationService,
  ) {
    this.cart = [];
    this.rooms$ = this.store.select((state: State) => state.data.allRooms);
    this.user$ = this.store.select((state: State) => state.auth.user);

      let cart = JSON.parse(localStorage.getItem('my_cart'));

      if (cart) {
          this.cart = cart;
          this.checkForOldBookings();
          this.cartRefresh$.next(cart);
      }
  }

    checkForOldBookings(): boolean {
        let oldBookings = 0;
        this.cart.forEach((booking) => {
            let time = new Date(booking.bookingTimeFrom);
            let today = new Date();

            if (today > time) {
                oldBookings++;
            }
        });
        return oldBookings < 1;
    }
  getCart(): {
    roomId: number;
    bookingTimeFrom: string;
    bookingTimeTo: string;
  }[] {
    return this.cart;
  }


  setCart(cart: any[]): void {
    this.cart = cart;
    this.cartLengthChanged.next(this.cart.length);
    this.cartRefresh$.next(this.cart);
    this.updateCartToLocalStorage();
  }

  updateCartToLocalStorage() {
    localStorage.setItem("my_cart", JSON.stringify(this.cart));
  }
  addToCart(booking: {
    roomId: number;
    bookingTimeFrom: string;
    bookingTimeTo: string;
  }): boolean {
    try {
      this.cart.push(booking);
      this.sortCart();
      this.cartLengthChanged.next(this.cart.length);
      this.cartRefresh$.next(this.cart);

      this.updateCartToLocalStorage();
      return true;
    } catch (e) {
      return false;
    }
  }

  deleteByObjectFromCart(booking: {
    roomId: number;
    bookingTimeFrom: string;
    bookingTimeTo: string;
  }): boolean {
    for (let i = 0; i < this.cart.length; i++) {
      if (JSON.stringify(this.cart[i]) === JSON.stringify(booking)) {
        this.cart.splice(i, 1);
        this.cartRefresh$.next(this.cart);

        this.updateCartToLocalStorage();
        return true;
      }
    }
    return false;
  }

  deleteByIndexFromCart(index: number): boolean {
    try {
      this.cart.splice(index, 1);
      this.cartRefresh$.next(this.cart);

      this.updateCartToLocalStorage();
      return true;
    } catch (e) {
      return false;
    }
  }

  getCartSize(): number {
    return this.cart.length;
  }

  restartCart() {
    this.cart = [];
    this.cartRefresh$.next(this.cart);

    this.updateCartToLocalStorage();
    this.restartedCart.next(true);
  }

  private sortCart() {
    this.cart.sort((a, b) => {
      if (
        a.bookingTimeFrom
          .substr(0, 10)
          .localeCompare(b.bookingTimeFrom.substr(0, 10)) == 0
      ) {
        return a.roomId - b.roomId;
      }
      return a.bookingTimeFrom.localeCompare(b.bookingTimeFrom);
    });
  }

  private bookErrorHandler = (error: HttpErrorResponse) => {
      console.log(error);
      if (error.error == 'System Error:There are unpaid bookings in the past') {
          return throwError(error);
      }
      if (error.status == 402) {
          /*this.notifier.show({
            message:"Es gibt unbezahlte Buchungen in der Vergangenheit. Wir konnten Ihre Buchung nicht erstellen.",
            type:"error"
          })*/

          return throwError(error);
      }
  };

    public bookCart(cardPayment = false): Observable<any> {
        this.rooms$.pipe(take(1)).subscribe((rooms) => console.log(rooms));
        let specialInfos = [];
        return this.rooms$.pipe(
            take(1),
            withLatestFrom(this.user$.pipe(take(1))),
            concatMap(([rooms, auth]) => {

                let body = cloneDeep<any>(this.getCart());
                let smartphoneRequiredRoom = false;
                /*
                check if there is a booking with a smartphone required room
                 */
                body.forEach((booking) => {
                    if (rooms[booking.roomId].smartphoneRequired === '1') {
                        smartphoneRequiredRoom = true;
                    }
                    booking.price = Number.parseFloat(rooms[booking.roomId].price);
                    if (booking.specialInfo) {
                        specialInfos.push(booking);
                    }
                });

          /*
           if there is a booking with a smartphone required room, check if user already has evva-airkey person id
           */
          if (smartphoneRequiredRoom) {
              return this.requestManager.getAuth('/user/' + auth.data.user_id + '?array_values=true').pipe(
                  map((user) => {
                      let userEVVAId = false;
                      let userPhone = false;
                      /*
                      there hast be only one user with this id, therefore pop is not a problem
                       */
                      user = Object.values(user.user).pop();
                      if (user.EVVAPersonId != null) {
                          userEVVAId = true;
                      }
                      if (user.phone != null && user.phone != '') {
                          userPhone = true;
                      }
                      return [body, userEVVAId, userPhone, auth.data.user_id, true];
                  }),
              );
          }
          return of([body, null, false, auth.data.user_id, false]);
      }),
            concatMap(([body, userEVVAId, userPhone, userId, smartphoneRequiredRoom]) => {

                if (smartphoneRequiredRoom && !userPhone) {
                    return from(this.router.navigateByUrl('validation/booking/' + userId)).pipe(
                        map(() => {
                            return 'validation';
                        }),
                    );
                }
                /*
                if there is a booking with a smartphone required room and user has no evva airkey person id,
                initate tan validation
                 */
            if (smartphoneRequiredRoom && !userEVVAId) {
                return this.requestManager.post('/registration/' + userId + '/initiatePhoneValidation', {}).pipe(
                    map((user) => {
                        this.phoneValidationService.setErrorState(null);
                        this.router.navigateByUrl('validation/booking/' + userId);
                        return 'validation';
                    }),
                    catchError((error) => {

                        this.phoneValidationService.setErrorState(error.status);
                        this.router.navigateByUrl('validation/booking/' + userId);
                        return of('validation');
                    }),
                );
            }
            return this.completeBookings(specialInfos, body, cardPayment);
        }),
    );
  }

    public completeBookings(specialInfos, body, cardPayment = false) {

        let requests = [];
        specialInfos.forEach((booking) => {
            let specialBody: any = cloneDeep(booking.specialInfo);
            delete booking.specialInfo;

            requests.push(
                this.dynamicService.saveFormEntry(specialBody).pipe(
                    map(([id]) => {
                        booking.formEntriesId = id;
                    }),
                ),
            );
        });



        return forkJoin(requests).pipe(
            defaultIfEmpty(null),
            concatMap((response: any) => {
                return from(
                    this.requestManager.post(
                        '/bookings/create?cardPayment=' + cardPayment,
                        body,
                        this.requestManager.getJWT(),
                        this.bookErrorHandler,
                    ),
                ).pipe(
                    tap((result) => {
                        if (!cardPayment) {
                            this.restartCart();
                        }

                    }),
                );
            }),
        );
    }

    public getRooms(): Observable<any> {
        return this.rooms$;
    }
}
