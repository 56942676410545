import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { openLocationSelectModal } from '../../actions/location.actions';
import { State } from '../../reducers';

@Component({
  selector: 'app-current-location',
  templateUrl: './current-location.component.html',
  styleUrls: ['./current-location.component.css']
})
export class CurrentLocationComponent implements OnInit {

  location$
  constructor(private store$:Store) { }

  ngOnInit(): void {
    this.location$ = this.store$.pipe(
      select((state: State) => state.location.current)
    );
  }
  openLocationModal() {
    this.store$.dispatch(openLocationSelectModal());
  }
}
