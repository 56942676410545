import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-modal-smartphone-required',
    templateUrl: './modal-smartphone-required.component.html',
    styleUrls: ['./modal-smartphone-required.component.scss'],
})
export class ModalSmartphoneRequiredComponent implements OnInit {

    smartphoneRequired = {
        settingsKey: 'page_description_smartphone_required',
        settingsValue: '',
        settingsId: 1,
        id: 14,
    };

    constructor(private dialogRef: MatDialogRef<ModalSmartphoneRequiredComponent>,
                private requestManager: RequestManagerService) {
        this.requestManager.get('/settings/settingsValue', this.requestManager.getJWT(), true).then(
            (res) => {
                let companySettings = UtilsService.getSettingsOfCategoryByName('companySettings', res);
                this.smartphoneRequired = UtilsService.getSettingByKeynameFromCategory('page_description_smartphone_required', companySettings);
            },
        );
    }

    ngOnInit(): void {

    }

    close() {
        this.dialogRef.close();
    }
}
