import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import {
    navItemsDefault,
    navItemsUser,
    navItemsAdmin,
    navItemsOffice, NavData,
} from '../../_nav';
import { DynamicMenuPageComponent } from '../dynamic-menu-page/dynamic-menu-page.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from '../../services/utils/utils.service';
import { ISettingsValue } from '../admin/settings/settingsValue';
import { changeLanguage } from '../../actions/ui.actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class LoginService {


    loggingIn$ = new BehaviorSubject(false);
    isLoggedIn = new BehaviorSubject(false);
    isLoggingIn = new BehaviorSubject(false);
    user = new BehaviorSubject(null);
    isAdmin = new BehaviorSubject(false);
    isOffice = new BehaviorSubject(false);
    sideNavShow = new BehaviorSubject(true);
    navMenu = new BehaviorSubject<NavData[]>(navItemsDefault);

    dynamicMenuPointActiveAfterLogin: ISettingsValue =
        {
            settingsKey: 'page_dynamicMenuPointActiveAfterLogin',
            settingsValue: false,
            settingsId: 1,
            id: 11,
        };

    constructor(private requestManager: RequestManagerService,
                private cookieService: CookieService,
                private router: Router,
                private modalService: MatDialog,
                private store: Store,
                private translate: TranslateService) {

        this.requestManager.get('/settings/settingsValue', this.requestManager.getJWT(), true)
            .then(
                (res) => {
                    let companySettings = UtilsService.getSettingsOfCategoryByName('companySettings', res);
                    this.dynamicMenuPointActiveAfterLogin = UtilsService.getSettingByKeynameFromCategory('page_dynamicMenuPointActiveAfterLogin', companySettings);
                    this.dynamicMenuPointActiveAfterLogin.settingsValue = this.dynamicMenuPointActiveAfterLogin.settingsValue == 'true';
                },
            );


    }

    showLoggingIn()
    {
        this.loggingIn$.next(true);
    }

    hideLogginIn()
    {
        this.loggingIn$.next(false);
    }

    isUserAdmin() {
        if (this.cookieService.get("jwt")) {

            this.isLoggedIn.next(true);

            const userId = (<any>jwt_decode(this.requestManager.getJWT())).data
                .user_id;


            this.requestManager.get('/user/' +userId, this.requestManager.getJWT(), true).then(userdata => {
                let user;
                Object.keys(userdata['user']).forEach(key => {
                    user= userdata['user'][key];
                })
                this.user.next(user);
            }, error => {
            });


            this.requestManager
                .get("/user/" + userId, this.requestManager.getJWT())
                .then((response) => {
                    if (response.user[userId].language !== undefined && response.user[userId].language !== null) {
                        this.store.dispatch(changeLanguage({ language: response.user[userId].language }));
                        this.translate.use(response.user[userId].language.toLowerCase());
                    }

                    if (response.user[userId].isAdmin === '1') {
                        this.isAdmin.next(true);
                    }
                    if (response.user[userId].isOffice === '1') {
                        this.isOffice.next(true);
                    }

                    if (this.isLoggingIn.value && this.isLoggedIn.value == true && (this.isOffice.value == true || this.isAdmin.value == true)) {
                        this.router.navigateByUrl('admin/bookings');
                        this.hideLogginIn();
                    } else if (this.isLoggingIn.value && this.isLoggedIn.value == true && (this.isOffice.value == false && this.isAdmin.value == false)) {
                        this.router.navigateByUrl('');
                        this.hideLogginIn();
                    }


                    this.isLoggingIn.next(false);
                    this.sideNavShow.next(true);

                    if (this.dynamicMenuPointActiveAfterLogin.settingsValue) {
                        if (response.user[userId].isAdmin !== '1' && response.user[userId].isOffice !== '1') {
                            this.openLinkToDynamiceMenuPage();
                        }
                    }
                    this.updateMenu();
                });
        } else {
            this.isLoggedIn.next(false);
        }
    }

     updateMenu() {
        if (this.isAdmin.value) {
            this.navMenu.next(navItemsAdmin);
        } else if (this.isOffice.value) {
            this.navMenu.next(navItemsOffice);
        } else if (this.isLoggedIn.value) {
            this.navMenu.next(navItemsUser);
        } else {
            this.navMenu.next(navItemsDefault);
        }
    }

    setLoggedIn() {
        localStorage.setItem('loggedIn', 'true');
        this.isLoggedIn.next(true);
    }

    unsetLoggedIn() {
        localStorage.setItem('loggedIn', 'false');
        this.isLoggedIn.next(false);
    }

    openLinkToDynamiceMenuPage() {
        this.modalService.open(DynamicMenuPageComponent);
    }
}
