import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../../services/utils/utils.service';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';
import { from, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Pipe({
    name: 'replaceEmail',
})
export class ReplaceEmail implements PipeTransform {

    private cachedEmail = null;

    constructor(private requestManager: RequestManagerService) {

    }

    transform(value: any): Observable<string> {

        if (!this.cachedEmail) {
            return from(this.requestManager.get('/settings/settingsValue', this.requestManager.getJWT(), true))
                .pipe(
                    map((res) => {
                        let companySettings = UtilsService.getSettingsOfCategoryByName('companySettings', res);
                        const contactEmailSetting = UtilsService.getSettingByKeynameFromCategory('footer_mailto', companySettings);
                        this.cachedEmail = contactEmailSetting.settingsValue;
                        return value.toString().replace('EMAIL_PLACEHOLDER', contactEmailSetting.settingsValue);
                    }),
                );
        }
        return of(value.toString().replace('EMAIL_PLACEHOLDER', this.cachedEmail));
    }
}