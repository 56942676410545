import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import * as dayjs from 'dayjs';


@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    static translate: TranslateService;

    constructor() {
    }

    /**
     * 2021-06-02 10:00:00
     * @param dateString
     */
    static dateObjectFromString(dateString: string): Date {
        let a = dateString.split('/[^0-9]/');
        return new Date(Number.parseInt(a[0]), Number.parseInt(a[1]) - 1, Number.parseInt(a[2]), Number.parseInt(a[3]), Number.parseInt(a[4]), Number.parseInt(a[5]));
    }

  /**
   * Compare two time strings in format "HH:mm"; if first is greater return true, if smaller return false, else return undefined
   * @param first
   * @param second
   * @returns {boolean}
   */
  static compareTimeStrings(first, second): boolean {

    let firstTimes = first.split(':');
    let secondTimes = second.split(':');

    if (Number(firstTimes[0]) >= Number(secondTimes[0])
      || (Number(firstTimes[0]) == Number(secondTimes[0]) && Number(firstTimes[1]) >= Number(secondTimes[1]))) {
      return true;

    }
    else if (Number(firstTimes[0]) < Number(secondTimes[0])
      || (Number(firstTimes[0]) == Number(secondTimes[0]) && Number(firstTimes[1]) < Number(secondTimes[1]))) {
      return false;

    } else {
      return undefined;
    }
  }

    /**
   * Compare two time strings in format "HH:mm"; if first is greater return 1, if smaller -1 false, else return 0
   * @param first
   * @param second
   * @returns {number}
   */
     static compareTimeStringsDetailed(first, second): number {
      let firstTimes = first.split(':');
      let secondTimes = second.split(':');
  
      if (Number(firstTimes[0]) > Number(secondTimes[0])
        || (Number(firstTimes[0]) == Number(secondTimes[0]) && Number(firstTimes[1]) > Number(secondTimes[1]))) {
        return 1;
  
      }
      else if (Number(firstTimes[0]) < Number(secondTimes[0])
        || (Number(firstTimes[0]) == Number(secondTimes[0]) && Number(firstTimes[1]) < Number(secondTimes[1]))) {
        return -1;
  
      } else {
        return 0;
      }
    }

  /**
   * transforms a timeString of format "HH:mm" to a full number
   * @param timeString
   * @returns {number}
   */
  static transformTimeString2EntityLength(timeString): number {
    timeString = this.timeStringFormatter(timeString);
    return Number(timeString.substr(0, 2)) * 4 + Number(timeString.substr(3, 2)) / 15;
  }

  /**
   * transforms a entityLength to a timeString in format "HH:mm"
   * @param entityLength
   * @returns {string}
   */
  static transformEntityLength2TimeString(entityLength): string{
    return this.addEntityLength2TimeString('00:00', entityLength);
  }

  /**
   * Adds an entityLength to a timeString in format "HH:mm" and returns a string "HH:mm"
   * @param timeString
   * @param entityLength
   * @returns {string}
   */
  static addEntityLength2TimeString(timeString, entityLength): string {

      timeString = this.timeStringFormatter(timeString);

    let hours = (Number(timeString.substr(0, 2)) + Math.floor(entityLength / 60)).toString();
    let minutes = (Number(timeString.substr(3, 2)) + Math.floor(entityLength % 60)).toString();


      if (Number(minutes) >= 60){
      minutes = (Number(minutes)-60).toString();
      hours = (Number(hours)+1).toString();
    }
    if (minutes === '0')
      minutes = '00';

    if (Number(hours)< 10)
      return '0' + hours + ':' + minutes;

    return hours + ':' + minutes;
  }


  private static timeStringFormatter(anyString: string) {
    let timeString = '';
    const parts = anyString.split(':');
    if(parts[0].length == 1) {
      timeString += '0'+parts[0];
    }else {
      timeString += ''+parts[0];
    }
    timeString +=':';
    if(parts[1].length == 1) {
      timeString += '0'+parts[1];
    }else {
      timeString += ''+parts[1];
    }
    
    return timeString;
  }
  
  /**
   * Creates a array with timeStrings in format "HH:mm" from "fromTimeString" to "toTimeString" with step size entityLength
   * @param fromTimeString
   * @param toTimeString
   * @param entityLength
   * @returns {string[]}
   */
  static createTimeStringArray(fromTimeString, toTimeString, entityLength): string[] {
    let totalEntities = this.transformTimeString2EntityLength(toTimeString) - this.transformTimeString2EntityLength(fromTimeString);
    totalEntities /= (entityLength/15);
    totalEntities = totalEntities ;

    let timeStringArray = [];
    for(let i = 0; i <= totalEntities; i++){
        let checkTime = this.addEntityLength2TimeString(this.addEntityLength2TimeString(fromTimeString, entityLength*i),entityLength);

        if(this.transformTimeString2EntityLength(checkTime) <= this.transformTimeString2EntityLength(toTimeString)){
            timeStringArray.push(this.addEntityLength2TimeString(fromTimeString, entityLength*i));
        }

    }

    return timeStringArray;
  }

  /**
   * Gets description and name of room in current language
   * @param room
   * @param currentLang
   */
  static getDescriptionFromLanguage(room, currentLang): void{
    // loop over all description to find right language
    _.values(room['roomDescription']).forEach((description) => {
      if (description.language.toLowerCase() == currentLang) {
        room['description'] = description.description;
        room['name'] = description.name;
      }
    });
  }

  static getRoomOpeningHoursToday(room: any): void {
    let today = new Date().getDay();

    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    _.values(room['roomType']['roomTypeOpeningHour']).forEach((openingHour) => {
      if (openingHour.day == weekday[today]) // get actual day
      {
        room['openingHours'] = openingHour;
      }
    });
  }

  /**
   * Translates a TimeString in format "HH:mm" and a entityLength to a time span in format "HH:mm - HH:mm"
   * This time span is from "fromTime" to "(fromTime + entityLength)"
   * @param fromTime
   * @param entityLength
   * @returns {string}
   */
  static getReadableTimeSpan(fromTime,entityLength): string{
    const toTime = this.addEntityLength2TimeString(fromTime, entityLength);
    return fromTime +' - '+ toTime;
  }


  /**
   * Translates the input in a ISO-Date
   * @param year
   * @param month
   * @param day
   * @param timeString in format HH:mm
   * @returns {string} in ISO-Date format
   */
  static getISOStringOfTimeString(year, month, day, timeString): string{
    timeString = this.timeStringFormatter(timeString);
   // let offset = (new Date).getTimezoneOffset() * 60000;
    let date = new Date(year, month-1, day);
    let timeParts = timeString.split(':');


      date.setHours(Number.parseInt(timeParts[0]));
    let offset = date.getTimezoneOffset() * 60000;

    date = new Date(date.getTime() - offset);


    let dateString = dayjs(date).toISOString();

      return dateString.substr(0,dateString.length-1);
  }

  static getSettingsOfCategoryByName(name: string, settingsCategory: any){
    let tmpCategory = undefined;
    _.values(settingsCategory['settings']).forEach(
      category => {
        if(category['name'] == name){
          // console.log('FOUND: ', category);
          tmpCategory   = _.cloneDeep(category);
        }
      }
    );
    return tmpCategory ;
  }

    static getSettingByKeynameFromCategory(keyname: string, settings: any) {
        let tmpSetting = undefined;
        _.values(settings.settingsValue).forEach(
            setting => {
                if (setting['settingsKey'] == keyname) {
                    tmpSetting = _.cloneDeep(setting);
                }
            },
        );
        return tmpSetting;
    }

    static getLocalDate() {
        let date = new Date();
        let offset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + offset);
    }

}
