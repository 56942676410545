import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RequestManagerService } from '../../../services/requestManager/request-manager.service';
import { NotifierService } from 'angular-notifier';
import { select, Store } from '@ngrx/store';
import { State } from '../../../reducers';
import { map, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-bookings-export',
    templateUrl: './bookings-export.component.html',
    styleUrls: ['./bookings-export.component.scss'],
})
export class BookingsExportComponent implements OnInit {

    settingsForm = this.fb.group({
        locationId: this.fb.control(null, [Validators.required]),
        from: this.fb.control(null, [Validators.required]),
        to: this.fb.control(null, [Validators.required]),
    });

    locations$: Observable<any>;
    submitLoading$ = new BehaviorSubject(false);
    submitLoadingText$ = new Subject();

    constructor(private fb: FormBuilder,
                private requestManager: RequestManagerService,
                private notifier: NotifierService,
                private store: Store) {
        this.locations$ = this.store
            .pipe(
                select((state: State) => state.location.all),
                map((locations) => Object.values(locations)));


        this.settingsForm.patchValue(
            {
                from: new Date(),
            },
        );
    }

    ngOnInit(): void {
    }

    downloadBookings() {

        this.submitLoading$.next(true);
        setTimeout(() => {
            if (this.submitLoading$.value) {
                this.submitLoadingText$.next(true);
            }

        }, 2000);

        console.log(this.settingsForm.value);
        this.requestManager.getAuth('/bookings/export/json?locationId='
            + this.settingsForm.get('locationId').value
            + '&from=' + this.getDate(this.settingsForm.get('from').value)
            + (this.settingsForm.get('to').value ? ('&to=' + this.getDate(this.settingsForm.get('to').value)) : ''))
            .pipe(
                take(1),
            )
            .subscribe(
                (jsonData) => {
                    // const headers: string[] = Object.values(jsonData[0]);
                    const worksheet = XLSX.utils.json_to_sheet(jsonData);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
                    XLSX.writeFile(workbook, 'buchungen.xlsx');
                },
                (error) => {
                    console.log(error);
                    this.notifier.show({
                        message: 'Es gab einen Fehler beim Herunterladen der Datei',
                        type: 'error',
                    });
                    this.submitLoading$.next(false);
                    this.submitLoadingText$.next(false);
                },
                () => {
                    this.submitLoading$.next(false);
                    this.submitLoadingText$.next(false);
                }
            );
    }

    getDate(inputDate: any): string {
        console.log(inputDate);
        if (!(inputDate instanceof Date)) {
            inputDate = new Date(inputDate);
        }
        const year = inputDate.getFullYear();

        const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Monat beginnt bei 0
        const day = String(inputDate.getDate()).padStart(2, '0');

        const formatiertesDatum = `${year}-${month}-${day}`;

        return formatiertesDatum;
    }
}