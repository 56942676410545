import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-storno-booking',
  templateUrl: './modal-storno-booking.component.html',
  styleUrls: ['./modal-storno-booking.component.css']
})
export class ModalStornoBookingComponent implements OnInit {

  constructor(  private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  stornoButton() {
    
    this.activeModal.close(true)
  }
  cancelButton() {
    this.activeModal.dismiss();
  }

}
