import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeStringInHour'
})
export class TimeStringInHourPipe implements PipeTransform {

  transform(value: string, args?: string): boolean{
    const timeString = Number(value.substr(0,2));
    const hour = Number(args.substr(0,2));
    return timeString == hour;
  }

}
