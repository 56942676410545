import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { debounce, tap, values } from 'lodash';
import { from, Subject } from 'rxjs';
import { bufferTime, filter, map } from 'rxjs/operators';
import { loadCustomers } from '../../../actions/customer.actions';
import { State } from '../../../reducers';
import { RequestManagerService } from '../../../services/requestManager/request-manager.service';
import { UtilsService } from '../../../services/utils/utils.service';

@Component({
  selector: 'app-user-search-modal',
  templateUrl: './user-search-modal.component.html',
  styleUrls: ['./user-search-modal.component.css']
})
export class UserSearchModalComponent implements OnInit,OnDestroy{
  @Input()
  date;
  @Input()
  time;
  @Input()
  room;

  loading = false;

  customers$;
  customer:any;
  searchTerm;
  countResult = 0;

  searchTerm$ = new Subject();
  constructor(
    private store: Store,
    private requestManager: RequestManagerService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {

    this.customers$ = this.store.select((state:State) => state.data.customers).pipe(
        map(customers => {
      return values(customers);
    }));
   
    this.loading = true;

   
    this.customers$.subscribe((customers)=>{
      this.countResult = Object.values(customers).length;
    })

    this.searchTerm$.pipe(bufferTime(200), filter( (term) => term.length > 0 )).subscribe( () => {
      this.store.dispatch(loadCustomers({searchTerm: this.searchTerm, offset:0, limit:200}));
    })
  }

  selectCustomer(customer) {
    this.customer = customer;
    this.searchTerm= '';
    this.activeModal.close(this.customer);
    this.customer = null;
  }


  valueChange($event) {
    this.searchTerm= $event;
    this.searchTerm$.next($event);
    
  }

  ngOnDestroy() {
  }

}
