import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyValue'
})
export class EmptyValuePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(args != undefined)
      return ((value == null || value == '')) ? args : value;
    return ((value == null || value == '')) ? "-/-" : value;
  }

}
