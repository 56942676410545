import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {RequestManagerService} from '../../../../services/requestManager/request-manager.service';
import {NotifierService} from 'angular-notifier';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordModalComponent } from '../customer-dashboard-data/change-password-modal/change-password-modal.component';

@Component({
  selector: 'app-customer-dashboard-actions',
    templateUrl: './customer-dashboard-actions.component.html',
    styleUrls: ['./customer-dashboard-actions.component.css'],
})
export class CustomerDashboardActionsComponent implements OnInit {
    @Output() refresh: EventEmitter<any> = new EventEmitter();


    @Input()
    customerId;

    @Input()
    showInvoicesButton = false;

    @Input()
    loadingSpinner: BehaviorSubject<boolean>;

    @Output()
    loadingDone = new EventEmitter<string>();

    activeModal;
    resetLoading = false;
    protected passwordUpdateObject = {
    'oldPassword': '',
    'newPassword': '',
    'newPassword2': ''
  };

  public passwordRequestLoading = false;

  protected userIsOffice;
  protected userIsAdmin;


  constructor(private modalService: NgbModal,
              private _requestManager: RequestManagerService,
              private _notifyService: NotifierService,
              private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private matDialogService: MatDialog) {
  }

  ngOnInit() {
    this.userIsAdmin = this._requestManager.getUserIsAdmin();
    this.userIsOffice = this._requestManager.getUserIsOffice();
    this.disableLoading();
  }


  disableLoading() {
    this.loadingDone.next('actions');

  }

  openBookings() {
    this.router.navigateByUrl('customer-dashboard/bookings/' + this.customerId);
  }

  fillAccount() {
    this.router.navigateByUrl('customer-dashboard/balance/'+this.customerId);
  }


    changePassword() {
        this.matDialogService.open(ChangePasswordModalComponent,
            {
                data: {
                    customerId: this.customerId,
                },
            },
        );
    }

    openInvoices() {
        this.router.navigateByUrl('customer-invoices/' + this.customerId);
    }
}
