import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { selectCurrentLocation } from '../../actions/location.actions';
import { State } from '../../reducers';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';
import { MatDialogRef } from '@angular/material/dialog';
import { LocationService } from '../../services/location/location.service';

@Component({
  selector: 'app-modal-location-select',
  templateUrl: './modal-location-select.component.html',
  styleUrls: ['./modal-location-select.component.scss']
})
export class ModalLocationSelectComponent implements OnInit {

    modalRef: MatDialogRef<ModalLocationSelectComponent>;
    userIsAdmin = false;
    userIsOffice = false;
  locations$: Observable<any>
  constructor(
      private store: Store,
      private _requestManager: RequestManagerService,
      private dialogRef: MatDialogRef<ModalLocationSelectComponent>,
      public locationService: LocationService,
  ) {
      this.dialogRef.disableClose = true;
      this.userIsAdmin = this._requestManager.getUserIsAdmin();
      this.userIsOffice = this._requestManager.getUserIsOffice();

  }

  ngOnInit(): void {

    this.locations$ = this.store.pipe(select((state: State) => (state.location.all)),
      map(locations => {
          if (locations !== undefined) {
              locations = Object.values(locations);
              if (locations.length !== 0) {

                  const locationsArray = [];
                  for (const key in locations) {
                      if (locations[key].disabled !== '1' || (this.userIsAdmin == true || this.userIsOffice == true)) {
                          locationsArray.push(locations[key]);
                      }
                  }
                  return locationsArray;
              }
          }

          this.modalRef.disableClose = false;
          return [];
      }),
      tap((locations) => {
        console.log(locations);
      })
    )
  }

  selectLocation(location){
    this.store.dispatch(selectCurrentLocation(location));
    this.modalRef.close();
  }

}
